.baner2-main {
    position: relative;

    .banner-slider-2 {
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            img {
                display: block;
                width: 100%;
                height: calc(100vh - 65.77px);
                object-fit: cover;
                object-position: 100% 100%;
            }
        }

        .swiper-container {
            width: 100%;
            height: calc(100vh - 65.77px);
        }

        .autoplay-progress {
            position: absolute;
            right: 16px;
            bottom: 16px;
            z-index: 10;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color: var(--swiper-theme-color);
            svg {
                --progress: 0;
                position: absolute;
                left: 0;
                top: 0px;
                z-index: 10;
                width: 100%;
                height: 100%;
                stroke-width: 4px;
                stroke: var(--swiper-theme-color);
                fill: none;
                stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
                stroke-dasharray: 125.6;
                transform: rotate(-90deg);
            }
        }
    }

    @media (max-width: 767.9px) {
        .banner-slider-2 {
            .swiper-slide {
                img {
                    height: calc(100vh - 65.77px);
                }
            }
        }
    }

    .banner2-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        display: flex;
        align-items: center;
        z-index: 2;

        .banner2-list {
            list-style: none;
            justify-content: center;
            margin-bottom: 0;
            padding-top: 30px;
            padding-left: 0;

            li {
                font-size: 55px;
                font-family: 'Minion pro';
                line-height: 74px;
                color: #FFFFFF;

                @media (max-width: 1199.9px) {
                    font-size: 38px;
                    line-height: 48px;
                }

                @media (max-width: 991.9px) {
                    font-size: 32px;
                    line-height: 42px;
                }

                @media (max-width: 575.9px) {
                    font-size: 24px;
                    line-height: 34px;
                }
            }

            @media (max-width: 575.9px) {
                padding-top: 0;
            }
        }

        .banner2-list-filters {
            display: flex;
            justify-content: space-between;
            list-style: none;
            align-items: center;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 50px;
            opacity: 1;
            max-width: 715px;
            margin: 90px auto 100px auto;
            padding: 6px 6px 6px 20px;
            & > li {
                &.any-where {
                    width: 200px;
                    height: 50px;
                    border-right: 1px solid #928F8F;
                    text-align: center;

                    & > button {
                        font-size: 20px;
                        line-height: 28px;
                        color: #212529;
                        background-color: transparent;
                        border-color: transparent;
                        // max-width: 152px;
                        min-width: 120px;
                        display: inline-flex;
                        padding: 10px 15px;
                        width: 100%;
                        text-align: center;
                        justify-content: center;

                        @media (max-width: 991.9px) {
                            font-size: 16px;
                        }

                        @media (max-width: 991.9px) {
                            font-size: 14px;
                        }
                    }

                    .location-dropdown {
                        position: absolute;
                        margin-top: 7px;
                        width: 300px;
                        height: 260px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #EA9B45;
                        border-radius: 10px;
                        opacity: 1;
                        padding: 8px;
                        margin-left: -20px;
                        z-index: 1;

                        .input-icon {
                            position: absolute;
                            top: 16px;
                            left: 18px;
                            width: 10px;
                            margin-right: 7px;
                            margin-top: 6px;
                        }

                        & > input {
                            width: 280px;
                            height: 48px;
                            padding: 10px;
                            padding-left: 25px;
                            background: #F2F2F2;
                            border-radius: 12px;
                            border: 0px;
                            text-align: left;
                            font-size: 15px;
                            line-height: 20px;
                            font-family: 'roboto';
                            letter-spacing: 0px;
                            color: #212529;
                            opacity: 1;
                        }

                        @media (max-width: 575.9px) {
                            margin-left: -77px;
                            margin-top: 0;
                        }
                    }

                    @media (max-width: 767.9px) {
                        width: 145px;
                    }

                    & > input {
                        font-size: 20px;
                        line-height: 28px;
                        color: #212529;
                        background-color: transparent;
                        border-color: transparent;
                        max-width: 152px;
                        min-width: 120px;
                        display: inline-flex;
                        padding: 10px 15px;
                        width: 100%;
                        text-align: center;
                        &::placeholder {
                            font-size: 20px;
                            line-height: 28px;
                            color: #212529;
                        }

                        @media (max-width: 991.9px) {
                            font-size: 16px;

                            &::placeholder {
                                font-size: 16px;
                            }
                        }

                        @media (max-width: 767.9px) {
                            font-size: 14px;

                            &::placeholder {
                                font-size: 14px;
                            }
                        }
                    }
                }

                &.any-time {
                    width: 200px;
                    height: 50px;
                    border-right: 1px solid #928F8F;
                    text-align: center;

                    @media (max-width: 767.9px) {
                        width: 145px;
                    }

                    &>button {
                        font-size: 20px;
                        line-height: 28px;
                        color: #212529;
                        background-color: transparent;
                        border-color: transparent;
                        min-width: 120px;
                        display: inline-flex;
                        padding: 10px 15px;
                        width: 100%;
                        text-align: center;
                        justify-content: center;

                        @media (max-width: 991.9px) {
                            font-size: 16px;
                        }

                        @media (max-width: 767.9px) {
                            font-size: 14px;
                        }
                    }

                }

                &.any-species {
                    position: relative;
                    width: 200px;
                    height: 50px;
                    text-align: center;

                    @media (max-width: 767.9px) {
                        width: 145px;
                    }

                    &>button {
                        font-size: 20px;
                        line-height: 28px;
                        color: #212529;
                        background-color: transparent;
                        border-color: transparent;
                        max-width: 152px;
                        min-width: 120px;
                        display: inline-flex;
                        padding: 10px 15px;
                        width: 100%;
                        text-align: center;
                        justify-content: center;

                        @media (max-width: 991.9px) {
                            font-size: 16px;
                        }

                        @media (max-width: 767.9px) {
                            font-size: 14px;
                        }
                    }

                    .species-dropdown {
                        position: absolute;
                        background-color: #fff;
                        border-radius: 10px;
                        width: 310px;
                        left: 50%;
                        right: auto;
                        transform: translate(-50%, 7px);
                        box-shadow: 3px 3px 15px #0000000F;
                        border: 1px solid #EA9B45;
                        overflow: hidden;
                        opacity: 1;
                        z-index: 20;

                        @media (max-width: 575.9px) {
                            transform: translate(-50%, -1%);
                        }

                        .species-dropdown-list {
                            list-style: none;
                            padding-left: 0;
                            padding: 15px;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;
                            border-radius: 10px;
                            gap: 5px;
                            height: 274px;
                            overflow-y: auto;
                            scrollbar-color: #EA9B45;

                            &::-webkit-scrollbar {
                                width: 2px;
                                height: 3px;
                                border-radius: 6px;
                                background-color: #f5f5f5;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: #ea9a45d6;
                            }

                            &::-webkit-scrollbar-track {
                                background-color: #f5f5f5;
                            }

                            li {
                                border: 1px solid #EA9B45;
                                padding: 1px 8px;
                                display: inline-flex;
                                align-items: center;
                                border-radius: 15px;
                                flex-grow: 1;
                                justify-content: center;

                                &:hover {
                                    cursor: pointer;
                                }

                                &:first-child {
                                    img {
                                        display: none;
                                    }
                                }

                                img {
                                    height: 15px;
                                    object-fit: scale-down;
                                }

                                span {
                                    font-size: 13px;
                                    color: #212529;
                                    padding-left: 4px;

                                    &:hover {
                                        color: #EA9B45;
                                    }

                                    &.active {
                                        color: #EA9B45 !important;
                                    }
                                }
                            }
                        }

                        .done-btn {
                            padding-left: 0;
                            list-style: none;

                            li {
                                button {
                                    background-color: #EA9B45;
                                    border: 1px solid #EA9B45;
                                    border-radius: 0px 0px 6px 6px;
                                    opacity: 1;
                                    text-align: center;
                                    font-size: 16px;
                                    letter-spacing: 0px;
                                    color: #FFFFFF;
                                    text-transform: uppercase;
                                    padding: 8px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                a {
                    background-color: #EA9B45;
                    border: 1px solid #EA9B45;
                    padding: 10px 30px;
                    border-radius: 6px;
                    text-decoration: none;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 151px;
                    text-transform: uppercase;
                    cursor: pointer;

                    img {
                        width: 28px;
                        height: auto;
                        margin-right: 15px;
                        object-fit: contain;
                    }

                    span {
                        font-size: 20px;
                        line-height: 24px;
                        color: #ffffff;
                        text-transform: uppercase;
                    }
                }

                &.search-icon {
                    line-height: 0;
                    button {
                        border: 0;
                        height: 55px;
                        width: 55px;
                        border-radius: 100%;
                        background-color: #EA9B45;

                        i {
                            color: #FFFFFF;
                            font-size: 20px;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            @media (max-width: 1199.9px) {
                margin: 50px auto 60px auto;
            }

            @media (max-width: 991.9px) {
                margin: 40px auto 45px auto;
            }

            @media (max-width: 575.9px) {
                flex-direction: column;
                padding-right: 0;
                border-radius: 20px;
                padding-left: 0;
                padding-bottom: 0;
                margin: 20px auto 30px auto;

                &>li {
                    &.any-where {
                        border-right: 0;
                        border-bottom: 1px solid #eee;

                        &>input {
                            text-align: center;
                        }
                    }

                    &.any-time {
                        border-right: 0;
                        border-bottom: 1px solid #eee;
                    }

                    &.any-species {
                        border-right: 0;
                        border-bottom: 1px solid #eee;
                    }

                    &.search-icon {
                        width: 100%;

                        button {
                            width: 100%;
                            border-radius: 0 0 20px 20px;

                            span {
                                display: flex !important;
                                font-size: 16px;
                                color: #fff;
                                text-align: center;
                                justify-content: center;
                            }

                            i {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .banner2-list-btns {
            display: flex;
            justify-content: center;
            list-style: none;
            align-items: center;
            padding-left: 0;
            width: fit-content;
            margin: 0 auto;

            li {
                &:not(:last-child) {
                    padding-right: 40px;
                }

                a {
                    background-color: #EA9B45;
                    border: 1px solid #EA9B45;
                    padding: 10px 30px;
                    border-radius: 6px;
                    text-decoration: none;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 151px;

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        width: 28px;
                        height: auto;
                        margin-right: 15px;
                        object-fit: contain;
                    }

                    span {
                        font-size: 20px;
                        line-height: 24px;
                        color: #ffffff;
                        text-transform: uppercase;
                    }
                }
            }

            @media (max-width: 1199.9px) {
                padding: 10px 20px;
                min-width: 140px;

                li:not(:last-child) {
                    padding-right: 30px;
                }
            }

            @media (max-width: 991.9px) {
                li {
                    &:not(:last-child) {
                        padding-right: 15px;
                    }

                    a {
                        padding: 10px 20px;
                        min-width: 120px;

                        img {
                            width: 22px;
                        }

                        span {
                            font-size: 14px;
                        }
                    }
                }
            }

            @media (max-width: 575.9px) {
                gap: 10px;
                li {
                    a {
                        min-width: 50px;
                        font-size: 16px;
                        height: 42.5px;

                        img {
                            width: 22px;
                            margin-right: 0;
                        }

                        span {
                            font-size: 16px;
                            display: none;
                        }
                    }

                    &:not(:last-child) {
                        padding-right: 0;
                    }
                }
            }
        }

        .calendar-main {
            margin-left: -55px;
            margin-top: 7px;

            @media (max-width: 767.9px) {
                margin-left: -77px;
                margin-top: 0;
            }
        }

        .react-calendar {
            width: 300px !important;
            margin-top: 0 !important;
            border: 1px solid #EA9B45;
            border-radius: 10px 10px 0 0 !important;
            height: 276px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 2px;
                height: 3px;
                border-radius: 6px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #ea9a45d6;
            }

            &::-webkit-scrollbar-track {
                background-color: #f5f5f5;
            }

            .react-calendar__tile:disabled abbr {
                color: #E8E8E8;
            }

            .react-calendar__tile abbr {
                font-size: 14px;
            }

            .react-calendar__month-view__weekdays__weekday abbr[title="Sunday"],
            abbr[title="Saturday"] {
                color: #EA9B45;
            }

            .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
            .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
                display: none;
            }
            
            .react-calendar__year-view .react-calendar__tile,
            .react-calendar__decade-view .react-calendar__tile,
            .react-calendar__century-view .react-calendar__tile {
                padding: 1em 0.5em;
            }
        }   

    }

    .banner-bottom {
        & > .left {
            position: absolute;
            bottom: 30px;
            left: 30px;
            z-index: 4;

            @media (max-width: 991.9px) {
                left: 30px;
                bottom: 30px;
            }

            @media (max-width: 767.9px) {
                left: 10px;
                bottom: 10px;
            }

            @media (max-width: 575.9px) {
                left: 15px;
                bottom: 30px;
                z-index: 2;

            }

            @media (max-width: 420.9px) {
                left: 5px;
                bottom: 5px;
            }

            a {
                img {
                    height: 230px;

                    @media (max-width: 991.9px) {
                        height: 180px;
                    }

                    @media (max-width: 767.9px) {
                        height: 110px;
                    }

                    @media (max-width: 575.9px) {
                        height: 100px;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .calendar-btn {
        position: relative !important;
        z-index: 1001;
        width: 300px !important;
        border-radius: 0 0 6px 6px !important;
        font-size: 16px !important;
        margin-top: 276px;
        border: 1px solid #EA9B45 !important;
        background-color: #EA9B45;
        border: 1px solid #EA9B45;
        opacity: 1 !important;
        text-align: center;
        letter-spacing: 0px !important;
        color: #FFFFFF;
        padding: 8px !important;

    }

    .address-btn {
        position: relative !important;
        z-index: 1001;
        width: 298px !important;
        border-radius: 0 0 6px 6px !important;
        font-size: 16px !important;
        margin-top: 160px;
        margin-left: -8px;
        border: 1px solid #EA9B45 !important;
        background-color: #EA9B45;
        border: 1px solid #EA9B45;
        opacity: 1 !important;
        text-align: center;
        letter-spacing: 0px !important;
        color: #FFFFFF;
        padding: 8px !important;
        cursor: pointer;
    }
}

.hunter-page-main {
    h3 {
        text-align: left;
        font-size: 50px;
        line-height: 80px;
        letter-spacing: 0px;
        color: #212529;
        opacity: 1;
        margin-bottom: 25px;
    }

    @media(max-width: 1399.9px) {
        h3 {
            text-align: left;
            font-size: 36px;
            line-height: 44px;
        }
    }

    @media(max-width: 991.9px) {
        h3 {
            text-align: left;
            font-size: 34px;
            line-height: 42px;
        }
    }

    @media(max-width: 767.9px) {
        h3 {
            text-align: left;
            font-size: 32px;
            line-height: 40px;
        }
    }

    @media(max-width: 575.9px) {
        h3 {
            text-align: left;
            font-size: 26px;
            line-height: 30px;
        }
    }

    h5 {
        text-align: left;
        font-size: 25px;
        line-height: 28px;
        letter-spacing: 0px;
        color: #212529;
        opacity: 1;
        margin-bottom: 25px;
    }

    .conservation p {
        max-width: 715px !important;
    }

    p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
        margin-bottom: 25px;

        @media(max-width: 767.9px) {
            text-align: left;
            font-size: 16px;
            line-height: 24px;
        }

    }

    button,
    a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }

    section {
        padding: 100px 0;

        @media(max-width: 991.9px) {
            padding: 70px 0;
        }
    }

    .left {
        margin-right: 35px;
        display: inline-flex;
        height: 100%;
        align-items: center;

        @media(max-width: 991.9px) {
            margin-right: 0;
        }
    }

    .right {
        margin-left: 35px;
        display: inline-flex;
        height: 100%;
        align-items: center;

        @media(max-width: 991.9px) {
            margin-left: 0;
        }
    }

    img {
        width: 100%;
    }

    .rounded-5 {
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
    }
}

.home-bg1 {
    background-image: url(./../../assets/images/hunt-for-hunter-bg.png) !important;
    background-position: right top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;

    .hunting-exp-right {
        justify-content: flex-end;
        width: 100%;

        img {
            width: 100%;
            max-width: 250px;
            max-height: 500px;
        }
    }

    @media (max-width: 991.9px) {
        .hunting-exp-right {
            img {
                max-height: 500px;
            }
        }
    }

    @media (max-width: 991.9px) {
        .hunting-exp-right {
            justify-content: flex-end;
        }
    }

    @media (max-width: 767.9px) {
        .hunting-exp-right {
            justify-content: center;
        }
    }
}

.home-bg2 {
    background-image: url(./../../assets/images/simply-trans-bg.png) !important;
    background-position: left top !important;
    background-repeat: no-repeat !important;
    background-size: contain;
    background-color: #E5E5E5;

    .right {
        .right-inner {
            img {
                max-width: 250px;
                max-height: 500px;
            }
        }
    }

    @media (max-width: 991.9px) {
        .left {
            padding: 20px 20px 0px 20px;
            background-color: rgb(255 255 255 / 80%);
            border-radius: 20px;
            display: flex;
        }

        .right {
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
            width: 100%;

            .right-inner {
                img {
                    max-height: 500px;
                    justify-content: center;
                }
            }
        }

    }
}

.bg-gray {
    background-color: #E5E5E5;
}

.old-banner {
    margin-top: 160px;

    @media (max-width: 991.9px) {
        margin-top: 0;
    }

    .banner {
        margin-top: 0;
    }
}

.overlay-account-detail-guide {
    padding: 70px 0;

    .left {
        @media (max-width: 991.9px) {
            margin-bottom: 25px;
        }
    }

    .right {
        @media (max-width: 991.9px) {
            justify-content: flex-end;
        }

        @media (max-width: 767.9px) {
            justify-content: center;
        }
    }
}

// =========================footer

.footer-2 {
    background-image: url(./../../assets/images/footer-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0;

    .footer-top {
        display: flex;
        justify-content: center;
        padding: 70px 0;

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 50px;
            opacity: 0.8;
            padding: 0 40px;
            flex-wrap: wrap;

            li {
                a {
                    font-size: 25px;
                    line-height: 30px;
                    color: #000000;
                    text-decoration: none;
                    padding: 20px;
                    display: inline-block;
                    cursor: pointer;
                }
            }

        }

        @media (max-width: 991.9px) {
            ul {
                padding: 0 25px;

                li {
                    a {
                        font-size: 16px;
                        padding: 15px;
                    }
                }
            }
        }

        @media (max-width: 767.9px) {
            ul {
                padding: 0 15px;

                li {
                    a {
                        font-size: 12px;
                        padding: 10px;
                    }
                }
            }
        }

        @media (max-width: 575.9px) {
            ul {
                // flex-direction: column;
                padding: 10px 30px;
                justify-content: center;

                li {
                    display: inline-flex;

                    a {
                        padding: 5px 10px;
                    }
                }
            }
        }

    }
    .footer-middle {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 0.9;
        display: flex;
        justify-content: center;
        padding: 40px 0;
        border-top: 3px solid #EA9B45;
        border-bottom: 3px solid #EA9B45;

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            li {

                &:hover {
                    cursor: pointer;
                }

                .icon-left {
                    display: inline-flex;

                    img {
                        max-width: 80px;
                        max-height: 80px;
                        object-fit: contain;
                    }
                }

                .icon-middle {
                    width: 400px;
                    display: inline-flex;
                    margin: 0 50px;

                    img {
                        margin: auto;
                        max-width: 400px;
                        max-height: 160px;
                        object-fit: contain;
                    }

                }

                .icon-right {
                    display: inline-flex;

                    img {
                        max-width: 80px;
                        max-height: 80px;
                        object-fit: contain;
                    }
                }

                @media (max-width: 767.9px) {
                    .icon-middle {
                        width: 240px;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }

                @media (max-width: 575.9px) {

                    .icon-right,
                    .icon-left {
                        max-width: 50px;
                        max-height: 50px;

                        img {
                            max-width: 50px;
                            max-height: 50px;
                        }
                    }

                    .icon-middle {
                        width: 200px;
                        margin: 0 25px;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }

                @media (max-width: 575.9px) {

                    .icon-right,
                    .icon-left {
                        max-width: 35px;
                        max-height: 35px;

                        img {
                            max-width: 35px;
                            max-height: 35px;
                        }
                    }

                    .icon-middle {
                        width: 180px;
                        margin: 0 20px;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    .copy-right {
        p {
            font-size: 25px;
            line-height: 30px;
            color: #ffffff;
            text-align: center;
        }

        @media (max-width: 991.9px) {
            p {
                font-size: 20px;
            }
        }
    }

    @media (max-width:991.9px) {
        padding: 10px 0 70px 0;

        .footer-top {
            padding: 50px 0;
        }
    }
}

.store-link-modal {

    .modal-dialog {
        width: 100%;
        max-width: 770px;
        margin: auto;
        justify-content: center;
        @media (max-width:930px) {
            max-width: 650px;
        }

        @media (max-width:767px) {
            max-width: 530px;
        }

        @media (max-width:575px) {
            max-width: 300px;
        }
    }

    .modal-content {
        border-radius: 30px;
        border: 1px solid #EA9B45;
        max-width: 770px;

        @media (max-width: 991px) {
            max-width: 650px;
        }
        @media (max-width: 767.9px) {
            max-width: 530px;
        }

        @media (max-width: 575px) {
            max-width: 300px;
            border-radius: 20px;
        }

        button {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            border: 4px solid #ffffff;
            background-color: #EA9B45;
            cursor: pointer;
            height: 40px;
            width: 40px;
            padding: 0 !important;
            img {
                width: 15px;
                height: 15px;
                border-radius: 100%;
            }
            @media (max-width: 575.9px) {
                height: 20px !important;
                width: 20px !important;
                border: 2px solid #ffffff;
                top: 15px;
                right: 13px;
                img {
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }

    .modal-body-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            background-color: #CCCCCC;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            width: 100%;
            height: 350px;
            max-width: 350px;

            @media (max-width: 991.9px) {
                height: 290px;
                width: 290px;
            }

            @media (max-width: 767.9px) {
                width: 230px;
                height: 230px;
            }

            @media (max-width: 575.9px) {
                width: 120px;
                height: 120px;
                border-radius: 20px;
            }

            .white {
                display: flex;
                width: 100px;
                transition: all .5s ease;
                object-fit: contain;

                
                @media (max-width: 767px) {
                    width: 80px;
                }

                @media (max-width: 575px) {
                    width: 40px;
                }
            }

            .black {
                display: none;
                width: 100px;
                transition: all .5s ease;
                object-fit: contain;

                @media (max-width: 767px) {
                    width: 80px;
                }

                @media (max-width: 575px) {
                    width: 40px;
                    
                }
            }

            &:hover {
                cursor: pointer;

                .white {
                    display: none;
                    width: 100px;
                    transition: all .5s ease;

                    @media (max-width: 767px) {
                        width: 80px;
                    }
    
                    @media (max-width: 575px) {
                        width: 40px;
                        
                    }
                }

                .black {
                    display: flex;
                    width: 100px;
                    color: #000000;
                    transition: all .5s ease;

                    @media (max-width: 767px) {
                        width: 80px;
                    }
    
                    @media (max-width: 575px) {
                        width: 40px;
                        
                    }
                }
            }
        }

        .right {
            background-color: #CCCCCC;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            width: 100%;
            height: 350px;
            max-width: 350px;

            @media (max-width: 991.9px) {
                height: 290px;
                width: 290px;
            }

            @media (max-width: 767.9px) {
                width: 230px;
                height: 230px;
            }

            @media (max-width: 575.9px) {
                width: 120px;
                height: 120px;
                border-radius: 20px;
            }

            .andriod {
                display: flex;
                width: 100px;
                transition: all .5s ease;

                @media (max-width: 767px) {
                    width: 80px;
                }

                @media (max-width: 575px) {
                    width: 40px;
                }
            }

            .playstore {
                display: none;
                width: 100px;
                transition: all .5s ease;

                @media (max-width: 767px) {
                    width: 80px;
                }

                @media (max-width: 575px) {
                    width: 40px;
                }
            }

            &:hover {
                cursor: pointer;

                .andriod {
                    display: none;
                    transition: all .5s ease;

                    @media (max-width: 767px) {
                        width: 80px;
                    }
    
                    @media (max-width: 575px) {
                        width: 40px;
                        
                    }
                }

                .playstore {
                    display: flex;
                    transition: all .5s ease;

                    @media (max-width: 767px) {
                        width: 80px;
                    }
    
                    @media (max-width: 575px) {
                        width: 40px;
                        
                    }
                }
            }
        }

        .hr {
            border-left: 2px solid #EA9B45;
            height: 350px;

            @media (max-width: 991.9px) {
                height: 290px;
            }

            @media (max-width: 767.9px) {
                height: 230px;
            }

            @media (max-width: 575.9px) {
                height: 120px;
            } 
        }
        @media (max-width: 575px) {
            height: 116px;
        }
    }

}

.pac-container {
    box-shadow: none !important;
    border-top: 1px solid transparent;
}

.pac-matched,
.pac-item {
    font-size: 15px;
    font-family: 'Roboto';
    color: #212529;
    border-top: 0 solid transparent;
}

.pac-icon-marker {
    background-position: -1px -161px;
}

.pac-logo:after {
    display: none;
}