@import "assets/scss/_helpers/variables";

.pagination {
  li {
    height: 35px !important;
    width: 35px !important;
    &:hover {
      background-color: map-get($theme-colors, "primary");
      i {
        color: #fff;
      }
    }
  }
}

.custom-pg-dropdown {
  width: 100%;
  max-width: 65px;
}
.pagination-container select {
  max-width: 85px;
  padding: 7px 15px !important;
  width: 100% !important;
}

.custom-pg-dropdown .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-pg-dropdown .dropdown-toggle {
  background: #EA9B45;
  color: #fff;
  border: 1px solid #EA9B45;
  padding: 8px 15px;
  width: 100%;
  text-align: left;
}
