@import "../../../../assets/scss/_helpers/variables";

.all-location-holder {
  background-color: map-get($theme-colors, "sixth");
  border-radius: 7px;
  padding: 20px;
  min-height: 395px;
  @media (max-width: 991px) {
    min-height: 100%;
  }
  .all-locations {
    display: flex;
    margin-bottom: 30px;
    .icons {
      text-align: center;
      width: 60px;
      img {
        width: 100%;
        max-width: 40px;
        height: 36px;
      }
      div {
        margin-top: 34px;
        &:nth-child(1) {
          margin-top: 0px;
        }
      }

      p {
        margin-bottom: 0;
        position: relative;
        margin-top: 20px;
        color: map-get($theme-colors, "darkblack");
        &::after {
          position: absolute;
          height: 25px;
          border-left: 1px dashed map-get($theme-colors, "primary");
          content: "";
          width: 4px;
          left: 50%;
          top: -27px;
          transform: translate(-50%);
        }
        &::before {
          position: absolute;
          height: 25px;
          border-left: 1px dashed map-get($theme-colors, "primary");
          content: "";
          width: 4px;
          left: 50%;
          bottom: -27px;
          transform: translate(-50%);
        }
      }
    }
    .location-detail {
      width: 100%;
      margin-left: 30px;
      border-bottom: 1px solid #928f8f;
      @media (max-width: 550px) {
        margin-left: 10px;
      }
      a {
        display: block;
        border-bottom: 3px solid map-get($theme-colors, "border");
        margin-bottom: 30px;
        font-size: 14px;
      }
      p {
        font-size: 16px;
        font-weight: 500;
      }
      div {
        &:last-child {
          a {
            border: none;
          }
        }
      }
    }
  }
}

.all-location-holder .all-locations .location-detail .caption {
  color: #928f8f;
  font-weight: 400;
}
.all-locations:last-child .location-detail {
  border-bottom: none;
}
.all-locations .icons i {
  font-size: 20px;
}
