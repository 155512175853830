.inner-container {
  .leasing-app-container {
    .var-data {
      // color: #EA9B45;
      display: block;
      text-transform: uppercase;
      font: normal normal 600 18px/27px Roboto;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .inline-var-data {
      // color: #EA9B45;
      font: normal normal 600 18px/27px Roboto;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    h4 {
      color: #2c2c2c;
      font: normal normal 600 22px/27px Roboto;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    h5 {
      color: #2c2c2c;
      font: normal normal 600 20px/25px Roboto;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    p {
      color: #515151;
      font: normal normal normal 18px/27px Roboto;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    ul {
      li {
        color: #928f8f;
        font: normal normal normal 18px/27px Roboto;
        @media (max-width: 767px) {
          font-size: 14px;
        }
        &::marker {
          color: #2c2c2c;
        }
      }
    }
  }
  .term-condition {
    .form-check {
      display: flex;
      p {
        margin-left: 0.5rem;
        font: normal normal normal 18px/27px Roboto;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      position: relative;
      label {
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 24px;
        left: 0;
        position: relative;
        top: 0;
        width: 24px;
      }

      label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 5px;
        opacity: 0;
        position: absolute;
        top: 6px;
        transform: rotate(-45deg);
        width: 12px;
      }

      input[type="checkbox"] {
        visibility: hidden;
      }

      input[type="checkbox"]:checked + label {
        background-color: #EA9B45;
        border-color: #EA9B45;
      }

      input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
    }
  }
  .signature-box {
    padding-left: 1.5rem;
    p {
      font: normal normal normal 18px/27px Roboto;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .user-signature {
      border: 1px solid #707070;
      height: 6rem;
      width: 40%;
      margin: 15px 0px;
      background: #ffffff;
      border-radius: 7px;
    }
  }
}
