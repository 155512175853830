@import "assets/scss/_helpers/variables";

.review-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}

.reviews-details {
  .box-reviews {
    // background-color: map-get($theme-colors, "sixth");
    // border-radius: 12px;
    //width: 126px;
    //  text-align: left;
    // height: 90px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    padding: 0.5rem 1rem;
    margin: 2rem 0rem;

    @media (max-width: 575px) {
      margin-right: 0.5rem !important;
    }
  }

  .nav-tabs {
    border: none;

    .nav-link.active {
      border: none;
      color: #ea9b45;
      border-bottom: 1px solid #ea9b45;
    }

    .nav-item.show .nav-link {
      border: none;
    }

    .nav-link {
      border: none;
      color: #495057;

      @media screen and (max-width: 425px) {
        font-size: 0.813rem;
      }
    }
  }

  // .if-no-review {
  //   height: 100%;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  // }

}

@media (min-width: 992px) {
  .box {
    height: calc(100% - (-100px));
  }
}

@media (max-width: 992px) {
  .box {
    height: calc(100% - (-45px));
  }
}

.no-review-mainbox {
  height: 100%;
  width: 100%;

  // display: flex;
  .box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      max-width: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 100%
      }

      span {
        font-size: 18px;
        line-height: 10px;
        font-weight: 500;
        color: #2C2C2C;
        margin-top: 20px;
      }
    }
  }
}

.reviews-dashboard {
  .reviews {
    max-width: 733px;
  }
}

.review-back {
  a {
    text-decoration: none;

    svg {
      margin-right: 8px;
      margin-top: -2px;
    }
  }
}

.write-review-comment {
  border: 1px solid map-get($theme-colors, "fifth");
  padding: 13px 19px;
  height: 127px;
  border-radius: 12px;
}

.reply-inner {
  flex-direction: column;
  width: 100%;
  border-left: 2px solid map-get($theme-colors, "fifth");
  padding-left: 15px;
  margin-bottom: 5px;

  img {
    width: 22px !important;
    height: 22px !important;
    object-fit: cover;
  }
}

.font-18 {
  font-size: 18px;
}

.text-yellow {
  color: #fec156;
}

.item-main-img {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 100%;
}

.item-main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reply-img {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  object-fit: cover;
}

.rewiew-comment.mt-3 {
  max-width: 733px;
}

.comment-section {
  display: flex;
  background: #7070701a 0% 0% no-repeat padding-box;
  border-radius: 6px;
  margin: 1rem 0rem;
  width: 80%;
  padding: 1rem 0rem;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .comment-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    border-bottom: 1px solid #d7d7d7;

    p {
      color: #ea9b45;
      font-size: 18px;
      font-weight: 500;
      margin: 0rem;
    }

    span {
      color: #140000;
      text-decoration: none;
      font-size: 14px;
    }

    .view-details {
      color: #140000;
      text-decoration: none;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .blur {
    filter: blur(4px);
  }

  .reveal-comment {
    margin: auto;
    // margin-top: -70px;
  }

  .reveal-comment-none {
    display: none;
  }

  .comment-body {
    padding: 0rem 1.5rem;
    margin-top: 1rem;
    //margin-bottom: 1rem;
    width: 100%;

    .reviewer-profile {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;
      align-items: center;
      margin: 0px 0px;

      .user-profile {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          margin-right: 0.5rem;
          object-fit: cover;
          object-position: center;
        }

        .name {
          color: #000000;
          font-size: 14px;
        }
      }

      .rating-section {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {
          margin-right: 0.35rem;
        }

        p {
          margin-top: 1rem;
          font-size: 18px;
          color: #2c2c2c;
        }
      }
    }

    .comment {
      color: #515151;
      margin: 0rem;
      font-size: 16px;
      margin-left: 0.5rem;
      padding-left: 27px;
      margin-bottom: 0;
    }

    .comment-date {
      color: #928f8f;
      font-size: 14px;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      padding-left: 27px;
      margin-bottom: 0;

      .timer-icon {
        margin-right: 0.3rem;
        height: 13px;
        width: 13px;
      }
    }

    .edited-comment {
      margin-left: 5px;
      color: #928f8f;
      font-size: 0.9rem;
      text-decoration: underline;
    }

    .post-comment-section {
      margin-top: 1rem;
      border-left: 1px solid #707070;
      margin-left: 2rem;
      padding-left: 1rem;

      .user-profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0rem;

        img {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          margin-right: 0.5rem;
          object-fit: cover;
          object-position: center;
        }

        .rating-section {
          display: flex;
          flex-direction: row;
          align-items: center;

          i {
            margin-right: 0.35rem;
            font-size: 17px !important;
          }

          p {
            margin-top: 1rem;
            font-size: 16px;
            color: #2c2c2c;
          }
        }

        .name {
          color: #000000;
          font-size: 14px;
          margin: 0rem;
        }
      }

      .reply {
        margin-top: 0.5rem;
        margin-bottom: 0rem;
        margin-left: 0.5rem;
        padding-left: 27px;
        color: #000000;
        font-size: 16px;
      }
    }
  }

  .challenge-btn {
    border: 1px solid #707070;
    color: #000000;
    margin-right: 0.5rem;
  }

  .post-btn {
    background-color: #ea9b45;
    color: white !important;
    margin-right: 0.5rem;

    &:hover {
      color: white !important;
    }
  }
}

.under-consideration-header,
.under-consideration-body {
  opacity: 0.5;
}

.under-consideration {
  // padding-left: 1rem;
  margin-top: 1rem;
  width: 100%;

  p {
    border-top: 1px solid #707070;
    padding-top: 1rem;
    padding-left: 1.5rem;
    margin: 0rem;
  }
}

.expires-in {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0.5rem;

  .info-icon {
    color: #e84c4c;
    border: 1px solid #e84c4c;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    text-align: center;
  }

  p {
    margin: 0rem;
    font-size: 14px;
  }
}

.feedback-section {
  margin-bottom: 1rem;
  padding: 0rem 1rem;
  .height-max {
    max-height: 304px;
    overflow: auto;
  }
  .question {
    margin-bottom: 1rem;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }

  [type="radio"]:checked+label:before,
  [type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 4px;
    width: 12px;
    height: 12px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #ea9b45;
    position: absolute;
    top: 4px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .no-selection:before,
  .no-selection:after {
    background: none !important;
  }

  .type-feedback {
    margin: 1rem 0rem;
    border: 1px solid #928f8f;
    border-radius: 10px;
    padding: 0.5rem 1rem;

    textarea {
      resize: none;
      width: 100%;
      height: 9rem;
      border: none;
    }
  }
}

.feedback-comment-section {
  width: 80%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .comment-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    p {
      color: #ea9b45;
      font-size: 18px;
      font-weight: 500;
      margin: 0rem;
    }

    span {
      color: #140000;
      text-decoration: none;
      font-size: 14px;
    }

    .view-details {
      color: #140000;
      text-decoration: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

// .item {
//   width: 100%;
//   padding: 15px 0px;
//   // background-color: gray;
//   font-size: 15pt;
//   text-align: center;
//   margin-bottom: 4px;
// }

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}