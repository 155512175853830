.location-map {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
    .pin-location {
      background-color: #EA9B45;
      display: inline-block;
      padding: 7px;
      border-radius: 10px;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    .marked-location {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      img {
        max-width: 100%;
      }
    }
  }
  