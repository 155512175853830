@import "../../../../assets/scss/_helpers/variables";

.filter {
  .choose-filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 35px;
    position: relative;
    padding: 0 50px;
    @media (max-width: 1399.9px) {
      padding: 0;
    }
    ul {
      display: flex;
      margin-bottom: 0;
      align-items: center;
      padding-left: 0;
      flex-wrap: wrap;

      li {
        list-style: none;
        text-align: center;
        // margin: 0 12px;
        margin: 0;
        font: normal normal normal 16px/14px Roboto;
        cursor: pointer;

        div {
          border: 2px solid #000;
          font-weight: 600;
          padding: 8px 8px;
          border-radius: 50%;
          width: fit-content;
          margin: auto;
          height: 50px;
          width: 50px;
          overflow: hidden;

          img {
            object-fit: cover;
            width: 100%;
          }

          &.active {
            border: 2px solid map-get($theme-colors, "primary");
            font-weight: 600;
            padding: 8px 8px;
            border-radius: 50%;
            margin: auto;
          }

          @media (max-width: 991px) {
            margin: 5px 5px;
            font-size: 14px;
          }
        }
      }
    }

    button {
      border: 1px solid #f2f2f2;
      padding: 12px 25px;
      font-size: 18px;
      border-radius: 8px;
      font-weight: 600 !important;
    }

    .filter-btn {
      img {
        width: 15px;
        margin-right: 5px;
      }
    }

    .toggle-listing-map-btn {
      position: absolute;
      right: 0;
      @media (max-width: 1399.9px) {
        position: relative;
      }
      button {
        font-size: 18px !important;
        background: transparent;
        border: 1px solid #EA9B45;
        padding: 9px 15px;
        width: 150px !important;
        i {
          font-size: 18px !important;
          margin-left: 10px !important;
        }
      }
    }
    
  }

  .range-filter {
    margin-top: 0;
    label {
      color: map-get($theme-colors, "tertiary");
      font-size: 12px;
      padding-bottom: 10px;
      padding-left: 0;
      font-weight: 400;
      margin-top: 2rem;
    }

    .col-md-3 {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }

    .rc-slider {
      touch-action: none;
      margin: 6px;
      width: calc(100% - 12px);
      .rc-slider-rail {
        background-color: #cbcbcb;
        height: 2px;
      }

      .rc-slider-handle {
        border: solid 2px map-get($theme-colors, "primary");
        background-color: map-get($theme-colors, "primary");
        height: 11px;
        width: 11px;
      }

      .rc-slider-track {
        background-color: map-get($theme-colors, "primary");
        height: 2px;
      }

      .rc-slider-tooltip-inner {
        background-color: transparent;
        color: map-get($theme-colors, "tertiary");
        box-shadow: none;
      }

      .rc-slider-tooltip-arrow {
        display: none;
      }

      .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        box-shadow: none;
      }

      .rc-slider-tooltip {
        top: 13px !important;
        // bottom: -20px;
      }

      .rc-slider-dot {
        display: none;
      }

      .rc-slider-mark-text {
        transform: translateX(-100%) !important;
      }
    }
  }

  .filter-btm-btns {
    margin-top: 25px;
    input {
      border: 1px solid #928f8f;
      border-radius: 6px;
    }
    input,
    select {
      border: 1px solid map-get($theme-colors, "tertiary");
      font-size: 14px;
    }

    .DatePicker {
      display: block;

      input {
        width: 100%;
        text-align: left;
        padding: 8px 5px;
        border: 1px solid #cbcbcb;
        border-radius: 5px;
        font-size: 14px;
        color: #928f8f;
      }
    }

    .form-group {
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .avaible-btn {
      label {
        display: block;
        padding-bottom: 4.1px;
      }

      .selected {
        color: #000;
        background-color: #EA9B451a;
      }

      button :not(.selected) {
        border: 1px solid map-get($theme-colors, "tertiary");
      }
    }

  }

  .btn-outline-secondary {
    border-color: #928f8f !important;
  }

  .availability-btns{
    display: flex;
    .btn{
      flex: 1;
      min-width: fit-content;
      &:hover{
        background-color: #EA9B452E;
        border-color: #EA9B45 !important;
        color: #212529;
      }
    }
  }
}

.option-select {
  font-family: Roboto;
  border-radius: 8px;
  padding: 11px 35px 11px 12px !important;
  border: 1px solid map-get($theme-colors, "fifth") !important;
}

.tabs-slider {
  max-width: 600px;
  // width: 100%;
  padding: 3px 15px;

  @media (min-width: 1400px) {
    max-width: 1100px;
    width: 1100px;
  }

  @media (max-width: 767px) {
    max-width: 520px;
  }

  @media (max-width: 575.96px) {
    max-width: 460px;
  }

  @media (max-width: 480px) {
    max-width: 320px;
  }

  .swiper-button-next {
    border: 1px solid #cbcbcb;
    border-radius: 30px;
    height: 27px;
    width: 27px;
    top: 50%;
    right: 0;
    background-color: #fff;

    // box-shadow: 0px 0px 12px 20px #fff;
    &:hover {
      background-color: map-get($theme-colors, "primary");

      &::after {
        color: #fff !important;
      }
    }

    &::after {
      font-size: 16px;
      color: map-get($theme-colors, "primary");
    }
  }

  .swiper-button-prev {
    border: 1px solid #cbcbcb;
    border-radius: 30px;
    height: 27px;
    width: 27px;
    top: 50%;
    left: 0;
    line-height: 27px;
    background-color: #fff;

    // box-shadow: 0px 0px 12px 20px #fff;
    &:hover {
      background-color: map-get($theme-colors, "primary");

      &::after {
        color: #fff !important;
      }
    }

    &::after {
      font-size: 16px;
      color: map-get($theme-colors, "primary");
    }
  }

  .swiper-wrapper {
    align-items: center;
    // margin: 0px 50px;
    display: inline-flex;

    // overflow: hidden;
    .swiper-slide {
      width: fit-content !important;
      padding: 0px 20px;

      @media screen and (max-width: 767px) {
        padding: 0px 2px;
      }

      @media only screen and (max-width: 991px) and (min-width: 768px) {
        padding: 0px 15px;
      }

      @media (min-width: 1400px) {
        padding: 0px 15px;
      }

      // @media only screen and (max-width: 1199px) and (min-width:992px) {
      //   padding: 0px 20px;
      // }
    }
  }
}

.custon-dateRange {
  img {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
  input {
    padding-right: 35px;
  }
}

.css-1ko62if-control {
  border-color: #928f8f;
}

.tab-slider {
  max-width: 880px;
  margin: auto;
}

@media only screen and (max-width: 1399.99px) {
  .toggle-listing-map-btn {
    order: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }
}

@media only screen and (max-width: 991.9px) {
  .toggle-listing-map-btn {
    order: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }

  .filter-btn {
    order: 2;
  }

  .tab-slider {
    order: 3;
  }

  .filter {
    .choose-filter {
      button {
        padding: 9px 15px !important;
        font-size: 14px;
      }
      .toggle-listing-map-btn {
        button {
          font-size: 14px;
          padding: 9px 15px;
          width: auto;
          i {
            font-size: 14px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.reset-btn {
  margin-top: 7px;
}

.stand-card-sorting {
  position: relative;
  max-width: 150px;
  flex: 0 0 150px;

  select {
    border-radius: 8px;
    border: 1px solid #928f8f;
    color: #2c2c2c;
    margin-top: auto;
    margin-bottom: auto;
    padding: 9px 15px;
    font-size: 18px;
    font-weight: 600;
    appearance: none;
    width: 100%;
    position: relative;

    &::after {
      content: "\F282";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}

.custom-spicies-silder {
  max-width: 880px;
  margin: 0 auto;
  width: 100%;
  .slick-slider {
    @media(max-width: 420px) {
      padding: 0 10px;
    }
  }
}

.slider-items {
  text-align: center;
  cursor: pointer;
}

.slide-image {
  width: 50px;
  height: 50px;
  border: 2px solid #000;
  border-radius: 100%;
  overflow: hidden;
  padding: 5px;
  margin: auto;
  transition: all 0.5s ease-in;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.5s ease-in;
  }
}

.slider-items {
  &:hover {
    .slide-image {
      img {
        transform: scale(1.1);
      }
    }
  }
  p {
    margin: 5px !important;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.slick-arrow {
  border: 1px solid #cbcbcb;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  color: #cbcbcb;
  background: transparent;
  z-index: 8;
  top: 15px;
  position: absolute;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #EA9B45;
  }
  &.arrow-left {
    left: -10px;
  }
  &.arrow-right {
    right: -10px;
  }
}

@media only screen and (max-width: 1399px) {
  .custom-spicies-silder {
    max-width: 100%;
    margin-top: 30px;
    order: 3;
  }

  .stand-card-sorting {
    order: 2;
  }
}


.sticky-section {
  position: sticky;
  top: 67px;
  left: 0;
  background: #ffffff;
  padding: 5px 0;
  z-index: 101;
  min-width: calc(100vw - 18px);
}

.points-of-interest-select {
  .css-g1d714-ValueContainer {
    height: 39px;
  }
}

.btn-group.action-button {
  &.clicked {
    .dropdown-toggle {
      &::after {
        transform: rotate(45deg);
      }
    }
  }

  .dropdown-toggle {
    max-width: 44px;
    border-radius: 50%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #EA9B45 !important;

    &::after {
      display: inline-block;
      margin-left: 0;
      vertical-align: middle;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      content: "\2b";
      font-family: "Font Awesome 6 Pro";
      font-size: 30px;
      transition: .4s all ease-in-out !important;
    }

    &:focus,
    &:hover {
      color: #EA9B45;

      &::after {
        color: #EA9B45;
      }
    }
  }

  .dropdown-menu {
    width: auto;
    min-width: auto;
    border: 0;
    }

  .map-filter-btn {
    padding: 0 0 10px 0;

    &:hover {
      background-color: transparent;
      color: #EA9B45;
    }

    button {
      width: 44px !important;
      padding: 0;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;

      i {
        margin-left: 0 !important;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.stands {
  .btn-group.action-button {
    .dropdown-menu{
      @media only screen and (min-width:1400px) {
        background-color: transparent;
      }
    }
  }
}