.create-group-modal {
  .modal-content {
    border-radius: 9px;
  }

  .modal-header {
    align-items: center;
    padding: 60px 60px 0 60px;
    border-bottom: 0;
    margin-bottom: 4px;

    @media (max-width: 575.9px) {
      padding: 30px 30px 0 30px;
    }

    .btn-close {
      width: 17.7px;
      height: 17.7px;
      opacity: 1;

      @media (max-width: 575.9px) {
        top: 30px;
        right: 30px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 18px;
      margin: 0;
      color: #2C2C2C;
      font-family: 'Minion Pro';
      font-weight: 500;

      @media (max-width: 575.9px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0;
      text-align: center;
      margin-top: 6px;

      a {
        font-size: 16px;
        line-height: 20px;
        color: #EA9B45;
      }

      @media (max-width: 575.9px) {
        font-size: 14px;
        list-style: 18px;

        a {
          font-size: 14px;
          list-style: 18px;
        }
      }
    }
  }

  .modal-body {
    padding: 20px 60px 67px 60px;

    @media (max-width: 575.9px) {
      padding: 20px 30px 30px 30px;
    }

    .create-group-form {
      .form-floating {
        input {
          border: 1px solid #928f8f;
          height: 48px;
          border-radius: 10px;
          padding-top: 20px !important;
        }

        label {
          padding-top: 12px;
        }
      }

      .save-btn {
        background-color: #EA9B45;
        border-radius: 10px;
        border: 0;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 1.3;
        padding-block: 15px;
        width: 100%;
        max-width: 146px;
        margin-top: 30px;

        &:hover,
        &:active,
        &:focus {
          opacity: .95;
        }
        @media (max-width: 575.9px) {
          margin-top: 16px;
          max-width:none;
          padding-block: 12px;
        }
      }
    }

  }
}