.wavier-form-agreement {
  margin: 2rem 0rem;
  overflow-x: hidden;
  .header {
    font-size: 60px;
    text-align: center;
  }
  .important-note {
    font-weight: bold;
    text-transform: uppercase;
  }
  .display-block {
    display: block;
  }
  .display-inline-block {
    display: inline;
    text-align: center;
  }
  .font-small {
    font-size: 16px !important;
  }

  .btn-clear {
    float: right;
  }
  .footer {
    display: inline-block !important;
  }
  .signature {
    visibility: hidden;
  }
  .sigCanvas {
    border: 1px solid gray;
    height: 120px !important;
    @media(max-width: 600px) {
      width: 100% !important;
    }
  }

  .signature-sec {
    margin: auto;
  }
  .mt-100 {
    margin-top: 100px;
  }

  .signature-label {
    width: 81px;
    margin: auto
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0rem;
    flex-wrap: wrap;
    span {
      font-weight: bold;
      font-size: 18px;
    }
    .line {
      width: 170px;
      height: 2px;
      border: 1px solid black;
    }
  }
  .wavier-btn {
    @media(max-width: 575.9px) {
        flex-direction: column;
        max-width: 400px;
        margin: auto;
        button {
          width: 100%;
        }
    }
  }
}
