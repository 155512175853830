@import "../../../../assets/scss/_helpers/variables";
.wavier-form {
  height: 100%;
  .wavier-form-container {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // align-items: center;
    padding-left: 0rem;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-right: 0 !important;
    }
    @media screen and (max-height: 767px) {
      align-items: flex-start;
    }
    .side-img {
      height: 100vh;
      width: 45%;
      /* margin-right: 4rem; */
      position: fixed;
      @media screen and (max-width: 767px) {
        width: 100vw;
        position: relative;
        height: 270px;
      }
      @media screen and (max-width: 575px) {
        height: 230px;
      }
      img {
        height: 100vh;
        object-fit: cover;
        object-position: center;
        width: 100%;
        @media screen and (max-width: 767px) {
          height: 270px;
        }
        @media screen and (max-width: 575px) {
          height: 230px;
        }
      }
    }
    .wavier-form-body {
      width: 55%;
      padding: 2rem 0rem;
      margin-left: 45%;
      padding-left: 3rem;
      padding-right: 3rem;
      @media screen and (max-width: 1399px) {
        padding-left: 3rem;
        padding-right: 1.5rem;
      }
      @media screen and (max-width: 767px) {
        width: 90%;
        margin: 0 auto;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
      form {
        width: 100%;
      }
      .header {
        @media (max-width: 991px) {
          text-align: left !important;
        }
        @media (max-width: 575px) {
          padding-bottom: 20px !important;
        }
        p {
          font-size: 40px;
          color: #2c2c2c;
          margin: 0;
          @media (max-width: 575px) {
            font-size: 32px;
          }
          @media (max-width: 430px) {
            font-size: 28px;
          }
        }
      }
      .subtitle {
        color: #515151;
        margin: 0;
      }
      .question-section {
        .question {
          margin-top: 2rem;
          .statement {
            margin-bottom: 1rem;
            color: #000000;
            font-size: 16px;
            padding: 0rem;
            position: relative;
            &::before {
              content: "";
              width: 10px;
              height: 10px;
              border-radius: 50%;
              position: absolute;
              background: map-get($theme-colors, "primary");
              top: 5px;
              margin-left: -1rem;
            }
          }
          .no-section {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            span {
              color: #0000008a;
              font-size: 0.813rem;
            }
            .reason-statement {
              margin-top: 1rem;
              width: 60%;
              border: 1px solid #cbcbcb;
              border-radius: 12px;
              padding: 0.5rem;
              font-size: 0.813rem;
              height: 5rem;
              @media screen and (max-width: 767px) {
                width: 100%;
              }
              textarea {
                resize: none;
                border: none;
                width: 100%;
                height: 4rem;
                box-shadow: none;
                outline: none;
              }
            }
          }
        }
        .form-floating {
          width: 60%;
          @media (max-width: 1199px) {
            width: 80%;
          }
          @media (max-width: 757px) {
            width: 100%;
          }
          input{
            margin-top: 1rem;
            border: 1px solid #cbcbcb;
            border-radius: 12px;
            padding: 0.5rem;
            font-size: 0.813rem;
            border: 1px solid #000000;
            border-radius: 12px;
            opacity: 1;
            font-size: 14px;
            line-height: 20px;
            height: 50px;
            padding-top: 14px;
          }
          label {
            font-size: 12px;
            color: #515151;
          }
        }
      }
      .next {
        width: 115px;
        margin: 1rem 0rem;
        &.disabled {
          background-color: #999;
          border-color: #999;
        }
        
      }
      .cancel {
        width: 115px;
        margin: 1rem 0rem;
      }
    }
  }
}
