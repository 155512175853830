@import "../../../../../assets/scss/_helpers/variables";

.navlink-item {
  ul {
    padding: 0;
    li {
      list-style: none;
      font-size: 16px;
      margin-bottom: 15px;

      a {
        color: map-get($theme-colors, "tertiary");
        text-decoration: none;
        display: block;
        &.active {
          color: #2c2c2c;
          font-weight: bold;
          border-right: 2px solid map-get($theme-colors, "primary");
        }
      }
    }
  }
}
