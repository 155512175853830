.next-available-text {
    margin-left: 20px;
    height: 21px;
    text-align: left;
    font: normal normal normal 16px/15px Roboto;
    letter-spacing: 0px;
    color: #ABABAB;
}

.next-available-date {
    color: var(--unnamed-color-ea9b45);
    text-align: left;
    font: normal normal bold 16px/15px Roboto;
    letter-spacing: 0px;
    color: #EA9B45;
}

.next-available-text-map-view {
    height: 21px;
    text-align: left;
    font: normal normal normal 16px/15px Roboto;
    letter-spacing: 0px;
    color: #ABABAB;
}

.pointer-events-none {
    pointer-events: none;
}

.course-details {
    .next-available-booking {
        border-left: 3px solid #EA9B45;
        margin-left: 0;
        padding-left: 7px;
        margin-top: 8px;
        margin-right: 5px;
        margin-bottom: 5px;

        .next-available-description {
            text-align: left;
            font: normal normal normal 14px/22px Roboto;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 8px;
            line-height: 1;
        }

        .next-available-date {
            color: var(--unnamed-color-ea9b45);
            text-align: left;
            font: normal normal normal 14px/22px Roboto;
            letter-spacing: 0px;
            color: #EA9B45;
            margin: 0px;
            line-height: 1;
        }
    }
}

.price-with-icon {
    img {
        width: 18px;
        object-fit: cover;
        margin-right: 5px;
    }
    span {
        text-align: left;
        font-size: 14px;
        line-height: 8px;
        letter-spacing: 0px;
        color: #2C2C2C;
    }
}