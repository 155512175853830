.reservation-success-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  flex-direction: column;
  text-align: center;
}
.reservation-success-wrapper h4 {
  font-size: 24px;
  margin: 15px 0 0;
}
.reservation-success-wrapper p {
  font-size: 18px;
  color: #928f8f;
  max-width: 420px;
  margin: 15px auto;
}
.reservation-success-wrapper a {
  font-size: 18px;
  padding: 12px 30px;
}
@media only screen and (max-width: 767px) {
  .reservation-success-wrapper h4 {
    font-size: 20px;
  }
  .reservation-success-wrapper p {
    font-size: 14px;
    max-width: 320px;
  }
  .reservation-success-wrapper a {
    font-size: 14px;
    padding: 10px 20px;
  }
}
