@import "../../../../assets/scss/_helpers/variables";

.stand-main-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 25px 0px;

  width: 100%;
}

.search-form {
  display: flex;
  max-width: 767px;
  width: 60%;
  position: relative;

  @media screen and (max-width: 425px) {
    width: 90%;
  }

  .form-control {
    border-radius: 7px;
    border: 1px solid #928f8f;
    color: #2c2c2c;
    font: normal normal bold 16px/20px Roboto;
    height: 48px;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    padding-right: 32px;

    &::placeholder {
      color: map-get($theme-colors, "tertiary");
      font: normal normal normal 16px/20px Roboto;
    }
  }

  .search-btn {
    border-radius: 10px;
    height: 48px;
    font-size: 18px;
    margin-top: auto;
  }

  .filter-btn {
    width: 10%;

    button {
      border: 1px solid #928f8f;
      padding: 12px 20px;
      border-radius: 8px;
      background: transparent;
      margin-left: 15px;
    }
  }

  .first-input {
    min-width: 340px;

    @media screen and (max-width: 425px) {
      min-width: unset;
    }
  }

  .col-auto {
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.new-filter-btn {
  height: 46px;
  position: absolute;
  right: 1px;
  top: 1px;
  border: 0;
  padding: 0 !important;
  display: flex;
  align-items: center;
  border-radius: 0 7px 7px 0;
  width: 34px;
  justify-content: center;

  button {
    // position: absolute !important;
    // right: 12px;
    // top: 0;
    background-color: transparent !important;
    border: 0;
    padding: 0 !important;

    &:focus,
    &:hover {
      background-color: transparent;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    z-index: 1000;
  }
  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        &:active ,
        &.active {
          background-color: #EA9B45;
          color: #ffffff;
        }
      }
    }
  }
    
}