.reviewer-profile {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  flex-wrap: wrap;
  img {
    margin-right: 0.5rem;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  p {
    font-size: 0.9rem;
    color: #707070;
  }
}
