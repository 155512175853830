.deleted-stand-div {
  .modal-header {
    padding: 1rem 2rem;
  }
  .deleted-stand-body {
    padding: 0rem 5rem 2rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 768px) {
      padding: 0rem 1.5rem 2rem;
    }
    .title {
      color: #2c2c2c;
      font-size: 28px;
      font-weight: 600;
      margin: 0;
      margin-top: 0.5rem;
    }
    .body {
      color: #515151;
      font-size: 1rem;
      text-align: center;
    }
  }
}
