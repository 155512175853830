.blog-loader {
    margin: auto;
    margin-bottom: 20px !important;
    border: 3px solid #EA9B45;
    border-radius: 50%;
    border-top: 3px solid #f3f3f3;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }