@import "../_helpers/variables";

.btn {
  padding-top: 5px;
  padding-bottom: 5px;
  @media (max-width:767px) {
    padding: 8px 20px !important;
  }
}

.btn.btn-secondary {
  border: 1px solid map-get($theme-colors, "primary");
  background: map-get($theme-colors, "white");
  color: map-get($theme-colors, "primary");
  
  &:hover {
    background: map-get($theme-colors, "primary");
    color: #fff;
    border: 1px solid map-get($theme-colors, "primary");
  }
}

// .btn-outline-secondary {
//   border-width: 2px;
//   box-shadow: $secondary-btn-box-shadow;
// }

// .btn-outline-primary {
//   box-shadow: $primary-btn-box-shadow;
// }
