@import "../../../../assets/scss/_helpers/variables";

.status-bar {
  position: relative;
  display: flex;
  padding: 20px;
  border: 1px solid #cb2027;
  border-radius: 12px;
  align-items: flex-start;
  .content-holder {
    margin-left: 15px;
    h5 {
      font-size: 20px;
      color: #2c2c2c;
      margin: 0;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }
    p {
      color: #928f8f;
    }
  }
}

.top-bar {
  margin-bottom: 24px;
  .top {
    display: flex;
    align-items: center;
    cursor: pointer;
    .bi {
      font-size: 26px;
      line-height: 1;
    }
    span {
      color: map-get($theme-colors, "seventh");
      font-size: 18px;
      font-weight: 400;
    }
  }
  .path {
    ul {
      padding-left: 7px;
      display: flex;
      list-style: none;
      align-items: center;
      flex-wrap: wrap;
      li {
        &.active {
          color: map-get($theme-colors, "tertiary");
        }
      }
    }
  }
}
