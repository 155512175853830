.agreement-modal {

    .modal-content {
        position: relative;

        .close-btn {
            position: absolute;
            right: 60px;
            top: 60px;
            z-index: 2;
            cursor: pointer;
            @media (max-width: 991.9px) {
                right: 30px;
                top: 30px;
            }
            img {
                width: 20px;
                height: 20px;
                display: flex;
                @media (max-width: 991.9px) {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    .modal-body {
        padding: 60px;
        @media (max-width: 991.9px) {
            padding: 30px;
        }
        h2 {
            padding-right: 25px;
            @media (max-width: 991.9px) {
                strong {
                    font-size: 20px;
                }
            }
        }

        h3 {
            font-size: 40px;
            line-height: 27px;
            color: #212529;
            font-family: 'Minionpro';
            font-weight: 400;
            margin-bottom: 30px;
        }

        .state {
            margin-bottom: 30px;

            &>div {
                margin-bottom: 8px;
            }

            span {
                letter-spacing: 0px;
                color: #515151;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 600;

                &:not(:last-child) {
                    padding-right: 7px;
                }
            }
        }

        h6 {
            letter-spacing: 0px;
            color: #515151;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        b {
            letter-spacing: 0px;
            color: #515151;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 600;
        }

        p {
            letter-spacing: 0px;
            color: #515151;
            // text-transform: uppercase;
            font-size: 16px;
            font-weight: 400;
        }

        span {
            letter-spacing: 0px;
            color: #515151;
            // text-transform: capitalize;
            font-size: 16px;
            font-weight: 400;
        }

        .form-check {
            margin: 40px 0 20px 0;
            @media (max-width: 991.9px) {
                margin: 10px 0 20px 0;
            }
        }

        .form-check-label {
            letter-spacing: 0px;
            color: #515151;
            font-size: 16px;
            padding-left: 5px;
            font-weight: 400;

            a {
                letter-spacing: 0px;
                color: #EA9B45;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .form-check-input {
            &[type=checkbox] {
                border-radius: 0;
            }

            &:focus {
                border-color: #EA9B45;
            }

            &:checked {
                background-color: #EA9B45;
                border-color: #EA9B45;
            }
        }

        .signature-sec {
            padding-left: 1.5em;
            @media(max-width: 991.9px) {
                padding-left: 0;
            }
            .signature-main {
                canvas {
                    @media(max-width: 991.9px) {
                        width: 100% !important;
                    }
                }
            }
        }

        .signature-main {
            position: relative;
            width: fit-content;
            display: flex;

            p {
                color: #515151;
                opacity: 0.6;
                text-transform: capitalize;
                position: absolute;
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-bottom: 0;
            }
        }

        canvas {
            border: 1px solid rgb(81 81 81 / 60%);
            height: 120px !important;
            border-radius: 6px;
        }

        button {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            width: 160px;
            justify-content: center;
            padding: 10px;
            letter-spacing: .5px;

        }
    }

    .display-flex {
        display: flex;
        @media (max-width: 991.9px) {
            width: 100%;
            justify-content: space-evenly;
        }
        @media (max-width: 420.9px) {
            flex-direction: column;
            gap: 10px;
            align-items: center;
        }
        button {
            @media (max-width: 991.9px) {
                font-size: 12px;
            }
        }
    }
}

.sign_error {
    margin-bottom: 20px;
}

img[src=":signature"] {
    display: none;
}