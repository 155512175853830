@import "../../../../assets/scss/_helpers/variables";
.weekly-weather table td,
.weekly-weather table th {
  text-align: center;
  padding: 15px !important;
  border-color: #cbcbcb;
}
.weekly-weather table th {
  text-transform: capitalize;
  text-align: left;
}
.weekly-weather table td div {
  width: 5rem;
  text-align: left;
}
.weekly-weather table td .sunset,
.weekly-weather table td .humidity,
.weekly-weather table td .wind,
.weekly-weather table td .sunrise {
  width: 8rem;
}
.weekly-weather .table > :not(:first-child) {
  border-top: 2px solid #cbcbcb;
}

@media only screen and (max-width: 767px) {
  .weekly-weather table {
    width: 767px;
  }
}
.weekly-weather table td .weather-image {
  height: 50px;
  width: 50px;
}
