$theme-colors: (
  "primary": #EA9B45,
  "secondary": #515151,
  "tertiary": #928f8f,
  "white": #fff,
  "darkblack": #000000,
  "fifth": #cbcbcb,
  "sixth": #f9f9f9,
  "seventh" :#2C2C2C,
  'border': #F2F2F2,
  'eight' :#EA9B450D,
  'star' : #E9BF45,
  'danger' : #ff3333
);
// map-get($theme-colors, "tertiary");
// // $font-family-base:  sans-serif;
// $font-size-base: 1rem;
// // Anchor element
$navbar-dark-color: map-get($theme-colors, "white");
$btn-padding-x: 30px;
$btn-padding-y: 8px;
$btn-border-radius: 6px;
$btn-font-size: 14px;
$border-height: 2px;
// $btn-padding-y:               5px ;
// $navbar-nav-link-padding-x:               15px ;
// $btn-link-color:map-get($theme-colors, "fourth") ;
// $link-hover-decoration: none;

// // Headings

// $h1-font-size: $h1-font-size;
// $h2-font-size: $h2-font-size;
// $h3-font-size: $h3-font-size;
// $h4-font-size: $h4-font-size;
// $h5-font-size: $h5-font-size;
// $headings-font-weight: normal;

// // paragraph-color
// $paragraph-color: $paragraph-color;

// // Input
// $input-btn-padding-y: 0.44rem;
// $input-btn-padding-x: 1.4rem;

// // Buttons
// $btn-border-radius: 7px;

// //nav link
// $btn-font-size: 20px;

// // Button
// $btn-box-shadow: $primary-btn-box-shadow;
// $btn-font-family: $btn-font-family;

// // dropdown
// $dropdown-bg: map-get($theme-colors, "seventh");
// $dropdown-border-width: 0;
// $dropdown-link-color: map-get($theme-colors, "tertiary");
// $dropdown-link-hover-bg: map-get($theme-colors, "secondary");
// $dropdown-link-hover-color: #fff;
