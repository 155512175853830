@import "../../../../assets/scss/_helpers/variables";


.schedule {
  .optional {
    color: #949494;
  }

  .react-calendar__tile--reserved-days {
    background: #AFFFB6;
  }

  .react-calendar__tile--blocked-days {
    background: #C8C5C5;
  }

  .react-calendar__tile--recovery-days {
    background: #F3ADD6;
  }

  .react-calendar__tile--special-occasion {
    background: #CEEEFC;
  }

  .react-calendar {
    position: relative !important;
  }

  .header {
    margin: 1rem 0rem;
    color: #2C2C2C;

    .add-icon {
      margin-right: 0.25rem;
    }
  }

  .reserved-date {
    background-color: #DFF7E1;
  }

  .reserved-date-disabled {
    background-color: #DFF7E1;
    pointer-events: none !important;
  }

  .react-calendar button {
    pointer-events: none;
  }

  .react-calendar__navigation button {
    pointer-events: auto;
  }

  .react-calendar1 button {
    pointer-events: auto;
  }

  .react-calendar__navigation__label {
    background-color: transparent;
  }

  .react-calendar__navigation__label__labelText {
    color: #000000;
  }

  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    pointer-events: auto !important;
  }

  .recovery-date {
    background-color: #F6E3EE;
  }

  .pre-blocked-date {
    background-color: #8C8989;
  }

  .pre-blocked-date-disabled {
    background-color: transparent;
    color: #e8e8e8;
    pointer-events: none !important;
  }

  .blocked-date {
    background-color: #C8C5C5;
  }

  .blocked-date-disabled {
    background-color: transparent;
    color: #e8e8e8;
    pointer-events: none !important;
  }

  .special-occasion-date {
    background-color: #CEEEFC;
  }

  .special-occasion-date:after {
    content: " *";
    color: #1EC42F;
  }

  .special-reserved-date {
    background-color: #DFF7E1;
  }

  .special-reserved-date:after {
    content: " *";
    color: #1EC42F;
  }

  .special-recover-date {
    background-color: #F6E3EE;
  }

  .special-recover-date:after {
    content: " *";
    color: #1EC42F;
  }

  .special-blocked-date {
    background-color: #C8C5C5;
  }

  .special-blocked-date-disabled {
    background-color: transparent;
    color: #e8e8e8;
    pointer-events: none !important;
  }

  .special-blocked-date:after {
    content: " *";
    color: #1EC42F;
  }

  .section1 {
    width: 90%;

    .day-section {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      span {
        color: #2C2C2CA6;
        font-size: 14px;
        position: relative;
        margin: 0rem 1rem;

        &::before {
          content: "";
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          background: red;
          top: 4px;
          margin-left: -1rem;
        }
      }

      .reserved-days {
        &::before {
          background: #DFF7E1;
        }
      }

      .pre-blocked-days {
        &::before {
          background: #8C8989;
        }
      }

      .blocked-days {
        &::before {
          background: #C8C5C5;
        }
      }

      .recovery-days {
        &::before {
          background: #F6E3EE;
        }
      }

      .special-occasion {
        &::before {
          background: #CEEEFC;
        }
      }
    }
  }

  .section2 {
    .section2-body {
      .notes-section {
        margin-top: 1rem;
        border: 1px solid #928F8F;
        border-radius: 10px;
        padding: 0.5rem;

        ::placeholder {
          color: #CBCBCB;
          font-size: 0.813rem;
        }

        textarea {
          width: 100%;
          height: 4rem;
          resize: none;
          box-shadow: none;
          outline: none;
          border: none;


        }
      }

      .block-days-section-0 {
        display: flex;
        flex-direction: column;
        border: 1px solid #928F8F;
        border-radius: 10px;
        padding: 0.2rem 1rem;
        margin-top: 1rem;
        height: 4rem;
        overflow-y: auto;

        .block-date-detail {
          display: flex;
          flex-direction: column;
          margin-top: 1rem;

          .block-date {
            font-weight: 600;
            font-size: 0.9rem;
          }

          .reason {
            color: #2C2C2C94;
            font-size: 0.813rem;
          }
        }
        &::-webkit-scrollbar {
          width: 8px;
          height: 3px;
          border-radius: 6px;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #A9A9A9;
            border-radius: 6px;
        }
      }

      .block-days-section {
        display: flex;
        flex-direction: column;
        border: 1px solid #928F8F;
        border-radius: 10px;
        padding: 0.2rem 1rem;
        margin-top: 1rem;
        height: 8.5rem;
        overflow-y: auto;

        .block-date-detail {
          display: flex;
          flex-direction: column;
          margin-top: 1rem;

          .block-date {
            font-weight: 600;
            font-size: 0.9rem;
          }

          .reason {
            color: #2C2C2C94;
            font-size: 0.813rem;
          }
        }
        &::-webkit-scrollbar {
          width: 8px;
          height: 3px;
          border-radius: 6px;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #A9A9A9;
            border-radius: 6px;
        }
      }
    }
  }

}

.red {
  color: red;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}