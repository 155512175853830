@import "../../assets/scss/_helpers/variables";

.dashboard {
  border: 1px solid map-get($theme-colors, "tertiary");
  border-radius: 6px;
  display: flex;
  height: calc(100vh - 170px);
  overflow: hidden;
  .nav-overlay {
    display: none;
  }
  .nav-overlay-show {
    display: flex;
    background-color: #000;
    opacity: .3;
    z-index: 1;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .d-navbar {
    border-right: 1px solid map-get($theme-colors, "tertiary");
    max-width: 220px;
    width: 100%;
    padding: 20px;
    transition: left .5s ease;
    height: 100%;
    overflow: auto;
    &.nav-show {
      @media screen and (max-width: 991px) {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fff;
      border-radius: 6px;
      width: 200px;
      height: 100%;
      z-index: 9;
      overflow-y: auto;
      }
      transition: left .5s ease;
    }
    @media screen and (max-width: 991px) {
      width: 200px;
      left: -1000px;
      position: absolute;
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 3px;
      border-radius: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #DF9C56;
        border-radius: 6px;
    }
  }
  .d-content {
    width: 85%;
    position: relative;
    padding: 30px;
    overflow-y: auto;
    @media (max-width: 991.9px) {
      padding: 15px;
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 3px;
      border-radius: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #DF9C56;
        border-radius: 6px;
    }

  }
}

