    .landowner-main {
        h3 {
            text-align: left;
            font-size: 50px;
            line-height: 80px;
            letter-spacing: 0px;
            color: #212529;
            opacity: 1;
            margin-bottom: 25px;

            @media(max-width: 991.99px) {
                text-align: left;
                font-size: 45px;
                line-height: 70px;
            }

            @media(max-width: 767.99px) {
                text-align: left;
                font-size: 35px;
                line-height: 60px;
            }

            @media(max-width: 575.99px) {
                text-align: left;
                font-size: 26px;
                line-height: 50px;
            }
        }

        p {
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 25px;

            @media(max-width: 767.99px) {
                text-align: left;
                font-size: 16px;
                line-height: 24px;
            }
        }

        button,
        a {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }

        section {
            padding: 100px 0;

            @media(max-width: 991.99px) {
                padding: 70px 0;
            }
        }

        .left {
            margin-right: 35px;
            display: inline-flex;
            height: 100%;
            align-items: center;

            @media(max-width: 991.99px) {
                margin-right: 0;
            }
        }

        .right {
            margin-left: 35px;
            display: inline-flex;
            height: 100%;
            align-items: center;

            @media(max-width: 991.99px) {
                margin-left: 0;
            }
        }

        img {
            width: 100%;
            border-radius: 12px;
        }
    }

    .about-iHunt-section {
        .mobileframe-img {
            img {
                width: 100%;
                min-height: 480px;
            }
        }
    }

    .working-iHunt-section {
        background-image: url(../../../../assets/images/how-work-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        .right {
            .right-inner {

                h3,
                p {
                    color: #fff;
                }
            }
        }
    }

    .directional-section {
        background-image: url(../../../../assets/images/directional-bg.png);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .liability-section {
        background-image: url(../../../../assets/images/liability-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        .right {
            .right-inner {

                h3,
                p {
                    color: #fff;
                }
            }
        }
    }

    .reviews-section {
        background-image: url(../../../../assets/images/review-bg.png);
        background-repeat: no-repeat;
        background-size: cover;

        .right {
            @media (max-width: 991.99px) {
                margin-left: 0;
                margin-top: 30px;
            }

            .right-inner {

                h3,
                p {
                    color: #fff;
                }
            }
        }

        .box-reviews {
            i {
                font-size: 40px;
            }
        }
    }

    .youtube-video-frame {
        background-image: url(../../../../assets/images/how-work-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;

        .frame {
            border: 20px solid #fff;

            iframe {
                height: 450px;

                @media(max-width: 991.99px) {
                    height: 350px;
                }
            }
        }
    }

    .contact-section {
        ul {
            list-style: none;
            padding-left: 0;
            margin-top: 20px;

            li {
                font-size: 40px;
                line-height: 60px;
                color: #515151;

                @media(max-width: 575.99px) {
                    text-align: left;
                    font-size: 22px;
                    line-height: 40px;
                }

                a {
                    font-size: 40px;
                    line-height: 60px;
                    color: #515151;
                    padding-left: 8px;
                    display: inline-flex;
                    text-decoration: none;

                    @media(max-width: 575.99px) {
                        text-align: left;
                        font-size: 22px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    .faq-modal {
        .modal-header {
            .btn-close {
                position: absolute;
                top: 25px;
                right: 25px;
                z-index: 90;
            }
        }

        .modal-content {
            padding: 40px
        }

        .modal-body,
        .modal-header {
            border-bottom: 0;
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .modal-body {
            h4 {
                text-decoration: underline;
            }
        }

        h4 {
            text-align: left;
            font-size: 40px;
            line-height: 35px;
            letter-spacing: 0px;
            color: #212529;
            font-weight: 400;
            opacity: 1;
            margin-bottom: 25px;
            font-family: 'Minionpro';

            @media(max-width: 991.99px) {
                text-align: left;
                font-size: 35px;
                line-height: 30px;
            }

            @media(max-width: 767.99px) {
                text-align: left;
                font-size: 30px;
                line-height: 30px;
            }

            @media(max-width: 575.99px) {
                text-align: left;
                font-size: 26px;
                line-height: 50px;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                span {
                    font-size: 20px;
                    line-height: 36px;
                    font-weight: 400;
                    padding: 10px 10px 10px 10px;
                    letter-spacing: 0px;
                    color: #515151;
                    opacity: 1;
                    text-decoration: none;
                    border-bottom: 1px solid #dddddd;
                    width: 100%;
                    display: inline-flex;

                    @media(max-width: 767.99px) {
                        text-align: left;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .content {
            display: inline-flex;
            height: 100%;
            width: 100%;
            align-items: center;

            .inner-content {
                width: 100%;
            }
        }

        img {
            width: 100%;
            min-height: 293px;
        }
        
        &::-webkit-scrollbar {
            width: 8px;
            height: 3px;
            border-radius: 6px;
            background-color: #f5f5f5;
          }
        
          &::-webkit-scrollbar-thumb {
              background-color: #A9A9A9;
              border-radius: 6px;
          }
    }