.authentication-modal {
  .modal-content {
    border-radius: 9px;
  }
    .modal-header {
        flex-direction: column-reverse;
        align-items: center;
        padding: 60px 60px 0 60px;
        border-bottom: 0;
        @media (max-width: 575.9px) {
          padding: 30px 30px 0 30px;
        }
        .btn-close {
          position: absolute;
          top: 60px;
          right: 60px;
          width: 17.7px;
          height: 17.7px;
          padding: 0;
          opacity: 1;
          @media (max-width: 575.9px) {
            top: 30px;
            right: 30px;
          }
        }
        .modal-title {
          font-size: 32px;
          line-height: 32px;
          margin-top: 0;
          color: #2C2C2C;
          padding: 0 15px;
          text-align: center;
          font-family: 'Minion Pro';
          font-weight: 400;
          @media (max-width: 575.9px) {
            font-size: 22px;
          }
        }
        p {
          font-size: 16px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 0;
          text-align: center;
          margin-top: 6px;
            a {
                font-size: 16px;
                line-height: 20px;
                color: #EA9B45;
            }
            @media (max-width: 575.9px) {
              font-size: 14px;
              list-style: 18px;
              a {
                font-size: 14px;
                list-style: 18px; 
              }
            }
        }
    }
    .modal-title {
      margin-bottom: 0.5rem;
    }
    .modal-body {
      padding: 30px 60px 60px 60px;
      @media (max-width: 575.9px) {
        padding: 10px 30px 30px 30px;
      }
      .form-box {
        margin-bottom: 15px;
        padding: 0;
        @media (max-width: 575.9px) {
          margin-bottom: 10px;
        }
        input {
          border-radius: 10px;
          height: 45px;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 400;
          width: 100% !important;
        }
    
        label {
          padding: 12px 10px;
        }
    
        .form-floating>.form-control:not(:placeholder-shown) {
          padding-top: 20px;
        }
    
        .input-icon {
          position: absolute;
          right: 9px;
          top: 7px;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
}