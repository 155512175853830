@import "assets/scss/_helpers/variables";

.trophies-dashboard {
  .trophy-box {
    border-radius: 12px;
    .trophies-img {
      position: relative;
      margin-right: 1rem;
      img {
        width: 50px;
        height: 50px;
        border-radius: 30px;
        object-fit: cover;
        border: 2px solid map-get($theme-colors, "primary");
        z-index: 3;
        position: relative;
      }
      .singh {
        max-width: 45px;
        position: absolute;
        left: 3px;
        top: -28px;
        z-index: 1;
      }
    }
    .trophies-bottom {
      padding: 21px 37px;
      text-align: end;
      .btn {
        font-size: 12px;
        padding: 6px 10px;
        background-color: #e9e9e9;
      }
    }
  }
}

.item {
  width: 100%;
  padding: 15px 0px;
  // background-color: gray;
  font-size: 15pt;
  text-align: center;
  margin-bottom: 4px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
