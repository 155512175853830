.phone__container {
  background: white !important;
  border: white 2px solid !important;
  margin-bottom: 4% !important;
}

.input__container {
  background: white !important;
  border: solid 1px lightgray !important;
  height: 43px !important;
  width: 100% !important;
  // margin-bottom: 2% !important;
}

// .date__picker {
//   width: 100%;
// }

.react__calendar__hide {
  display: none;
}
