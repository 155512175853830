* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  a {
    transition: .3s all ease-in-out !important;
  }

  a:hover {
    opacity: 0.8 !important;
  }

  td,
  input,
  textarea,
  select {
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  }

  p {
    line-height: 1.5;
  }

  .inner-map {
    height: 461px !important;
  } 

  @media print {
    .page-break {
      page-break-after: always !important;
      clear: both !important; 
    }
    .full-height {
      margin-top: 32px;
      min-height: 100vh;
      position: relative;
      padding-bottom: 170px;
      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .gmnoprint , .gm-fullscreen-control {
    display: none!important;
  }

  .fw-700 {
    font-weight: 700
  }
.dfp-mec {
    font-size: 14px; 
    margin-bottom: 6px;
}

.bd {
    text-align: center; 
    margin-right: 10px;
}

body .header .logo .big-logo {
  top: -25px!important;
} 
  
  @media screen and (max-width: 991px) {
    //  .header {
    //   background-size: 100% 100% !important;
    //   padding-left: 15px !important;
    //   text-align: center !important;
    //   padding-bottom: 36px !important;
    // }

    //  .header .logo {
    //   margin: auto !important;
    // }

    //  .header .logo .big-logo {
    //   max-width: 240px !important;
    // }
    body .header {
      background-size: 100% 100%!important;
      padding-left: 0px!important;
      text-align: center!important;
      padding-bottom: 40px!important;
      padding-top: 15px!important;
    }
    body .header .logo {
      width: 209px!important;
    }
    body .header .logo .big-logo {
      max-width: 180px!important;
      top: -15px !important;
    }
    body .header .logo .mini-logo {
      max-width: 52px!important;
    }
  }

  @media screen and (max-width: 767px) {
    .map {
      width: 100%!important;
    }
     table .stack-on-767 td {
      display: block !important;
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0!important;
    }
     table .stack-on-767 td:not(:last-child) {
      margin-bottom: 15px!important;
    }
     table .stack-on-767 td .inner-map  {
        height: 300px!important;
    }
     table .stack-on-767 td ul {
      padding-left: 0 !important;
    }

     table .stack-on-767 td.text-left-767 {
      text-align: left !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

     table .stack-on-767 td:last-child {
      // border-top: 1px solid #2d2d2d !important;
      // border-left: 0 !important;
      // text-align: left !important;
    }
  }

  table {

    border-collapse: unset !important;
  }

  .shape {
    font-family: sans-serif;
    text-align: center;
    height: 460px;
  }
  
  .shape-map {
    height: 100%;
    width: 100%;
  }

  .specie-circle {
    border: 1px solid #CBCBCB91;
    opacity: 1;
    border-radius: 50%;
    width: 45px !important;
    height: 45px !important;
    background-color: #ffff;
    img {
      margin-top: 10px;
    }
  }