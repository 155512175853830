.tickets-section {
  .ticket-section-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .new-ticket-btn {
      //  box-shadow: 0px 10px 20px #219ebc1a;
      border-radius: 10px;

      &:hover {
        color: white !important;
      }
      // &:active{
      //   background-color: #EA9B45;
      //   color:white;
      //   border:none;
      // }
      // &:focus{
      //   background-color: #EA9B45;
      //   color:white;
      //   border:none;
      // }
      .addTicketImg {
        margin-right: 0.8rem;
      }
    }
  }
  .ticket-section1 {
    display: flex;
    //justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .slick-arrow {
      border: 1px solid #cbcbcb;
      border-radius: 100%;
      height: 25px;
      width: 25px;
      background-color: #fff;
      display: inline-block;
      text-align: center;
      line-height: 25px;
      color: #cbcbcb;
      background: transparent;
      z-index: 8;
      top: 15px;
      position: absolute;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      &:hover {
        background-color: #ea9b45;
        color: white;
        border: 1px solid white;
      }
    }
    .slick-list {
      margin: 0 2rem 0rem 2rem;
    }
    .slick-arrow.arrow-left {
      // left: -10px;
      left: 0;
      top: 6px;
    }
    .slick-arrow.arrow-right {
      right: 0;
      top: 6px;
      // @media screen and (max-width: 767px) {
      //   right: -10px;
      // }
    }
    .slick-slider {
      width: 100%;
    }
    .ticket-section-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      list-style-type: none;
      flex-wrap: wrap;
      // width: 96%;
      width: calc(100% - 40px);
      padding: 0rem;
      @media only screen and (max-width: 767px) {
        justify-content: space-evenly;
        margin-bottom: 0;
        width: calc(100% - 60px);
      }

      li {
        width: auto;
        display: inline-flex !important;
        justify-content: center;
        .btn {
          border: 1px solid rgba(0, 0, 0, 0.63);
          font-size: 0.813rem;
          width: 175px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media only screen and (max-width: 1399.9px) {
            width: 160px;
          }
          @media only screen and (max-width: 1199.9px) {
            width: 140px;
          }
          @media only screen and (max-width: 767px) {
            width: 145px;
          }
          @media only screen and (max-width: 512px) {
            width: 140px;
          }
          @media only screen and (max-width: 425px) {
            width: 100px;
          }
          @media only screen and (max-width: 400px) {
            width: 90%;
          }
          &:hover {
            border: 1px solid #ea9b45;
          }
        }
        .active-btn {
          background-color: #fbecdc;
          border: 1px solid #ea9b45;
        }
      }
    }
    .ticket-section-filter {
      display: flex;
      justify-content: flex-end;
      width: 40px;
      @media (max-width: 767.8px) {
        width: 60px;
        margin-bottom: 0.5rem;
      }
    }
    .dropdown {
      button {
        background: white;
        border: none;
        padding: 0rem;
        margin-top: -1.5rem;
        // margin-right: 1rem;
        @media screen and (max-width:767px) {
          margin-top: 0rem;
        }
        @media screen and (max-width:768px) {
          margin-right: 0rem;
        }
      }
      label {
        color: black;
      }
      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
      .dropdown-menu.show {
        border: 1px solid #707070;
        border-radius: 5px;
      }
      .dropdown-menu {
        min-width: auto !important;
      }
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0px;
        top: 4px;
        width: 12px;
        height: 12px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 12px;
        height: 12px;
        background: #ea9b45;
        position: absolute;
        top: 4px;
        left: 0px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  .ticket-section2 {
    .tickets-status-lists {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      flex-wrap: wrap;
      padding: 0rem;
      li {
        background: #f9f9f9 0% 0% no-repeat padding-box;
        border-radius: 19px;
        padding: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
        &:hover {
          outline: 1px solid #ea9b45;
        }
        .ticket-status-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 0.3rem;
          font-size: 0.85rem;
          .ticket-number {
            color: #2c2c2c;
          }
          .ticket-status-resolved {
            color: #DF9C56;
            text-transform: capitalize;
            &::before {
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              -moz-border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              border-radius: 7.5px;
              margin-right: 0.5rem;
              background-color: #DF9C56;
            }
          }
          .ticket-status-pending {
            color: #E9BF45;
            text-transform: capitalize;
            &::before {
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              -moz-border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              border-radius: 7.5px;
              margin-right: 0.5rem;
              background-color: #E9BF45;
            }
          }
          .ticket-status-open {
            color: #5A8DEC;
            text-transform: capitalize;
            &::before {
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              -moz-border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              border-radius: 7.5px;
              margin-right: 0.5rem;
              background-color: #5A8DEC;
            }
          }
        }
        .ticket-title {
          font-weight: 600;
          font-size: 1rem;
          word-break: break-all;
        }
        .ticket-status-body {
          color: #928f8f;
          font-size: 0.85rem;
          word-break: break-all;
        }
        .ticket-status-detail {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          font-size: 0.85rem;
          margin-top: 1rem;
          .ticket-status-date {
            margin-right: 0.8rem;
          }
          .commentImg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

.item {
  width: 100%;
  padding: 15px 0px;
  // background-color: gray;
  font-size: 15pt;
  text-align: center;
  margin-bottom: 4px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
