@import "../../../../assets/scss/_helpers/variables";

.create-password-content{
    div{
        color: map-get($theme-colors, "tertiary");
    }
}
form{
    .input-icon.eye-icon {
        position: absolute !important;
        right: 10px !important;
        top: 10px !important;;
        cursor: pointer !important;
    }
}