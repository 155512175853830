@import "/src/assets/scss/_helpers/variables";
@import "/src/assets/scss/_helpers/buttons";
@import "/src/assets/scss/_helpers/form";
@import "./assets/scss/_base/general.scss";

@import "~bootstrap/scss/bootstrap";

.react-tel-input .form-control.invalid-number {
  background: #ffffff !important;
  border: 1px solid #928f8f !important;
  border-radius: 0.5rem;
  width: 100%;
}

.react-tel-input .flag-dropdown.invalid-number {
  border: 1px solid #928f8f !important;
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.junior-modal {
  .modal-content {
    padding: 50px;

    @media (max-width: 465.99px) {
      padding: 20px;
    }

    @media (max-width: 410.99px) {
      padding: 0;
    }

    .modal-header {
      border-bottom: 0;
      padding: 0;

      .btn-close {
        position: absolute;
        right: 60px;
        top: 60px;
        z-index: 2;
      }
    }

    .junior-img {
      width: 150px;

      @media (max-width: 991.9px) {
        text-align: center;
        width: 100%;
      }

      img {
        width: 150px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    h3 {
      font-size: 40px;
      font-weight: 400;

      @media (max-width: 991.9px) {
        text-align: center;
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;

      @media (max-width: 991.9px) {
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .list-junior-hunter {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 20px #0000001A;
      border: 1px solid #EA9B45;
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      &.black {
        border: 1px solid #2A2A2A;

        .img {
          svg {
            path {
              fill: #2A2A2A;
            }
          }
        }
      }

      &.green {
        border: 1px solid #1EC42F;

        .img {
          svg {
            path {
              fill: #1EC42F;
            }
          }
        }

        .list {
          ul {
            padding-left: 25px;
            padding-right: 25px;

            li {
              text-align: center;
              padding-left: 0;

              &::before {
                display: none;
              }
            }
          }
        }
      }

      .img {
        position: absolute;
        z-index: 1;
        width: 100%;
        left: 0;
        top: -2px;
        border-radius: 10px;

        svg {
          width: 100%;
        }
      }

      .list {
        z-index: 2;
        position: relative;

        ul {
          list-style: none;
          margin: 15px 0;
          padding-left: 25px;

          li {
            position: relative;
            padding-left: 20px;
            // margin-bottom: 5px;
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;

            &::before {
              content: "•";
              color: #EA9B45;
              font-size: 35px;
              line-height: 10px;
              position: absolute;
              left: 0;
              top: 10px;
            }
          }
        }

        h6 {
          height: 41px;
          margin-bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }
      }
    }
  }
}

body > iframe[style*='2147483647']{
  display: none;
}

body {
  &::-webkit-scrollbar {
    width: 8px;
    height: 3px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #A9A9A9;
      border-radius: 6px;
  }
}