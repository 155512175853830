.new-tags-v2 {
  .inner-tags {
    .tags-item {
      background-color: #F2F2F2;
      border-radius: 14px;
      padding: 3px 10px !important;
      color: #515151;
      width: fit-content;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      min-width: auto !important;

      &.hover:hover,
      &:focus,
      &.active {
        color: #fff;
        background-color: #EA9B45;
        opacity: 1;
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      i {
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
}
.red-border {
  border: 1px solid red !important;
}