@import "../../../../../assets/scss/_helpers/variables";

.new-ticket-div {
  .modal-lg {
    @media (min-width: 992px) {
      max-width: 590px;
    }
  }

  .modal-content {
    padding: 2.5rem 2.8rem;
    border: 1px solid #707070;
    border-radius: 9px;

    .modal-header {
      padding: 0rem;
      justify-content: center;
      border-bottom: unset !important;

      .modal-title.h4 {
        font-weight: 400;
        font: normal normal normal 22px/16px Minion Pro;
      }
    }

    .modal-body {
      padding: 0rem !important;

      .newTicket-form-row {
        .form-container {
          padding-bottom: 0rem;
          min-height: auto;
        }

        .form-box {
          border: none;
          max-width: 100% !important;
          padding: 0rem !important;
          text-align: left;
        }

        .categorySelect {
          text-align: left;
          margin-bottom: 14px;

          .select__control--menu-is-open {
            .select__value-container {
              align-items: baseline;
            }

            .css-1rhbuit-multiValue {
              line-height: 16px;
            }
          }

          label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          }

          .css-319lph-ValueContainer {
            padding: 0px 8px;
          }

          .select__value-container {
            max-height: 45px;
            overflow-x: auto;
            flex-wrap: nowrap;
            display: inline-flex;
            align-items: baseline;

            &::-webkit-scrollbar {
              width: 2px;
              height: 3px;
              background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #ea9b45;
            }

            &::-webkit-scrollbar-track {
              // -webkit-box-shadow: inset 0 0 1px rgb(0 0 0 / 30%);
              background-color: #f5f5f5;
            }
          }

          .css-1rhbuit-multiValue {
            min-width: fit-content;
            line-height: 16px;
          }

          .select__control.css-1s2u09g-control {
            border-radius: 10px;
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
          }

          .css-1okebmr-indicatorSeparator {
            background-color: transparent;
          }

          .css-1pahdxg-control {
            border-radius: 10px !important;
            box-shadow: none;
            border-color: map-get($theme-colors, "tertiary");

            &:hover {
              border-color: map-get($theme-colors, "tertiary");
            }
          }
        }

        .categorySelect .select__value-container {
          margin-top: 5px;
        }

        .css-1okebmr-indicatorSeparator {
          background-color: transparent !important;
        }

        .description-textarea {
          border-radius: 10px;
          height: 14rem;
          margin-bottom: 12px;
          margin-top: 12px;
          border: 1px solid #ced4da;

          label {
            background: #fff;
            height: 20px;
            margin-top: 1px;
            margin-left: 6px;
            font-size: 14px;
            padding: 0px;
          }

          .form-control {
            border: none !important;
          }

          textarea {
            resize: none;
            height: 13rem;
            width: 98%;
            padding: 2rem 0.6rem;
            border: none;
            font-size: 14px;
            border-radius: 10px;
          }
        }

        .video-attachment {
          border: 1px dashed #2a0909;
          border-radius: 10px;
          padding: 1rem;
          position: relative;
          margin-bottom: 1rem;

          .form-box {
            text-align: left;
          }

          .file-input {
            display: none;
          }

          .file-selected {
            border: 1px solid #ea9b45;
            height: 2rem;
            margin-left: 2rem;
            padding-left: 1rem;
            padding-top: 0.1rem;
            width: 32%;
            display: block;
            border-radius: 0rem;
            cursor: pointer;

            &::after {
              content: "";
              width: 30px;
              height: 14px;
              position: relative;
              display: inline-block;
              /* border-left: hidden; */
              left: 14%;
              top: 23%;
              cursor: pointer;
              background: url("../../../../../assets/images/crossIcon.svg") 0 0 no-repeat;

              display: inline-block;
            }
          }

          input {
            background-color: transparent;
            margin-left: 2rem;
            padding-top: 1rem;
          }

          ::-webkit-file-upload-button {
            display: none;
          }

          label {
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 2px;

            img {
              width: 25px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}
.new-ticket-div .modal-content .modal-body .newTicket-form-row .description-textarea.custom-description .form-group .field-style {
  width: 100% !important;
  height: 100%;
}
.new-ticket-div .modal-content .modal-body .newTicket-form-row .description-textarea.custom-description .form-group {
  height: 100%;
}

.new-ticket-div .modal-content .modal-body .newTicket-form-row .description-textarea.custom-description label {
    font-size: 12px !important;
    padding: 4px 7px !important;
    transform: scale(0.97) translateY(0) translateX(0);
    margin-left: 0 !important;

}

.file-attachment {
  display: flex;
  border: 1px solid #928f8f;
  height: 60px;
  border-radius: 8px;
  margin-bottom: 10px;

  button {
    background-color: unset;
    border: none;
  }

  .custom-file-label {
    padding: 3px !important;
    display: flex;
    align-items: center;
    background-color: #ececec;
    color: #000;
    //height: 45px;
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
    max-width: 150px;
    min-width: 125px;

    span {
      color: #000 !important;
      padding-left: 5px;
      font-size: 12px;
    }

    img {
      padding-left: 12px;
      width: 33px;
    }
  }

  .custom-file-input {
    padding: 18px !important;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 0px 8px 8px 0px !important;

  }

  .no-select-file {
    color: #a7a7a7;
  }

  ::-webkit-file-upload-button {
    display: none;
  }

  .Delete-file {
    padding: 10px;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 40px;
    }
  }
}