@import "../../../../assets/scss/_helpers/variables";
.border-btm {
  border-bottom: 2px solid map-get($theme-colors, "sixth");
}
.location {
  display: flex;
}
.tab {
  .nav-tabs {
    justify-content: space-between;
    border-bottom: 1px solid #cecece;
    .nav-item {
      // border-bottom: 1px solid #cecece;
      // padding: 0 20px;
      &:not(:last-child) {
        margin-right: 12px;
      }
      .nav-link {
        color: map-get($theme-colors, "secondary");
        padding-right: 0;
        padding-left: 0;
        border: 0px !important;
        font-family: Minionpro;
        font-size: 26px;
        @media (max-width: 640px) {
          font-size: 16px;
        }
        &:hover {
          border: none;
          color: map-get($theme-colors, "secondary");
        }
        &:focus {
          border: none;
        }
        &.active {
          border: 0;
          position: relative;
          background-color: unset;
          border-bottom: 3px solid map-get($theme-colors, "primary") !important;
          margin-bottom: -2px;
          color: map-get($theme-colors, "primary");

          // &::after {
          //   content: "";
          //   position: absolute;
          //   height: 3px;
          //   width: 96px;
          //   background-color: map-get($theme-colors, "primary");
          //   bottom: -4px;
          //   left: 50%;
          //   transform: translate(-50%);
          // }
        }
      }
    }
  }
}
.elevation-graph {
  padding-top: 280px;
}
.elevation-graph h4 {
  font-size: 22px;
  font-family: "Roboto";
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .elevation-graph {
    padding-top: 30px;
  }
}

.blog-request-dialog {
  .modal-dialog {
    max-width: 860px;
  }

  .modal-content {
    .modal-header {
      padding: 30px 30px 16px;
      border: 0;

      @media screen and (max-width: 991px) {
        padding: 22px 22px 16px;
      }
    }

    .modal-body {
      padding-inline: 44px;
      padding-bottom: 44px;

      @media screen and (max-width: 991px) {
        padding-inline: 22px;
        padding-bottom: 22px;
      }

      .blog-topbar {
        display: flex;
        align-items: center;
        margin-bottom: 27px;


        .date {
          border: 1px solid #928F8F;
          width: 76px;
          height: 76px;
          padding: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          flex: 0 0 76px;
          color: #2C2C2C;
          font-size: 12px;
          opacity: 1;
          vertical-align: middle;
          margin-right: 20px;
          text-align: center;
          background-color: #ffffff;

          @media screen and (max-width: 991px) {
            margin-right: 12px;
          }

          label {
            font-size: 25px;
            font-weight: 600;
            color: #2C2C2C;
            margin-bottom: 5px;
            padding: 0;
          }
        }

        .blog-title {
          font-size: 35px;
          margin: 0;

          @media screen and (max-width: 1200px) {
            font-size: 28px;
          }

          @media screen and (max-width: 991px) {
            font-size: 25px;
          }

          @media screen and (max-width: 575px) {
            font-size: 23px;
          }
        }

        .blog-status {
          margin-top: 2px;
          font-size: 14px;
          position: relative;
          padding-inline-start: 10px;
          text-transform: capitalize;

          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: currentColor;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }

          &.pending {
            color: #FFBE00;
          }

          &.approved {
            color: #EA9B45;
          }

          &.rejected {
            color: #D60000;
          }
        }

      }

      .blog-media-slider-wrapper {
        background: #cbcbcb;
        border-radius: 8px;

        .swiper-container {
          height: 333px;

          .swiper-wrapper {
            align-items: center;
          }
        }

        .swiper-slide {
          text-align: center;

          img {
            object-fit: contain;
            border-radius: unset;
            height: inherit;
            width: auto;
            max-width: 100%;
          }
        }

        .swiper-pagination {
          display: none;
        }
      }

      .blog-desc {
        p {
          font-size: 20px;
          line-height: 1.2;
          color: #2C2C2C;
          margin-block: 25px 37px;
  
          @media screen and (max-width: 991px) {
            font-size: 16px;
          }
        }
        .read-more {
          margin-left: 5px;
          cursor: pointer;
          font: 16px Roboto, Sans-serif;
          color: #ea9b45;

          @media screen and (max-width: 991px) {
            font-size: 12px;
          }
        }
      }

      .blog-request-actions {
        display: flex;
        gap: 10px;

        .blog-request-btn {
          font-size: 14px;
          padding: 12px 40px;
          border-radius: 10px;
          border: 1px solid transparent;

          @media screen and (max-width: 991px) {
            padding: 8px 30px;
          }

          &.approve {
            color: #FFFFFF;
            background-color: #EA9B45;
            border-color: #EA9B45;
          }

          &.reject {
            border-color: #515151;
            color: #2C2C2C;
            background-color: #FFFFFF;
          }
        }
      }

      .deleted-blog-body {
        padding: 0rem 5rem 0rem 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 768px) {
          padding: 0rem 1.5rem 1rem;
        }
        .deleted-blog-title {
          color: #2c2c2c;
          font-size: 28px;
          font-weight: 600;
          margin: 0;
          margin-top: 0.5rem;
        }
        .deleted-blog-body {
          color: #515151;
          font-size: 1rem;
          text-align: center;
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 3px;
        border-radius: 6px;
        background-color: #f5f5f5;
      }
    
      &::-webkit-scrollbar-thumb {
          background-color: #A9A9A9;
          border-radius: 6px;
      }
    }
  }
}