@import "../../../../assets/scss/_helpers/variables";

.chat {
  .chat-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid map-get($theme-colors, "fifth");
    padding: 10px 15px;

    img {
      height: 45px;
      width: 45px;
      border-radius: 50%;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .Chat-footer {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    background-color: map-get($theme-colors, "fifth");
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .input {
      width: 100%;

      textarea {
        width: 100%;
        height: 42px;
        background-color: #f9f9f9;
        border: none;
        border-radius: 4px;
        padding: 5px 10px;
        font-weight: 400;
      }
    }

    .send-box {
      width: 37px;
      text-align: center;
      padding-left: 10px;
      img {
        width: 26px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .chat-messages {
    scroll-behavior: smooth;
    height: 300px;
    padding: 10px 15px;
    background-color: map-get($theme-colors, "border");
    background-image: url("../../../../assets/icons/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 300px 200px;

    @media (max-width: 550px) {
      background-size: 200px 200px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
    }

    span {
      font-size: 12px;
    }

    .sent {
      color: #fff;
      text-align: right;
      margin-bottom: 20px;

      div {
        background-color: map-get($theme-colors, "primary");
        display: inline-block;
        margin-left: auto;
        padding: 10px;
        border-radius: 16px 16px 0 16px;
      }
    }

    .recieved {
      display: flex;
      align-items: flex-start;
      margin-bottom: 7px;

      img {
        width: 25px;
        margin-right: 10px;
        height: 25px;
        object-fit: cover;
        border-radius: 100%;
      }

      .bg-white {
        padding: 10px;
        border-radius: 16px 16px 16px 0;
      }

      span {
        color: #adb5bd;
      }

      P {
        color: #0f1828;
      }
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 3px;
      border-radius: 6px;
      background-color: #f5f5f5;
    }
  
    &::-webkit-scrollbar-thumb {
        background-color: #A9A9A9;
        border-radius: 6px;
    }
  }

  .card-stack {
    white-space: pre-wrap !important;
  }

  .strona {
    font-size: 14px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}