@import "../../../../assets/scss/_helpers/variables";

.current-weather {
  border: 1px solid map-get($theme-colors, "fifth");
  border-radius: 7px;
  padding: 16px 30px;
  .current-temp {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;

    .deg {
      font: normal normal bold 32px/30px Roboto;
    }
    img {
      width: 80px;
    }
    .bi {
        color: #E9BF45;
    }
  }
  .stand-list {
    display: grid;
    grid-template-columns: auto auto;
    border-radius: 7px;
    justify-content: space-between;
    @media (max-width:767px) {
      grid-template-columns: auto;
      justify-content: unset;
    }
    .border-btm {
      border-top: 1px solid map-get($theme-colors, "fifth");
      @media (max-width:767px) {
       border: none;
      }
    }
    div {
      min-width: 280px;
      display: flex;
      justify-content: space-between;
      @media (max-width:1399px) {
        min-width: 250px;
      }
      @media (max-width:1199px) {
        min-width: 220px;
      }
      @media (max-width:991px) {
        min-width: 260px;
      }
      @media (max-width:767px) {
        min-width: 180px;
      }
      @media (max-width:767px) {
        min-width: unset;
        justify-content: space-between;
      }
      // @media (max-width:420px) {
      //   min-width: 120px;
      // }
    }
    p {
      // margin-bottom: 4px;
      font: normal normal normal 14px/17px Roboto;
      &.fw-bold {
        // margin-bottom: 15px;
        // margin-top: 5px;
        font: normal normal bold 14px/15px Roboto;
      }
      // @media (max-width:550px) {
      //   margin-bottom: unset;
      // }
    }
  }
  .moon-phase {
    img {
      height: 25px;
      width: 25px;
    }
  }
}
