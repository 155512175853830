.faq-container {
  h4 {
    color: #2c2c2c;
    font: normal normal 600 22px/27px Roboto;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  p {
    color: #515151;
    font: normal normal normal 18px/27px Roboto;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  ul {
    li {
      color: #928f8f;
      font: normal normal normal 18px/27px Roboto;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      &::marker {
        color: #2c2c2c;
      }
    }
  }
}
