.assign-to-group-modal {
  .modal-content {
    border-radius: 9px;
  }

  .modal-header {
    align-items: center;
    padding: 60px 60px 0 60px;
    border-bottom: 0;
    margin-bottom: 4px;

    @media (max-width: 575.9px) {
      padding: 30px 30px 0 30px;
    }

    .btn-close {
      width: 17.7px;
      height: 17.7px;
      opacity: 1;

      @media (max-width: 575.9px) {
        top: 30px;
        right: 30px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 18px;
      margin: 0;
      color: #2C2C2C;
      font-family: 'Minion Pro';
      font-weight: 500;

      @media (max-width: 575.9px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0;
      text-align: center;
      margin-top: 6px;

      a {
        font-size: 16px;
        line-height: 20px;
        color: #EA9B45;
      }

      @media (max-width: 575.9px) {
        font-size: 14px;
        list-style: 18px;

        a {
          font-size: 14px;
          list-style: 18px;
        }
      }
    }
  }

  .modal-body {
    padding: 20px 60px 60px 60px;

    @media (max-width: 575.9px) {
      padding: 20px 30px 30px 30px;
    }

    .tags{
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 5px;  
      padding-block: 2px;
      max-height: 55vh;
      overflow-y: auto;
  
      &::-webkit-scrollbar {
        width: 6px;
        height: 3px;
        border-radius: 6px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
          background-color: #DF9C56;
          border-radius: 6px;
      }
      .tag{
        border: 1px solid #CBCBCB91;
        border-radius: 8px;
        padding: 7px 20px;
        color: #2C2C2C;
        background: transparent;
        line-height: 1;

        &.selected{
          background-color: #FFEAD4;
          border-color: #EA9B45;
        }
        
      }
    }
    
    .save-btn {
      background-color: #EA9B45;
      border-radius: 10px;
      border: 0;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 1.3;
      padding-block: 15px;
      width: 100%;
      max-width: 146px;
      margin-top: 15px;

      &:hover,
      &:active,
      &:focus {
        opacity: .95;
      }
      &:disabled {
        opacity: .65;
      }
      @media (max-width: 575.9px) {
        margin-top: 16px;
        max-width:none;
        padding-block: 12px;
      }
    }

  }
}