@import "assets/scss/_helpers/variables";

.tbl-img-user {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 30px;
}
.full-name-link {
  .full-name {
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #ea9b45;
    color: black;
    display: inline;
  }
}

.chat-tab:hover {
  cursor: pointer;
}
.chat-msg-icon {
  background-color: map-get($theme-colors, "border");
  width: 32px;
  height: 32px;
  background-color: #f2f2f2;
  border-radius: 30px;
  padding: 8px;
  overflow: visible;
  margin-right: 6px;
}
.stand-details-table {
  &.table-left-align {
    .table {
      tr {
        th,
        td {
          text-align: left;
        }
      }
    }
  }
  .table {
    > :not(:first-child) {
      border-top: 1px solid currentColor;
    }
    > :not(caption) > * > * {
      vertical-align: middle;
      padding: 1rem 0.5rem;
      @media (max-width: 767px) {
        width: 100%;
        display: block;
      }
    }
    tr {
      th {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
      td {
        font-size: 14px;
        text-align: center;
        @media (max-width: 767px) {
          border-bottom: none;
          &:last-child {
            border-bottom: 1px;
            border-style: solid;
            border-color: inherit;
          }
        }
      }
      &:last-child {
        td {
          border-bottom: 0px;
        }
      }
    }
    .text-fifth {
      &:first-child {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
