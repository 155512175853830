.partitioned {
  padding-left: 15px;
  letter-spacing: 39px;
  border: 0;
  background-image: linear-gradient(to left,
      #707070 70%,
      rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
  background-color: transparent;
  font-weight: bold;
}

.verify-input {
  left: 0;
  position: sticky;
}

.verify-code-box {
  width: 190px;
  overflow: hidden;
  margin: auto;
}

.form-box {
  padding: 80px 20px;
  text-align: center;

  &>span {
    line-height: 5;

    @media (max-width: 320px) {

      font-size: 14px;
    }
  }
}