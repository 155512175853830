@import "assets/scss/_helpers/variables";


.dropdown-menu {
    min-width: fit-content;
    text-align: center;
}

.tel {
    display: flex;
    align-items: center;
    justify-content: center;

    i,
    span {
        background: #f2f2f2;
        color: #EA9B45;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        img {
            height: 20px;
            width: 20px;
        }
    }

    span {
        margin-right: 7px;

        &.cursor-pointer {
            margin-right: 0;
        }
    }
}
