.countdown {
    margin-top: 10px;

    .icon {
        margin-right: 5px;
    }

    color: #515151;
    font-size: 14pt;
}

.disable-btn {
    background: #F2F2F2 !important;
    border-color: #F2F2F2 !important;
}