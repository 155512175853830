    .hunter-page-main {
        h3 {
            text-align: left;
            font-size: 50px;
            line-height: 80px;
            letter-spacing: 0px;
            color: #212529;
            opacity: 1;
            margin-bottom: 25px;
        }
        @media(max-width: 1399.9px) {
            h3 {
                text-align: left;
                font-size: 36px;
                line-height: 44px;
            }
        }
    
        @media(max-width: 991.9px) {
            h3 {
                text-align: left;
                font-size: 34px;
                line-height: 42px;
            }
        }
    
        @media(max-width: 767.9px) {
            h3 {
                text-align: left;
                font-size: 32px;
                line-height: 40px;
            }
        }
    
        @media(max-width: 575.9px) {
            h3 {
                text-align: left;
                font-size: 26px;
                line-height: 30px;
            }
        }

        h5 {
            text-align: left;
            font-size: 25px;
            line-height: 28px;
            letter-spacing: 0px;
            color: #212529;
            opacity: 1;
            margin-bottom: 25px;
        }

        p {
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 25px;

            @media(max-width: 767.9px) {
                text-align: left;
                font-size: 16px;
                line-height: 24px;
            }
        }

        button,
        a {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }

        section {
            padding: 100px 0;

            @media(max-width: 991.9px) {
                padding: 70px 0;
            }
        }

        .left {
            margin-right: 35px;
            display: inline-flex;
            height: 100%;
            align-items: center;

            @media(max-width: 991.9px) {
                margin-right: 0;
            }
        }

        .right {
            margin-left: 35px;
            display: inline-flex;
            height: 100%;
            align-items: center;

            @media(max-width: 991.9px) {
                margin-left: 0;
            }
        }

        img {
            width: 100%;
        }
        .rounded-5 {
            border-radius: 25px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
        }
    }
.hunt-for-hunter-bg {
    background-image: url(../../../../assets/images/hunt-for-hunter-bg.png);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
}
section {
    .hunter-experience {
        background-color: rgb(237 174 91 / 10%);
        height: 300px;
        padding: 0;
        margin: 125px 0;
        width: 100%;
        position: relative;
        @media (max-width: 991.9px) { 
            height: auto;
            margin: 0;
        }
        &::before {
            content: "";
            width: 60%;
            height: 11px;
            background: #EDAE5B 0% 0% no-repeat padding-box;
            opacity: 1;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }
        &::after {
            content: "";
            width: 60%;
            height: 11px;
            background: #EDAE5B 0% 0% no-repeat padding-box;
            opacity: 1;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
        .left {
            .left-inner {
                height: 300px;
                position: relative;
                @media (max-width: 991.9px) { 
                    height: auto;
                    padding-bottom: 30px;
                }
                img {
                    height: 500px;
                    width: 100%;
                    margin-top: -100px;
                    @media (max-width: 1399.9px) {
                        height: 400px;
                        margin-top: -50px;
                    }
                    @media (max-width: 1199.9px) {
                        height: 330px;
                        max-width: 435px;
                        margin-top: -15px;
                    }
                    @media (max-width: 475.9px) {
                        width: 100%;
                        height: auto;
                        margin-top: 70px;
                    }
                }
            }
        }
        .right {
            @media (max-width: 991.9px) { 
                // justify-content: flex-end;
                // display: inline-flex;
            }
            p {
                margin-bottom: 0;
                // display: -webkit-box;
                // max-width: 200px;
                // -webkit-line-clamp: 7;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                @media (max-width: 991.9px) { 
                    padding-bottom: 50px;
                }
            }
        }
    }
}
.iframe-block-main { 
    padding: 20px 0 0 0 !important;    
    margin-bottom: 100px;  
    overflow: hidden; 
}
.iframe {
    // border-radius: 50px;
    
    @media (max-width: 1400px){
        max-width: 579px;
        height: 326px;
    }
    @media (max-width: 1200px){
        max-width: 491px;
        height: 280px;
    }  
    @media (max-width: 991.9px) {
        max-width: 530px;
        height: 300px;
        margin-left: 10%;
        margin-right: 10%;
    }
    @media (max-width: 767.9px) {
        max-width: 366px;
        height: 210px;
    }
    @media (max-width:575.9px){
        max-width: 376px;
        height: 213px;
    }
    @media (max-width:500px) {
        max-width: 333px;
        height: 189px;
    } 
    @media (max-width:455px) {
        max-width: 244px;
        height: 140px;
    }
   
    iframe {
        // border-radius: 50px;
        border: 3px solid #FF0000;
        max-width: 857px;
        height: 485px;
        @media (max-width: 1400px){
            max-width: 575px;
            height: 326px;
        }
        @media (max-width: 1200px){
            height: 280px;
        }      
        @media (max-width: 991.9px) {
            max-width: 528px;
            height: 300px;
        }
        @media (max-width: 767.9px) {
            max-width: 366px;
            height: 210px;
        }
        @media (max-width:575.9px){
            max-width: 374px;
            height: 213px;      
        }
        @media (max-width:500px) {
            max-width: 330px;
            height: 189px;
        } 
        @media (max-width:455px) {
            max-width: 244px;
            height: 140px;
        }        
       
    }
}
.account-detail-guide {
    background-image: url(../../../../assets/images/direction-right-bg.png);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #E5E5E5;
    @media (max-width: 991.9px) {
        background-size: cover;
    }
    &.bg-2 {
        background-image: url(../../../../assets/images/choice-hunting-site-bg.png);
        @media (max-width: 767.9px) {
            .left-inner {

                h5,p {
                    color: #fff;
                }
            }
        }
    }
    &.bg-3 {
        background-image: url(../../../../assets/images/parking-info-img.png);
    }
    &.bg-4 {
        background-image: url(../../../../assets/images/enjoy-hunt-bg.png);
        @media (max-width: 767.9px) {
            .left-inner {
                background-color: #000000a1;
                padding: 25px 25px 0 25px;
                margin-bottom: 25px;
                h5,p {
                    color: #fff;
                }
            }
        }
    }
    &.bg-5 {
        background-image: url(../../../../assets/images/enjoy-download-bg.png);
        background-color: transparent;
        background-position: right bottom;
    }
}

.iframe-block-inner{
    background-image: url(../../../../assets/images/youtube-bar.png);
    object-fit: scale-down;
    object-position: center;
    background-position: center center;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    text-align: center;
}