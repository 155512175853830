.faq-wrapper {
  border: 1px solid #cbcbcb;
  border-radius: 16px;
  max-width: 1010px;
  margin: 75px auto;
  @media (max-width: 767px) {
    border-radius: 8px;
    margin: 50px auto;
  }
  .faq-container {
    border-bottom: 1px solid #cbcbcb;
    &:last-child {
      border-bottom: none;
    }
    .faq-item {
      border-bottom: 1px solid #cbcbcb;
      padding: 0;
      .faq-questiond {
        padding: 30px 30px;
        @media (max-width: 767px) {
          padding: 15px 15px;
        }
      }
      
      &:last-child {
        border-bottom: none;
      }
      .heading {
        font-size: 22px;
        margin: 0;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .content {
        font-size: 16px;
        margin-bottom: 25px;
        line-height: 1.4;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    .faq-answer {
      padding: 0 30px 30px 30px;
        @media (max-width: 767px) {
          padding: 0 15px 15px 15px;
        }
    }
    .accordion-button {
      font-size: 22px;
      padding: 0;
      font-weight: 500;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    .accordion-button:not(.collapsed) {
      color: black !important;
      background-color: white !important;
      box-shadow: none !important;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    }
    .accordion-body {
      padding: 0 !important;
    }
  }
}

.faq-wrapper .faq-container .faq-item .content:first-child {
  margin-top: 15px;
}
.faq-wrapper .faq-container .faq-item .content:last-child {
  margin-bottom: 0;
}
