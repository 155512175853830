@import "../../../../assets/scss/_helpers/variables";

.trophies {
  .trophies-list {
    background-color: map-get($theme-colors, "sixth");
    display: flex;
    // display: grid;
    // grid-template-columns: 100px auto 100px;
    padding: 10px 15px;
    border-radius: 10px;
    align-items: center;
    position: relative;

    @media (max-width: 767px) {
      grid-template-columns: auto;
      text-align: center;
    }


    .trophies-img {
      position: relative;
      margin-right: 1rem;
      img {
        width: 50px;
        height: 50px;
        border-radius: 30px;
        object-fit: cover;
        border: 2px solid map-get($theme-colors, "primary");
        z-index: 3;
        position: relative;
      }
      .singh {
        max-width: 45px;
        position: absolute;
        left: 3px;
        top: -28px;
        z-index: 1;
      }
    }

    .second {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 15px;
    }

    .third {
      color: #928f8f;
      font-size: 14px;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      padding-left: 27px;
      margin-bottom: 0;

      .timer-icon {
        margin-right: 0.3rem;
        height: 13px;
        width: 13px;
        margin-top: 0;
      }
    }

    p.title {
      font-family: Roboto;
      font-weight: 800;
    }

    p.address {
      color: map-get($theme-colors, "tertiary");
    }

    .pin {
      width: 12px;
    }

    button {
      padding: 4px 9px;
      background-color: #e9e9e9;
      color: #2c2c2c;
      border-radius: 20px;
      font-weight: 600;
      width: max-content;

      @media (max-width: 767px) {
        margin: 10px 0;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}