.feat-icon-wrapper {
  padding: 30px 0 75px;
  .feat-card {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .text-holder {
      margin-left: 30px;
      h5 {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 5px;
      }
      p {
        color: #928f8f;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
.pd-btn {
  padding: 8px 30px !important;
}

.who-we-are-wrapper {
  background: #000;
  padding: 110px 0;
  .media-holder {
    border-radius: 10px;
    overflow: hidden;
  }
  .content-holder {
    h2 {
      font-size: 39px !important;
      font-weight: 400;
      margin: 0;
    }
    p {
      font-size: 18px;
      max-width: 400px;
      margin: 30px 0;
      font-weight: 300;
    }
    a {
      font-size: 14px;
      text-transform: uppercase;
      padding: 15px;
      max-width: 220px;
      width: 100%;
    }
  }
}

.youtube-video-frame{
  &.about-v2 {
    background-image: url(../../../../assets/images/about-bg.png);
    background-position: bottom left;
    background-size: cover;
    background-repeat: no-repeat;
    height: 790px;
    @media (max-width: 1399.9px) {
      height: 750px;
    }
    @media (max-width: 991.9px) {
      height: 650px;
    }
    @media (max-width: 767.9px) {
      height: 540px;
    }
    @media (max-width: 575.9px) {
      height: 460px;
    }
    @media (max-width: 499.9px) {
      height: 360px;
    }
    .about-bg2 {
      padding-top: 80px;
      background-image: url(../../../../assets/images/curve-bg.png);
      background-position: top right;
      background-size: contain;
      background-repeat: no-repeat;
      @media (max-width: 575.99px) {
        padding-top: 50px;
      }
      h3 {
        color: #212529 !important;
        text-align: left;
        font-size: 50px;
        line-height: 80px;
        letter-spacing: 0px;
        color: #212529;
        opacity: 1;
        margin-bottom: 25px;
        @media (max-width: 1399.9px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (max-width: 991.9px) {
          font-size: 34px;
          line-height: 42px;
        }
        @media (max-width: 767.9px) {
          font-size: 32px;
          line-height: 40px;
        }
        @media (max-width: 575.9px) {
          font-size: 26px;
          line-height: 20px;
        }
      }
    }
    .frame {
      height: 490px;
      width: 835px;
      iframe {
        height: 450px;
        width: 795px;
        border: 3px solid #FF0000;
      }
      @media (max-width: 991.9px) {
        height: 386px;
        width: 650px;
        iframe {
          height: 346px;
          width: 610px;
        }
      }
      @media (max-width: 767.9px) {
        height: 287px;
        width: 474px;
        iframe {
          height: 247px;
          width: 434px;
        }
      }
      @media (max-width: 499.9px) {
        height: 193px;
        width: 311px;
        iframe {
          height: 153px;
          width: 271px;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .who-we-are-wrapper {
    padding: 75px 0;
    .media-holder {
      margin-bottom: 50px;
    }
    .content-holder {
      text-align: center;
      h2 {
        font-size: 36px !important;
      }
      p {
        font-size: 16px;
        max-width: 100%;
        margin: 15px 0;
      }
      a {
        font-size: 14px;
        padding: 12px;
        max-width: 180px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .contact-page .inner-container.mt-5.py-5 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}
@media only screen and (max-width: 576) {
  .feat-icon-wrapper {
    padding: 0 0 40px;
    .feat-card {
      .text-holder {
        margin-left: 15px;
      }
    }
  }
}
