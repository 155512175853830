.blog-tutorial {
  .blog-item {
    .blog-bottom {
      bottom: 35px !important;
    }
    .blog-bottom-date {
      position: absolute;
      width: 100%;
      bottom: 5px ;
      padding: 0 15px;
      z-index: 3;
      color: #fff;
      font-size: 16px;
    }
    .layer.cursor-pointer {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 60px;
            height: unset;
        }
      }
  }
}
.react-player-video {
  .modal-body {
    div {
      width: 100% !important;
      height: 360px !important;
      iframe {
        width: 100%;
        height: 360px;
      }
    }
    @media (max-width: 575.9px) {
      div {
        height: 310px !important;
      iframe {
        height: 310px;
      }
      }
    }  
  }
}
