.navbar-dark {
  transition: all .5s ease-in-out;
  .navbar-nav {
    
    .active-nav-link {
      color: rgba(255, 255, 255, 0.75) !important;
    }

    .nav-link {
      // font-weight: 300;
      text-transform: uppercase;
      font-size: 18px;

      @media screen and (max-width: 991px) {
        // padding-left: 0 !important;
        padding-bottom: 5px;
        margin-bottom: 5px;
        width: fit-content;
      }

      &:active,
      &:focus-within,
      &:focus-visible {
        // color: #EA9B45 !important;
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
  .header-logo-main {
    height: 49.77px;
    z-index: 3;
    .header-logo {
      width: 125px;
      margin: auto;
      @media (max-width: 430px) {
        width: 100px;
        height: 49.77px;
        object-fit: contain;
      }
      @media (max-width: 390px) {
        width: 80px;
        height: 49.77px;
        object-fit: contain;
      }
    }
  }
  .navbar-toggler-icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: #000;
    background-image: none;
    display: flex;
    align-items: center;
    &:before {
      content: "\f0c9";
    }
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 30px;
  padding-left: 30px;

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
}

header {
  z-index: 100;
  width: 100%;

  // @media screen and (max-width: 991px) {
  //   background-color: #000000;
  // }

  &.isSticky {
    // background-color: #000000e8;
    position: relative;

    @media screen and (max-width: 991px) {
      position: relative;
    }
  }
}

.dashbaord-links {
  display: none;

  @media screen and (max-width: 991px) {
    display: revert;
  }

  .dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    text-align: left;
    padding-left: 0rem !important;
    width: 100% !important;

    &::after {
      position: absolute;
      top: 27px;
      right: 13px;
    }
  }

  .dropdown-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: transparent !important;
  }

  .dropdown-link {
    padding: 0rem;

    a {
      font-size: 0.813rem !important;
    }
  }
}

.login-btn {
  @media screen and (max-width: 991px) {
    display: revert !important;
  }
}

.desktop-login {
  margin-top: -8px;
  align-items: flex-start;
  z-index: 3;
  // @media screen and (max-width: 991px) {
  //   display: none !important;
  // }
  .btn {
    padding: 9px 32px !important;
  }
}

.mobile-login {
  @media screen and (min-width: 991px) {
    display: none !important;
  }
}

.mobile-notification-icon {
  position: absolute;
  top: 19px;
  right: 55px;
}

.loged-in {
  display: flex;
  align-items: center;

  .text-white {
    width: max-content;
  }

  &.user-detail {
    img {
      width: 40px;
      height: 40px;
      border-radius: 30px;
      object-fit: cover;
      border: 2px solid #fff;
    }
  }
}

// Styles by Mohsin
header .container-fluid {
  @media (min-width: 1200px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  .btn {
    padding: 10px 32px;
    font-size: 1rem;
  }
}

.notification-popover {
  .card-title {
    margin-bottom: 0.5rem;
    font-size: 20px;
    color: #2c2c2c;
  }

  .list-group {
    .list-group-item {
      .notification-image {
        width: 100%;
        max-width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
      }

      .twoline-text {
        margin-right: 15px;
        font-size: 16px;
        font-family: "Roboto";
        font-weight: 400;
        line-height: 1.4;
      }
    }
  }
}

.active--link {
  background-color: #000000 !important;
}