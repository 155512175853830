.title-area {
  max-width: 285px;
  margin-bottom: 30px;
  h3 {
    font-size: 22px;
    font-family: "Roboto";
  }
  p {
    color: #928f8f;
    font-size: 16px;
    margin: 0;
  }
}
.trophy-wrapper {
  border: 1px solid #cbcbcb;
  padding: 30px;
  border-radius: 12px;
  margin: 30px 0;
  position: relative;
  .trophy-form {
    .trophy-fields-wrapper {
      max-width: 285px;
      position: relative;
      .date-calander {
        position: relative;
      }
    }
    .field-group {
      margin-bottom: 15px;
      input {
        border-radius: 10px;
        height: 45px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #928f8f;
      }
      select {
        border-radius: 10px;
        height: 45px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #928f8f;
        &::-webkit-scrollbar {
          width: 8px;
          height: 3px;
          border-radius: 6px;
          background-color: #f5f5f5;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #A9A9A9;
            border-radius: 6px;
        }
      }
      textarea {
        border-radius: 10px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #928f8f;
        width: 100%;
        height: 100px;
        resize: none;
      }
      label {
        padding: 12px 10px;
      }
    }
  }
}

.select__control {
  border-color: #928f8f !important;
}

.trophy-wrapper {
  .res-image-upload {
    overflow: hidden;
    height: 240px;
    .uploaded-img-inner {
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.trophy-wrapper .res-image-upload.after-submit {
  height: 240px;
}

@media only screen and (max-width: 767px) {
  .trophy-wrapper {
    padding: 15px;
    margin: 15px 0;
  }
  .title-area {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .trophy-wrapper .trophy-form .trophy-fields-wrapper {
    max-width: 100%;
  }
}
