.landinfo {
  .follow-button {
    margin-bottom: 12px;

    .btn {
      text-transform: uppercase;
      padding: 7px 20px;
      border-radius: 8px !important;
    }

    &.unfollowed {
      .btn-primary {
        background-color: transparent;
        color: #EA9B45 !important;
      }
    }
  }

  .rating {
    span {
      color: #fec156;
      font-size: 18px;
    }
  }

  .price-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    >* {
      margin-bottom: 8px;
    }

    p {
      font-size: 32px;
      margin-bottom: 0;
      line-height: 1;

      span {
        font-size: 18px;
        color: #515151 !important;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .add-favourite {
      border: 1px solid #707070;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      margin-right: 5px;
      flex: 0 0 40px;

      i {
        font-size: 20px;
        padding-top: 5px;
      }

      // #EA9B45
      &.active {
        background-color: #EA9B45;
        border-color: #EA9B45;

        i {
          &:before {
            content: "\f415";
            color: #ffffff;

          }
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #EA9B45;
        border-color: #EA9B45;

        i {
          &:before {
            content: "\f415";
            color: #ffffff;

          }
        }
      }
    }
  }

  .btn-reserve {
    background-color: #f2f2f2;
  }

  .address-distance {
    p {
      display: flex;
      align-items: flex-start;
      position: relative;

      &:first-child {
        min-height: 34px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        &:before {
          content: "";
          position: absolute;
          bottom: -9px;
          left: 7px;
          width: 0;
          height: calc(100% - 12px);
          border-left: 2px dashed #ea9b45;
        }
      }
    }
  }

  .address-text {
    font-size: 18px !important;
    margin-left: 5px;
    width: 398px;
    // margin-top: -3px !important;
    // float: right;
    // margin-left: 20px !important;
    position: relative;
    // top: -4px;
    margin-bottom: 10px;
    margin-left: 0px !important;

    @media screen and (max-width: 1300px) {
      width: auto;
    }

  }
}

.google-map-link {
  color: #fec156;
  font-size: 18px;
  display: block;
  margin: 22px;
  margin-top: 0;
}

.rating-btn {
  &:hover {
    cursor: pointer !important;
  }
}

.no-reservation-available {
  display: flex;
  justify-content: space-between;
  border: 1px solid #cbcbcb;
  padding: 10px 10px;
  border-radius: 7px;
  align-items: center;
  font-size: 14px;
  flex: 1;
  justify-content: center;
}

.location-icon {
  margin-top: 1px;
  margin-right: 5px;
  float: left;
}