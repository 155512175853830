.review-reconsider-feedback-section{
    border: 1px solid #7070707A;
     padding: 1rem;
      border-radius: 16px;
      width: 80% !important;
      @media screen and (max-width:767px) {
          width: 100% !important;
      }
}
.feedback-section-text{
    font-size: 16px;
    color: #928F8F;
}