.main-img {
  img {
    width: 100%;
    object-fit: contain;
    background-color: black;
    height: 410px;
    border-radius: 7px;
    @media (max-width: 1399px) {
      height: 368px;
    }
    @media (max-width: 425px) {
      height: 300px;
    }
  }
}
.stand-swiper .stand-slider {
  height: 279px !important;
  img .stand-img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 7px;
    @media (max-width: 1399px) {
      height: 368px;
    }
    @media (max-width: 425px) {
      height: 300px;
    }
  }
}
.swiper-thumnail {
  margin-top: 15px;
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 7px;
    // max-width: 160px;
    max-width: 100%;
    max-height: 100%;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 991px) {
      height: 100px;
    }
    @media (max-width: 550px) {
      height: 80px;
    }
    @media (max-width: 374px) {
      height: 60px;
    }
  }
}
.thumbslider .swiper-button-prev,
.thumbslider .swiper-button-next {
  position: absolute !important;
  top: 50% !important;
  margin-top: 0 !important;
  color: #000 !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center;
  background: #fff;
  line-height: 43px;
  border-radius: 100%;
  transform: translateY(-50%);
}
.thumbslider .swiper-button-prev:after,
.thumbslider .swiper-button-next:after {
  font-size: 22px !important;
}

.stand-swiper .swiper-button-prev,
.stand-swiper .swiper-button-next {
  position: absolute !important;
  top: 50% !important;
  margin-top: 0 !important;
  color: #fff !important;
  display: inline-block !important;
  width: 25px !important;
  height: 25px !important;
  text-align: center;
  background: #ea9c11 !important;
  line-height: 23px;
  border-radius: 100%;
  transform: translateY(-50%);
}

.stand-swiper .swiper-button-prev:after,
.stand-swiper .swiper-button-next:after {
  font-size: 13px !important;
  font-weight: bold !important;
}

.stand-swiper .swiper-pagination-bullet {
  background: transparent !important;
  opacity: 0.8;
  border: 1px solid #fff;
}
.stand-swiper .swiper-pagination-bullet-active {
  background: #ea9c11 !important;
  border: unset;
}
