@import "../../../../../../assets/scss/_helpers/variables";

.ms-listing-box {
  // border: 1px solid map-get($theme-colors, "fifth");
  border-radius: 25px;
  padding: 1rem;
  display: flex;
  background-color: #f9f9f9;
  height: 100%;

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  .inner-content {
    position: relative;
    overflow: hidden;
    max-width: 160px;
    width: 100%;
    height: 170px;
    border-radius: 10px;

    @media (max-width: 575px) {
      max-width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    &:hover {
      .overlay {
        visibility: visible;
      }
    }

    .overlay {
      background: #000000ba;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      visibility: hidden;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      transition: all 0.3s ease 0s;

      a {
        text-decoration: none;
      }

      img {
        width: 40px;
        border-radius: 0;
      }
    }
  }

  .ms-listing-box-content {
    width: 100%;
    padding-left: 20px;

    @media screen and (max-width: 575px) {
      padding-top: 20px;
      padding-left: 0px;
    }

    .ms-location {
      display: flex;
      align-items: flex-start;
      margin: 10px 0;

      span {
        color: map-get($theme-colors, "tertiary");
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 40;
        margin: 0;
        font-size: 14px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .d-flex {
      >span {
        white-space: nowrap;
      }
    }
  }

  .address-distance {
    list-style: unset;
    padding: 0;
    margin-bottom: 5px;
    margin-top: 15px;

    li {
      display: flex;
      align-items: flex-start;
      position: relative;

      &:first-child {
        min-height: 34px;
      }

      &::marker {
        content: none;
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        &:before {
          content: "";
          position: absolute;
          bottom: -9px;
          left: 7px;
          width: 0;
          height: calc(100% - 12px);
          border-left: 2px dashed #DF9C56;
        }
      }

      img {}

      span {
        font: normal normal normal 14px/20px Roboto;
        color: map-get($theme-colors, "tertiary");
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.2;
      }
    }
  }
}

.ms-location {
  img {
    margin-right: 0.5rem;
    max-width: 12px;
  }
}

.three-dots {
  color: #DF9C56;

  &:hover {
    cursor: pointer;
  }
}

.dot-primary {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px;
  margin-right: 8px;
  display: block;
  background: #DF9C56;
}

.dot-secondary {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px;
  margin-right: 8px;
  display: block;
  background-color: #D60000;
}

.dot-warning {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px;
  margin-right: 8px;
  display: block;
  background-color: #E9BF45;
}

.text-primary {
  float: right;
  color: #DF9C56 !important;
}

.ms-listing-box-content .text-secondary {
  float: right;
  color: #D60000 !important;
}

.text-warning {
  float: right;
  color: #E9BF45 !important;
}

.text-rejected {
  float: right;
  color: #D60000 !important;
}

.stand-item-action .btn {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  max-width: 112px;
  padding: 7px 12px;
}

.stand-item-action .btn.btn-secondary {
  color: #2c2c2c;
  border: 1px solid #2c2c2c;
}

.price-per-stand strong {
  font-weight: 500 !important;
}

.booking-deatils {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.booking-deatils-text span {
  width: auto !important;
  color: #2c2c2c !important;
  font-weight: 700;
}

.booking-deatils .count-bubble {
  display: inline-block;
  width: 22px !important;
  height: 22px;
  background: #ccc;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  border-radius: 100px;
  color: #fff !important;
  background: #DF9C56;
  margin: 0;
  padding: 0;
  margin-left: 5px;
}

.deatils-link a {
  text-decoration: none;
  color: #DF9C56;
  font-size: 16px;
}

// #uncontrolled-tab-example-tabpane-reserved .ms-listing-box > img,
// #uncontrolled-tab-example-tabpane-listing .ms-listing-box > img {
//   max-width: 150px;
//   height: 155px;
// }

.tabs {
  position: relative;
  margin-top: 30px;
}

.spinner-loader {
  height: 100vh;
  position: relative;
}

.spinner-border {
  width: 2rem !important;
  height: 2rem !important;
  position: absolute;
  top: 20%;
  left: 45%;
}

.fade-in {
  transition: all 0.2s;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.item {
  width: 100%;
  padding: 15px 0px;
  // background-color: gray;
  font-size: 15pt;
  text-align: center;
  margin-bottom: 4px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.no-stand-found-heading {
  text-align: center;
  font: normal normal medium 40px/16px Roboto;
  font-size: 30px;
  letter-spacing: 0px;
  color: #2c2c2c;
  margin-top: 25px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;

  @media screen and (max-width: 991px) {
    font-size: 23px;
  }
}

.sub-heading {
  color: #6b6b6b;
  font-size: 20px;
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 17px;
  }
}

.listing-boxes {
  text-align: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;

  img {
    max-width: 100%;
  }
}

.no-fvrts {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    max-width: 500px;
    width: 100%;
  }
}

.multi-action-btns {
  display: flex;
  align-items: center;

  .img-btn.add-to-favorite {
    margin-right: 6px;

    .btn.btn-secondary {
      background: transparent;
      color: #DF9C56;
      padding: 0;
      border: 1px solid #707070 !important;
      border-radius: 6px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2px;

      &.active {
        background: #DF9C56;
        border-color: #DF9C56 !important;
      }
    }
  }

  >span {
    white-space: nowrap;

    i {
      margin-right: 3px;
    }
  }
}

.next-available-booking {
  border-left: 3px solid #DF9C56;
  margin-left: 0;
  padding-left: 7px;
  margin-top: 8px;
  margin-right: 5px;
  margin-bottom: 5px;

  .next-available-description {
    text-align: left;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 8px;
    line-height: 1;
  }

  .next-available-date {
    color: var(--unnamed-color-ea9b45);
    text-align: left;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0px;
    color: #DF9C56;
    margin: 0px;
    line-height: 1;
  }
}

// search landowner

.stand_landowner {
  padding-left: 0 !important;
  .modal-body {
    padding: 50px;

    @media screen and (max-width: 575px) {
      padding: 25px 20px;
    }
  }

  .slick-list {
    margin: 0 -7px !important;
  }

  .slick-slide>div {
    padding: 0 7px !important;
  }

  .title {
    margin-bottom: 10px;
  }
 
  
  .modal-header {
    border: 0;
    position: absolute;
    right: 40px;
    top: 40px;
    @media (max-width: 575.9px) {
        border: 0;
        position: absolute;
        right: 20px;
        top: 25px;
        padding: 0;
    }
    .btn-close {
      z-index: 99;
    }
  }

  .modal-body {
    padding-bottom: 40px;
    max-height: 782px;
    .search-field-main {
    margin-bottom: 20px;
    }
    .search-field {
      .form-control {
        // background: #f2f2f2;
        // color: #928f8f;
        border-radius: 10px;
        font-size: 14px;
        height: 48px;

        &::placeholder {
          opacity: 0.5;
        }
      }
      .form-floating {
        & > label {
          top: -3px;
        }
      }
    }
    .phone-input2 {
      width: 100%;
      border-radius: 10px;
        
      +.flag-dropdown {
        border: 1px solid #C8C8C8;
        .country-list {
          // width: 100%;
          margin-top: 1px;
        }
      }
    }
  }

  .slick-slider {
    // max-height: 607px;
    max-height: 514px;
  }

  .landowner_wrapper {
    border: 1px solid #cbcbcb;
    border-radius: 8px;
    height: 100px;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
    &.wrapper2 {
      border: 0;
      border-radius: 0;
      box-shadow: 3px 3px 15px #0000000F;
      ul {
        border-bottom: 1px solid transparent !important;
        &:active,&.active,
        &:focus,
        &:hover {
          background-color: #F2F2F2;
        }
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      padding: 15px 20px;
      margin: 0;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        .user-info {
          display: flex;
          align-items: center;
          margin-right: 25px;

          @media screen and (max-width: 991px) {
            margin-right: 12px;
          }

          &__img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 12px;
            flex: 0 0 50px;

            img {
              height: 100%;
              width: 100%;
            }
          }

          &__desc {
            h5 {
              color: #2c2c2c;
              font-size: 18px;
              font-weight: 600;
              font-family: "Roboto", sans-serif !important;
              margin-bottom: 2.5px;
            }

            h6 {
              color: #707070;
              font-size: 15px;
              font-family: "Roboto", sans-serif !important;
              margin-bottom: 0;
            }
          }
        }

        @media screen and (max-width: 767px) {
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #cbcbcb;
      }
    }

    .transfer-btn {
      color: #DF9C56;
      text-decoration: unset;
      white-space: nowrap;
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      border: 0;
      background-color: transparent;
      box-shadow: none;

      i {
        margin-right: 5px;
        transition: 0.3s all ease-in-out;
      }

      &:hover {
        // color: #2c2c2c;
        opacity: .75;

        i {
          margin-right: 15px;
        }
      }
    }
  }

  .slick-slider {
    .slick-dots {
      bottom: -35px;
    }
  }

  .confirmation-wizard {
    .title {
      .back-btn {
        font-size: 18px;
        margin-right: 10px;
        transition: 0.3s all ease-in-out;
        cursor: pointer;

        &:hover {
          margin-right: 20px;
          // color: #ea9b45;
          opacity: .75;
        }
      }
    }

    .desc {
      margin-top: 25px;
      margin-bottom: 0;

      p {
        font-size: 20px;
        margin-bottom: 25px;
      }

      .text-sbold {
        font-weight: 600;
        margin-bottom: 12px;
      }
    }
  }
}
.initiate-transfer {
  .dropdown-menu {
    box-shadow: 0px 5px 14px #00000033;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    padding: 0;
    min-width: 148px;
    .dropdown-item {
        color: #000000;
        font-size: 14px;
        padding: 6px 14px !important;
        line-height: 1.3;
        
        &:active,
        &:focus,
        &:hover {
          background-color: #E9ECEF;
          color: #4B4D51;
        }
    
        &.red-dropdown-item {
          color: #D60000;
        }
    }
  }
}
.invite-wraper {
  width: 330px !important;
  height: 150px;
  background: #F3F3F3 0% 0% no-repeat padding-box;
  border: 1px solid #70707033;
  border-radius: 12px;
  opacity: 1;
  margin: 5px;

  img {
    width: 125px;
    height: 140px;
    border-radius: 12px;
    opacity: 1;
    vertical-align: middle;
  }

  .title {
    text-align: left;
    font: 20px/16px Minion Pro;
    letter-spacing: 0px;
    color: #2C2C2C;
    opacity: 1;
  }

  .address {
    text-align: left;
    font: 16px/20px Roboto;
    letter-spacing: 0px;
    color: #928F8F;
    opacity: 1;
  }
}

.ownership_invite {
  .ms-listing-box-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.inline-buttons {
  .btn {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
.invitation-modal-form {
  margin-top: 20px;
  // margin-bottom: 30px;
  form {
    .form-check-input {
      width: 17px;
      height: 17px;
      border: 1px solid #DCDCDC;
      margin-top: 0;
      &:checked {
        background-color: #ffffff;
        border-color: #DCDCDC;
        background-image: none;
        position: relative;
        &::after {
          content: "";
          width: 7px;
          height: 7px;
          position: absolute;
          left: 4px;
          top: 4px;
          background-color: #EA9B45;
          border-radius: 100%;
        }
      }
    }
    .form-check-label {
      font-size: 16px;
      line-height: 18px;
      color: #2C2C2C;
      text-transform: math-auto;
      font-weight: 600;
    }
    form {
      transition: display 0s linear 0.5s, opacity 0.5s linear;
      visibility: visible;
      opacity: 1;
      transition-delay: 0.4s;
    }
  }
}
.btn {
  &.btn-danger {
    background-color: #CB2027;
    border-color: #CB2027;
  }
}

.transfer-btn-font {
  font: normal normal normal 18px/24px Roboto !important;
  text-transform: uppercase;
}

.h-85 {
  min-height: 85.66px;
}

.create-blog-post-dialog {
  .modal-dialog{
    max-width:620px ;
  }
  .modal-body {
      padding: 25px 50px;

      @media screen and (max-width: 575px) {
          padding: 25px 20px;
      }
  }

  .title {
      margin-bottom: 25px;
  }

  textarea {
      &.form-control {
          min-height: 220px;
      }
  }

  .modal-header {
      border: 0;
      position: absolute;
      right: 0;
      top: 5px;

      .btn-close {
          z-index: 99;
      }
  }

  form {
      .blog-desc {
          label {
              background: #fff;
              height: 20px;
              margin-top: 8px;
              margin-left: 6px;
              font-size: 14px;
              padding: 0px;
          }
      }

      .form-floating {
        margin-bottom: 20px;

        >label {
          padding-block: 8px 0 !important;
          margin: 0;
        }

        .form-control {
          border-radius: 12px;
          border: 1px solid #000000;
          font-size: 14px;
          padding-block: 26px 8px !important;
          padding-left: 16px !important;

        }

        .form-control-error {
          border-radius: 12px;
          border: 1px solid #EB3434;
          font-size: 14px;
        }

        .error {
          color: #EB3434;
        }

      }

      .error {
          color: #EB3434;
      }

      .title-error {
          position: relative;
          color: #EB3434;
          top: -15px;
      }

      .images-uploader-wrapper {
          border: 1px dashed #000000;
          border-radius: 19px;
          opacity: 1;
          padding: 35px 21px;
          padding-bottom: 27px;
          display: flex;
          flex-wrap: wrap;

          >div {
              margin-bottom: 8px;

              &:not(:first-child) {
                  margin-left: 25px;

                  @media screen and (max-width: 575px) {
                      margin-left: 12px;
                  }
              }
          }

          .size-exceed-error-msg {
              margin: 0;
              flex: 0 0 100%;
              text-align: center;
              margin-left: 0 !important;
              margin-bottom: 0 !important;
              font-size: 14px;
              color: #D60000;
          }

          .uploaded-image {
              position: relative;
              display: inline-block;

              &.size-exceed-error {
                  img {
                      border: 3.5px solid #D60000;
                  }
              }

              img {
                  width: 58px;
                  height: 58px;
                  overflow: hidden;
                  border-radius: 6px;
              }

              .del-img {
                  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
                  display: inline-block;
                  background-repeat: no-repeat;
                  cursor: pointer;
                  position: absolute;
                  box-shadow: 0px 3px 6px #00000029;
                  width: 16px;
                  background-color: #ffffff;
                  height: 16px;
                  top: -5px;
                  right: -5px;
                  background-size: 8px;
                  background-position: center;
                  border-radius: 100px;
              }

          }

          .add-image {
              text-align: center;
              position: relative;
              z-index: 1;
              margin-left: auto;
              margin-right: auto;

              .max-limit {
                  color: #A8A8A8;
                  font-size: 16px;
                  margin-top: 10px;
                  display: block;
                  max-width: 283px;
                  margin-left: auto;
                  margin-right: auto;
                  line-height: 1.2;
              }

              .add-icon {
                  width: 58px;
                  height: 58px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f1f1f1;
                  margin: auto;
                  font-size: 26px;
                  font-weight: 700;
                  color: #929090;
                  border-radius: 6px;
              }

              .add-icon-error {
                  width: 58px;
                  height: 58px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f1f1f1;
                  margin: auto;
                  font-size: 26px;
                  font-weight: 700;
                  color: red;
                  border: 1px solid red;
              }

              label {
                  padding: 0;
                  margin-top: 10px;
                  font-size: 12px;
                  margin-top: 0px;
              }

              input {
                  position: absolute;
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 99;
                  cursor: pointer;
              }
          }
      }

      .uploaded-media-wrapper{
        border: 1px dashed #000000;
        border-radius: 19px;
        opacity: 1;
        padding: 30px ;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 14px;
        margin-bottom: 20px;

        .size-exceed-error-msg {
            margin: 0;
            flex: 0 0 100%;
            text-align: center;
            margin-left: 0 !important;
            margin-bottom: 0 !important;
            font-size: 14px;
            color: #D60000;
        }

        .uploaded-image {
            position: relative;
            display: inline-flex;

            &.size-exceed-error {
                .preview-image {
                    border: 3.5px solid #D60000;
                }
            }

            .preview-image {
                width: 58px;
                height: 58px;
                overflow: hidden;
                border-radius: 6px;
                object-fit: cover;
            }

            .del-img {
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
                display: inline-block;
                background-repeat: no-repeat;
                cursor: pointer;
                position: absolute;
                box-shadow: 0px 3px 6px #00000029;
                width: 16px;
                background-color: #ffffff;
                height: 16px;
                top: -5px;
                right: -5px;
                background-size: 8px;
                background-position: center;
                border-radius: 100px;
            }
            .type-icon{
                position: absolute;
                bottom: 3px;
                left: 3px;
            }

        }
        .progress-circle {
          width: 60px;
          height: 60px;
        }

        .circular-chart {
          display: block;
          max-height: 250px;
        }

        .circle-bg {
          fill: none;
          stroke: #eee;
          stroke-width: 3.8;
        }

        .circle {
          fill: none;
          stroke-width: 2.8;
          stroke-linecap: round;
          stroke: map-get($theme-colors, "primary");
          animation: progress 1s ease-out forwards;
        }

        .percentage {
          fill: map-get($theme-colors, "primary");
          font-family: sans-serif;
          font-size: 0.5em;
          text-anchor: middle;
        }

        @keyframes progress {
          0% {
            stroke-dasharray: 0 100;
          }
        }
      }

      .media-uploader-wrapper {
        border: 1px dashed #000000;
        border-radius: 19px;
        opacity: 1;
        padding: 23px 28px;
        display: flex;
        gap: 8px;

        // flex-wrap: wrap;
      
        .image-uploader-wrapper,
        .video-uploader-wrapper {
      
          &.exceeded {
            opacity: 0.6;
          }
        }

        >div {
            text-align: center;
            position: relative;
            z-index: 1;

            .max-limit {
                color: #B2B0B0;
                font-size: 13px;
                margin-top: 7px;
                display: block;
                max-width: 200px;
                line-height: 1.5;
            }

            input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 99;
                cursor: pointer;
            }

            input[type="file" i]:disabled {
                pointer-events: none;
            }
        }
        .seperator{
            width: 1px;
            background-color: #70707059;
            min-height: 100%;
            margin-inline: auto;
        }
        .images-uploader-wrapper {}

        .video-uploader-wrapper {}
      }

      .modal-action-btns {
        display: flex;
        gap: 10px;
        margin-top: 30px;
    
        @media (max-width: 575.9px) {
          margin-top: 20px;
        }
    
        .modal-action-btn {
          border-radius: 10px;
          border: 0;
          color: #FFFFFF;
          font-size: 14px;
          line-height: 1.2;
          padding-block: 15px;
          width: 100%;
          max-width: 146px;
    
    
          &:hover,
          &:active,
          &:focus {
            opacity: .95;
          }
    
          &.outlined {
            border: 1px solid #515151;
            color: #2C2C2C;
            background-color: transparent;
    
            &:hover,
            &:active,
            &:focus {
              opacity: .8;
            }
          }
    
          &.filled {
            background-color: #EA9B45;
            border: 1px solid #EA9B45;
          }
    
    
          @media (max-width: 575.9px) {
            max-width: none;
            padding-block: 12px;
          }
        }
      }
  }
}