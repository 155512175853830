.feedback-top-content {
  p {
    color: #928f8f;
    font-size: 18px;
  }
}
.feedback-form {
  margin-top: 30px;
  .form-group {
    margin-bottom: 15px;
  }
  .field-style {
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    padding: 11px 20px;
    background-color: #fff;
    width: 100%;
    height: 120px;
  }
  .action {
    text-align: right;
    .cancle-btn {
      background: #f2f2f2;
      border: 1px solid #f2f2f2;
      margin-right: 15px;
      color: #515151;
      text-transform: uppercase;
    }
  }
}
