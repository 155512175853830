@import "../../../../assets/scss/_helpers/variables";

.downlodes {
  background-color: map-get($theme-colors, "sixth");

  @media (min-width: 992px) {
    // margin-top: 100px;
    // margin-bottom: 60px;
  }

  .inner-container {
    padding-top: 70px;
    padding-bottom: 70px;
    .downlodes-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    p {
      max-width: 50%;
      @media (max-width: 991.9px) {
        max-width: 80%;
      }
      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }

    h2 {
      @media (min-width: 992px) {
        font-size: 50px;
      }
    }

    img {
      max-width: 200px;
      @media (max-width: 991.9px) {
        max-width: 130px;
      }
      @media (max-width: 575px) {
        max-width: 130px;
      }
    }

    .img-section {
      @media (max-width: 991.9px) {
        align-items: center;
        display: flex;
      }
      div {
        position: absolute;
        top: 0px;

        @media (min-width: 992px) {
          top: -22%;
        }

        @media screen and (max-width: 1400px) {
          right: -20px;
        }
        @media (max-width: 991.9px) {
          position: relative;
          right: auto;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }

        img {
          max-width: 200px;

          @media (min-width: 992px) {
            max-width: 335px;
          }

          width: 100%;
        }
      }
    }
  }
}