@import "../../../../assets/scss/_helpers/variables";

.form-container {
  padding-bottom: 100px;
  min-height: calc(80vh - 309px);

  .progress-style {
    max-width: 420px;
    text-align: center;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 14.5px;
      left: 41px;
      transform: translateY(-50%);
      width: calc(100% - 65px);
      height: 1px;
      background-color: #ccc;
    }
  }

  .progress-body {
    border: 1px solid map-get($theme-colors, "primary");
    border-radius: 100%;
    padding: 4px 8px 4px 8px;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    height: 29px;
    width: 29px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    &.active {
      background-color: map-get($theme-colors, "primary");
      color: #ffffff;
      z-index: 1;
    }
  }

  .step {
    position: relative;
    flex: 1;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: #000;
    &.active {
      background-color: map-get($theme-colors, "primary");
      color: #ffffff;
      z-index: 1;
    }
  }

  .form-box {
    border: 1px solid map-get($theme-colors, "fifth");
    max-width: 470px;
    text-align: center;
    padding: 40px 75px;
    margin: auto;
    border-radius: 15px;
    margin-top: 50px;

    .image-container {
      display: flex;
      align-items: center;
      position: relative;
      width: 157px;
      left: 13px;
      bottom: 10px;
      margin: auto;
      color: white;
      border-radius: 50%;
      padding: 0.2rem 0.5rem;

      img {
        border-radius: 50%;
        object-fit: cover;
        width: 100px;
        height: 100px;
      }

      input {
        margin-left: 10%;
        display: none;
        cursor: pointer;
      }

      label {
        margin-bottom: 0rem !important;
        display: inline;

        i {
          position: absolute;
          bottom: 4px;
          right: 54px;
          cursor: pointer;
          background: #ea9b45;
          padding: 6px;
          border-radius: 50%;
          color: #ffffff;
        }
      }
    }

    @media (max-width: 767px) {
      max-width: unset;
      padding: 30px 35px;
    }

    input,
    select {
      border-radius: 10px;
      height: 45px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
      width: 100% !important;
    }

    label {
      padding: 12px 10px;
    }

    .form-floating>.form-control:not(:placeholder-shown) {
      padding-top: 20px;
    }

    span {
      color: map-get($theme-colors, "tertiary");
      font-size: 14px;

      a {
        color: map-get($theme-colors, "darkblack");
        font-weight: 600;
      }
    }

    .input-icon {
      position: absolute;
      right: 9px;
      top: 9px;
      cursor: pointer;
    }
  }

  .invalid-feedback {
    margin-top: -5px;
    text-align: left;
    margin-bottom: 10px;
  }

  .updated-icon {
    width: 40px;
    height: 40px;
  }
}

.form-floating>.form-select {
  padding: 5px 0px 0 10px !important;
  border-radius: 0.5rem;
  border: 1px solid #928f8f;
  height: 3.2rem !important;
}

// Phone dropdown content setting
.react-tel-input .country-list {
  width: 270px !important;
}

.react-tel-input .country-list .country {
  text-align: left;
}

.width-50 {
  width: 46% !important;
}

.margin-right {
  margin-right: 1%;
}
.form-container {
  .form-box {
    &.referral-code-box {
      .image {
        img {
          border-radius: 0;
          object-fit: contain;
          width: auto;
          height: 230px;
        }
      }
      .referral-pin {
        display: flex;
        gap: 10px;
        flex-direction: column;
        span {
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0px;
          color: #928F8F;
          opacity: 1;
          padding: 0;
          margin-top: 30px;
        }
        p {
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0px;
          color: #928F8F;
          opacity: 1;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
      .btns {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        button {
          flex-grow: 1;
          margin-top: 20px;
          text-transform: uppercase;
          &:first-child {
            border-color: #515151 !important;
            background-color: transparent;
            color: #2C2C2C !important;
          }
        }
      }
      .login-text {
        display: flex;
        margin-top: 10px;
        justify-content: center;
        a {
          color: #000000;
          font-weight: 600;
          padding-left: 3px;
        }
      }
    }
  }
}