@import "assets/scss/_helpers/variables";

.vchr-top-content-holder {
  display: flex;
  justify-content: space-between;
}

.vouchers-tab-content{
  display: flex;
  flex-direction: column;
}

.voucher-boxes{
  margin-top: 50px !important;

  @media only screen and (max-width:768px) {
    margin-top: 35px !important;
  }
}

.voucher-boxes .discount {
  padding: 15px 15px 15px 0;
  color: #ea9b45;
  font-size: 14px;
  flex: 0 0 65px;
}

.voucher-boxes .vchr-box .upperdetails {
  padding: 15px 0 10px 15px;
  display: flex;
}

.voucher-boxes .vchr-box .vchr-bottom {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.voucher-boxes .vchr-box .vchr-bottom p {
  margin: 0;
  font-size: 14px;
}

.voucher-boxes.voucher-tab-cards .vchr-box .vchr-bottom .btn {
  padding: 5px 30px !important;
  color: map-get($theme-colors, "secondary");
  border-radius: 10px;

  &.disabled {
    background-color: #e9e9e9;
    border-radius: 14px;
    padding: 3px 10px !important;
    font-size: 12px !important;
  }
}

.voucher-boxes.voucher-tab-cards .vchr-box .vchr-bottom p {
  font-size: 14px;
}

.voucher-boxes.voucher-tab-cards .vchr-box .vchr-bottom p span {
  color: rgba(146, 143, 143, 1);
}

.no-vouchers{
  flex-direction: column-reverse;
}
.create-btn {
  border-radius: 10px;
  font-size: 18px;
  background-color: #EA9B45;
  border: 0;
  color: #F5F9FF;
  padding: 18px 40px !important;
  margin-inline: auto;
  line-height: 1.25;
  max-width: fit-content !important;
  margin-top: 34px !important; 

  &:hover,
  &:active,
  &:focus {
    background-color: #edaa61;
    color: #F5F9FF;
  }

  @media only screen and (max-width:768px) {
    padding: 12px 30px !important;
    font-size: 1rem;
    margin-top: 17px !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}