@import "assets/scss/_helpers/variables";

.voucher-boxes {
  .vchr-box {
    border: 1px solid map-get($theme-colors, "fifth");
    border-radius: 14px;
    position: relative;

    .upperdetails {
      padding: 15px;
      display: flex;

      .mt-neg-2 {
        margin-top: -2px;
      }

      svg {
        margin-right: 10px;
      }

      .fs-18 {
        line-height: 22px;
      }
    }

    .vchr-box-sep {
      border-top: 1px dashed map-get($theme-colors, "fifth");
      background-color: transparent;
      opacity: 1;

      &::before {
        content: " ";
        width: 26px;
        height: 26px;
        background-color: #fff;
        border: 1px solid map-get($theme-colors, "fifth");
        border-radius: 30px;
        display: block;
        position: absolute;
        left: -14px;
        margin-top: -13px;
      }

      &::after {
        content: " ";
        width: 26px;
        height: 26px;
        background-color: #fff;
        border: 1px solid map-get($theme-colors, "fifth");
        border-radius: 30px;
        display: block;
        position: absolute;
        margin-top: -13px;
        left: auto;
        right: -14px;
      }
    }

    .vchr-bottom {
      padding: 14px 22px;

      .btn {
        padding: 5px 30px !important;
        color: #EA9B45 !important;
        border-radius: 10px;

        &:focus {
          background: #fff;
          color: #EA9B45;
          border-color: #EA9B45;
        }

        &:visited {
          background: #fff;
          color: #EA9B45;
          border-color: #EA9B45;
        }

        &:hover {
          background: #EA9B45;
          color: #fff !important;
          border-color: #EA9B45;
        }

        &.disabled {
          background-color: #e9e9e9;
          border-radius: 14px;
          padding: 3px 10px !important;
          font-size: 12px !important;
        }
      }
    }
  }
}

.past-boxes {
  opacity: 0.5;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}