// .input-icon.password img {
//   width: 24px;
//   height: 25px;
// }
.spinner-loader {
  height: 100vh;
  position: relative;
}
.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
  // position: absolute;
  // top: 20%;
  // left: 45%;
}
