@import "../../../../assets/scss/_helpers/variables";

.review-item {
  display: flex;
  background-color: map-get($theme-colors, "sixth");
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 20px;
  align-items: flex-start;
  .review-user-image {
    width: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
    overflow: hidden;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .review-detail {
    margin-left: 20px;
    flex: 1 1 auto;
    .review-detail-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      p {
        margin: 0;
      }
      .bi {
        color: #fec156;
      }
    }
    .review-detail-bottom {
      p {
        margin: 0;
      }
    }
    .review-date {
      color: #928f8f;
      font-size: 14px;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .timer-icon {
        margin-right: 0.3rem;
        height: 13px;
        width: 13px;
      }
    }
  }
}
