.create-voucher-confirmation-modal {
  .modal-content {
    border-radius: 9px;
  }

  .modal-header {
    align-items: center;
    padding: 60px 60px 0 60px;
    border-bottom: 0;
    margin-bottom: 4px;

    @media (max-width: 575.9px) {
      padding: 30px 30px 0 30px;
    }

    .btn-close {
      width: 17.7px;
      height: 17.7px;
      opacity: 1;

      @media (max-width: 575.9px) {
        top: 30px;
        right: 30px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 18px;
      margin: 0;
      color: #2C2C2C;
      font-family: 'Minion Pro';
      font-weight: 500;

      @media (max-width: 575.9px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0;
      text-align: center;
      margin-top: 6px;

      a {
        font-size: 16px;
        line-height: 20px;
        color: #EA9B45;
      }

      @media (max-width: 575.9px) {
        font-size: 14px;
        list-style: 18px;

        a {
          font-size: 14px;
          list-style: 18px;
        }
      }
    }
  }

  .modal-body {
    padding: 10px 60px 60px 60px;

    @media (max-width: 575.9px) {
      padding: 10px 30px 30px 30px;
    }

    .modal-body-text {
      color: #2C2C2C;
      font-size: 14px;
      margin-bottom: 30px;
    }


    .create-voucher-confirmation-form {

      .created-voucher-section {
        padding: 20px;
        border: 1px solid #928F8F;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        .discount-title {
          font-family: 'Roboto', sans-serif;
          font-size: 20px;
          font-weight: 400;
          color: #EA9B45;
          margin-bottom: 10px;
        }

        .expires {
          font-size: 14px;
          color: #EA9B45;
          margin: 0;

          .discount {
            margin-left: 20px;
          }
        }

        .toggle-menu {
          i {
            color: #EA9B45;
            font-size: 28px;
            cursor: pointer;
          }

          .dropdown-menu {
            box-shadow: 0px 5px 14px #00000033;
            border: 1px solid #D4D4D4;
            border-radius: 5px;
            padding: 0;
            min-width: 100px;
            .dropdown-item {
                color: #000000;
                font-size: 14px;
                padding: 6px 14px !important;
                line-height: 1.3;
                
                &:active,
                &:focus,
                &:hover {
                  background-color: #E9ECEF;
                  color: #4B4D51;
                }
            
                &.red-dropdown-item {
                  color: #D60000;
                }
            }
          }
        }
      }


      .modal-action-btns {
        display: flex;
        gap: 10px;
        margin-top: 30px;

        @media (max-width: 575.9px) {
          margin-top: 20px;
        }

        .modal-action-btn {
          border-radius: 10px;
          border: 0;
          color: #FFFFFF;
          font-size: 14px;
          line-height: 1.2;
          padding-block: 15px;
          width: 100%;
          max-width: 146px;


          &:hover,
          &:active,
          &:focus {
            opacity: .95;
          }

          &.outlined {
            border: 1px solid #515151;
            color: #2C2C2C;
            background-color: transparent;

            &:hover,
            &:active,
            &:focus {
              opacity: .8;
            }
          }

          &.filled {
            background-color: #EA9B45;
            border: 1px solid #EA9B45;
          }


          @media (max-width: 575.9px) {
            max-width: none;
            padding-block: 12px;
          }
        }
      }

    }

  }
}

.voucher-detail-calender {
  .voucher-created-date {
    background-color: #eeaf6a !important;
    color: #fff !important;
  }
}