.create-voucher-modal {
  .modal-content {
    border-radius: 9px;
  }

  .modal-header {
    align-items: center;
    padding: 60px 60px 0 60px;
    border-bottom: 0;
    margin-bottom: 4px;

    @media (max-width: 575.9px) {
      padding: 30px 30px 0 30px;
    }

    .btn-close {
      width: 17.7px;
      height: 17.7px;
      opacity: 1;

      @media (max-width: 575.9px) {
        top: 30px;
        right: 30px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 18px;
      margin: 0;
      color: #2C2C2C;
      font-family: 'Minion Pro';
      font-weight: 500;

      @media (max-width: 575.9px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0;
      text-align: center;
      margin-top: 6px;

      a {
        font-size: 16px;
        line-height: 20px;
        color: #EA9B45;
      }

      @media (max-width: 575.9px) {
        font-size: 14px;
        list-style: 18px;

        a {
          font-size: 14px;
          list-style: 18px;
        }
      }
    }
  }

  .modal-body {
    padding: 10px 60px 60px 60px;

    @media (max-width: 575.9px) {
      padding: 10px 30px 30px 30px;
    }

    .modal-body-text {
      color: #2C2C2C;
      font-size: 14px;
      margin-bottom: 30px;
    }


    .create-voucher-form {
      .form-floating {
        input {
          border: 1px solid #928f8f;
          height: 48px;
          border-radius: 10px;
          padding-top: 20px !important;
        }

        label {
          padding-top: 12px;
        }

        .form-control-error {
          border-radius: 12px;
          border: 1px solid #EB3434;
          font-size: 14px;
        }
      }

      .input-icon {
        position: absolute;
        right: 14px;
        top: 12px;
        cursor: pointer;
      }

      .discount-section {
        display: flex;
        gap: 10px;
        margin-block: 15px;

        &-left {
          width: 50%;
        }

        &-right {
          display: flex;
          gap: 10px;
          width: 50%;

          .btn {
            border: 1px solid #928F8F;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 9px;
            color: #2C2C2C !important;
            font-size: 1rem;
            box-shadow: none;

          }

          .btn-check:checked+.btn-secondary,
          .btn-check:active+.btn-secondary,
          .btn-secondary:active,
          .btn-secondary.active,
          .show>.btn-secondary.dropdown-toggle {
            background-color: #FFEAD4;
            border-color: #EA9B45;
            color: #2C2C2C;
          }
        }
      }

      .toggle-usage-and-expiry {
        display: flex;
        gap: 30px;
        margin-block: 15px;
        .radio-input {
          width: 17px;
          height: 17px;
          border: 1px solid #DCDCDC;
          background-color: #ffffff;
          margin-top: 0;
          border-radius: 100%;
          margin-right: 7px;
          cursor: pointer;
          -webkit-appearance: none;
          position: relative;

          &:checked {
            background-color: #ffffff;
            border-color: #DCDCDC;
            background-image: none;
            position: relative;

            &::after {
              content: "";
              width: 7px;
              height: 7px;
              position: absolute;
              left: 4px;
              top: 4px;
              background-color: #EA9B45;
              border-radius: 100%;
            }
          }
        }

        .label {
          font-size: 14px;
          // line-height: 18px;
          color: #2C2C2C;
          text-transform: math-auto;
          padding: 0;
          cursor: pointer;

          span {
            position: relative;
            bottom: 3px;
          }
        }
      }

      .modal-action-btns {
        display: flex;
        gap: 10px;
        margin-top: 30px;

        @media (max-width: 575.9px) {
          margin-top: 20px;
        }

        .modal-action-btn {
          border-radius: 10px;
          border: 0;
          color: #FFFFFF;
          font-size: 14px;
          line-height: 1.2;
          padding-block: 15px;
          width: 100%;
          max-width: 146px;


          &:hover,
          &:active,
          &:focus {
            opacity: .95;
          }

          &.outlined {
            border: 1px solid #515151;
            color: #2C2C2C;
            background-color: transparent;

            &:hover,
            &:active,
            &:focus {
              opacity: .8;
            }
          }

          &.filled {
            background-color: #EA9B45;
            border: 1px solid #EA9B45;
          }


          @media (max-width: 575.9px) {
            max-width: none;
            padding-block: 12px;
          }
        }
      }

    }

  }
}