@import "../../../../assets/scss/_helpers/variables";

.stands {
  margin-top: 35px;
  font-family: 'Roboto', sans-serif;
  // min-height: 600px;
  h1 {
    font-size: 3rem !important;

    @media (max-width: 992px) {
      font-size: 2rem !important;
    }
  }

  .pagination {
    li {
      height: 48px;
      width: 48px;

      &:hover {
        background-color: map-get($theme-colors, "primary");

        i {
          color: #fff;
        }
      }
    }
  }
}

.marker {
  background-color: #fff;
  padding: 13px 6px;
  width: max-content;
  // min-width: 80px;
  min-width: 70px;
  max-width: 250px;
  height: auto;
  border-radius: 7px;
  text-align: center;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 100%;
  border-bottom-left-radius: 0;
  transform: rotate(-45deg);
  width: 71px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #ea9b45;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 15px;
    margin: 0;
    word-break: break-word;
  }

  .price-with-icon {
    transform: rotate(45deg);
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  position: absolute;
  bottom: -17px;
  left: 50%;
  transform: translateX(-50%);
}

.display-none {
  visibility: hidden;
}

.stands .infinite-scroll-component {
  overflow: hidden !important;
}

.stand-listig-with-map,
.stand-listig-without-map {
  &.mh-120 {
    .spinner-loader {
      max-height: 120px;
      margin-top: 60px;
    }
  }
}

.cluster-marker {
  color: #fff;
  //background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
    .img {
      z-index: 1;
   
    img {
      width: 50px;
      height: 50px;
    }
  }
  span {
    z-index: 2;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    line-height: 17px;
  }
}