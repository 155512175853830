.edit-stand-div {
  padding: 2rem;
  .modal-content {
    height: 25rem;
    @media screen and (max-width: 1024px) {
      height: auto;
    }

    .modal-body {
      .edit-stand-popup {
        .text-section {
          padding-left: 2rem;
          @media screen and (max-width: 991px) {
            padding-right: 2rem;
            padding-bottom: 2rem;
          }
        }
        a {
          cursor: pointer;
          margin-right: 1rem;
          margin-bottom: 1rem;
          @media screen and (max-width: 375px) {
            margin-right: 0.8rem;
          }
          img {
            @media screen and (max-width: 991px) {
              width: 8rem;
            }
          }
        }
        .img-section {
          position: absolute;
          top: -152px;
          right: 94px;
          @media screen and (max-width: 1024px) {
            position: relative;
            top: 0px;
            right: 0px;
            img {
              width: 13rem;
              height: 20rem;
            }
          }
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 3px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #A9A9A9;
      border-radius: 6px;
  }
}
