.reservation-boxes {
  .my-res-box {
    &.main-hunter {
      position: relative;
      background-color: #ffffff;
      box-shadow: 0px 3px 20px #0000001a;

      &:after {
        content: "";
        background: var(--unnamed-color-ea9b45) 0% 0% no-repeat padding-box;
        background: #EA9B45 0% 0% no-repeat padding-box;
        border-radius: 0px 4px 4px 0px;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        width: 3px;
        height: 100%;
        display: block;
      }
    }
    .cancel-reservation-txt {
      color: #D60000 !important;
    }
  }
}

.course-details {

  //   padding: 20px;
  .details-top {
    display: grid;
    grid-template-columns: 75% 25%;
    justify-content: space-between;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.location p {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item {
  width: 100%;
  padding: 15px 0px;
  // background-color: gray;
  font-size: 15pt;
  text-align: center;
  margin-bottom: 4px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}