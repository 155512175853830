@import "../../../../../assets/scss/_helpers/variables";
.blog {
  padding: 100px 0 50px 0;

  .blog-grid {
    display: grid;
    grid-template-columns: auto 450px;
    grid-gap: 20px;
    @media screen and (max-width: 991px) {
      grid-template-columns: auto;
    }
    img {
      width: 100%;
      height: 465px !important;
      object-fit: cover;
      border-radius: 10px;
    }

    .blog-item {
      position: relative;
      margin-bottom: 20px;
      &.blog-right {
        img {
          height: 220px !important;
          max-height: 100%;
          @media screen and (max-width: 991px) {
            height: unset;
          }
        }
      }
      .layer {
        position: absolute;
        height: 100%;
        width: 100%;
        background: transparent
          linear-gradient(
            181deg,
            #ffffff00 0%,
            #00000000 51%,
            #1f1f1fbd 77%,
            #000000 100%
          )
          0% 0% no-repeat padding-box;
        z-index: 2;
        top: 0;
        border-radius: 10px;
      }
      .blog-bottom {
        position: absolute;
        width: 100%;
        bottom: 5px;
        padding: 0 15px;
        z-index: 3;

        @media screen and (max-width: 380px) {
          padding: 0 5px;
        }
        .heading {
          color: #fff;
          font-size: 18px;
        }
        .blog-detail {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #fff;
          border-top: 1px solid map-get($theme-colors, "fifth");
          padding-top: 8px;

          div {
            display: flex;

            img {
              width: 17px;
              height: auto !important;
              margin-right: 10px;
              object-fit: unset;
              border-radius: unset;
              filter: brightness(100);
            }
          }
        }
      }
    }
  }
}
.blog-tutorial .blog-item .layer.cursor-pointer img {
  width: 60px;
  height: unset !important;
}

@media only screen and (max-width: 991px) {
  .blog .blog-grid img {
    height: 220px !important;
  }
  .blog-page .blog .blog-btm-grid .blog-item {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
