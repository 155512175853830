.blog-page {
  .blog {
    background-color: transparent !important;
    padding: 0 0 30px 0;
    .blog-grid:nth-child(2) {
      grid-template-columns: 450px auto !important;
      @media screen and (max-width: 991px) {
        grid-template-columns: auto !important;
      }
    }
    .blog-top-box {
      display: none !important;
    }
    .blog-btm-grid {
      display: flex;
      flex-wrap: wrap;
      .blog-item {
        position: relative;
        margin-bottom: 20px;
        max-width: 32%;
        height: 220px;
        flex: 0 0 32%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }
}
