@import "assets/scss/_helpers/variables";

.notification-popover {
  max-width: 674px !important;
  width: 100%;
  padding-bottom: 20px;
  box-shadow: 0px 0px 20px #06041c2b;
  border: 0px !important;
  top: 21px !important;
  // max-height: 60%;
  transform: translate3d(-100px, 44px, 0px) !important;
  overflow-y: auto;

  @media (max-width: 991.9px) {
    // right: 5px !important;
    width: 96%;
  }
  @media (max-width: 991.9px) {
    transform: translate3d(-4%, 44px, 0px) !important;
  }
  @media (max-width: 767px) {
    transform: translate3d(-2%, 44px, 0px) !important;
  }

  // inset: 0px 70px auto 0px !important;
  .list-group-item {
    border-bottom: 0px;

    .notification-image {
      max-width: 50px;
    }

    &:hover {
      background-color: map-get($theme-colors, "border");
    }
  }
}

.notification-txt {
  width: calc(100% - 84px);
}

.badge-5 {
  width: 20px;
  height: 20px;
  background-color: #EA9B45;
  position: absolute;
  border-radius: 10px !important;
  right: 0;
  top: -4px;
  left: 15px;
  color: white;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.badge-10 {
  width: 20px;
  height: 20px;
  background-color: #EA9B45;
  position: absolute;
  border-radius: 10px !important;
  right: 0;
  top: -4px;
  left: 15px;
  color: white;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.badge-100 {
  width: 20px;
  height: 20px;
  background-color: #EA9B45;
  position: absolute;
  border-radius: 10px !important;
  right: 0;
  top: -4px;
  left: 15px;
  color: white;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: 10px;
  font-weight: bold;
  padding-top: 2px;

  &:hover {
    cursor: pointer;
  }
}

.badge-1000 {
  width: 20px;
  height: 20px;
  background-color: #EA9B45;
  position: absolute;
  border-radius: 10px !important;
  right: 0;
  top: -8px;
  left: 15px;
  color: white;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: 8px;
  font-weight: bold;
  padding-top: 4px;

  &:hover {
    cursor: pointer;
  }
}

.header-notification {
  display: flex;

  @media (max-width: 991px) {
    // display: none;
  }
}
.mobile-notification-main {
  order: 3;
  display: flex;
  align-items: center;
  
  min-height: 57px;
  @media (max-width: 991.9px) {
    position: absolute;
    top: 5px;
    right: 15px;
  }
}
.mobile-notification {
  display: none;

  @media (max-width: 991px) {
    display: flex;
    // margin-top: 15px;
    // margin-top: 6px;
    margin-top: 0;
    height: 44px;
  }
}

.notification-time {
  font-size: 12px;
}

.readed {
  background-color: #fceee3 !important;
  margin-bottom: 2px;

  &:hover {
    cursor: pointer;
  }
}

.not-readed {
  &:hover {
    cursor: pointer;
  }
}

.scroller {
  &::-webkit-scrollbar {
    width: 8px;
    height: 3px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #A9A9A9;
      border-radius: 6px;
  }
}