@import "../../../../assets/scss/_helpers/variables";
.user-card {
  display: flex;
  justify-content: space-between;
  border: 1px solid #cbcbcb;
  margin-top: 30px;
  padding: 15px 20px;
  border-radius: 7px;
  align-items: center; 
  .user-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    flex: 0 0 100%;
    .dropdown-menu {
      min-width: fit-content;
      text-align: center;
    }
    .user-info-content {
      display: flex;
      align-items: center;
      p {
        &:last-of-type {
          font-size: 14px;
        }
      }

      img {
        height: 45px;
        width: 45px;
        border-radius: 50%;
      }
    }
    .btn-group {
      cursor: pointer;
      &.show {
        color: #ea9b45;
      }
      &:focus,
      &:hover {
        color: #ea9b45;
      }
      .btn {
        padding: 7px 11px;
        // border-radius: 3px !important;
        // -webkit-border-radius: 3px !important;
        // -moz-border-radius: 3px !important;
        // -ms-border-radius: 3px !important;
        // -o-border-radius: 3px !important;
}
    }
    .user-contact {
      a {
        background: #f2f2f2;
        width: 47px;
        height: 47px;
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        line-height: 47px;
      }
      img {
        height: 24px;
        width: 24px;
      }
    }
  }
  .tel {
    display: flex;
    align-items: center;
    justify-content: center;

    i,
    span {
      background: #f2f2f2;
      color: #EA9B45;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;

      img {
        height: 20px;
        width: 20px;
      }
    }
    span {
      margin-right: 7px;
      &.cursor-pointer {
        margin-right: 0;
      }
    }
  }
}
.chat-model {
  .modal-dialog {
    width: 80%;
    max-width: 1000px;
    margin: auto;
    @media (max-width: 550px) {
      width: 95%;
    }
  }
  .modal-content {
    border: none;
    background-color: map-get($theme-colors, "border");
    border-radius: 12px;
    .modal-body {
      padding: 0;
    }
  }
  .close-btn {
    position: absolute;
    top: -40px;
    right: 0;
    background-color: transparent;
    border: none;
    padding: 0;
    .bi {
      font-size: 34px;
      padding: 0;
      line-height: 0;
    }
  }
}
