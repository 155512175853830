.ticket-detail-page {
  font-family: 'Roboto', sans-serif;
  .ticket-title {
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 30px;
    @media (max-width: 767.9px) {
      font-size: 1.3rem;
    }
  }
  .category {
    color: #2c2c2c;
    font-size: .875rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: baseline;
    gap: 6px;
  }

  .separator{
    margin-inline: 20px;
    font-size: .875rem;
  }
  .date {
    color: #2c2c2c;
    font-size: .875rem;
  }
  .date img{
    margin-right: 6px;
  }
  .ticket-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px 30px;
    flex-wrap: wrap;
    font-size: .875rem;
    .profileImg {
      margin-right: 0.5rem;
      width: 40px;
      height: 40px;
      border-radius: 30px;
      object-fit: cover;
      border: 2px solid #fff;
    }
    .name {
      font-weight: 500;
      font-size: 0.9rem;
      margin-right: 0.5rem;
    }

    .ticket-status-resolved {
      color: #DF9C56;
      text-transform: capitalize;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        margin-right: 0.5rem;
        background-color: #DF9C56;
      }
    }
    .ticket-status-pending {
      color: #E9BF45;
      text-transform: capitalize;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        margin-right: 0.5rem;
        background-color: #E9BF45;
      }
    }
    .ticket-status-open {
      color: #5A8DEC;
      text-transform: capitalize;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        margin-right: 0.5rem;
        background-color: #5A8DEC;
      }
    }
  }
  .ticket-detail {
    background-color: #F9F9F9;
    padding: 30px;
    border-radius: 10px;
    .ticket-body {
      font-size: 1rem;
      color: #2C2C2C;
      margin-bottom: 20px;
      line-height: 35px;
    }
    .attachment-btn{
      padding: 11px 20px;
      border: 1px solid #EA9B45;
      color: #EA9B45;
      background-color: transparent;
      border-radius: 5px;
      display: inline-flex;
      gap: 8px;
      align-items: center;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 500;
      line-height: 19px;
    }
  }
  .add-comment-section {
    max-width: 800px;
    margin-left: 31px;
    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
    textarea {
      margin: 50px 0px 20px;
      // resize: none;
      width: 100%;
      border: 1px solid #D3D3D3;
      border-radius: 12px;
      font-size: .9375rem;
      line-height: 20px;
      padding: 20px;
      height: 200px;
      color:#515151;
      &:focus{
        border-color: #D3D3D3 !important;
      }

      @media only screen and (max-width: 1024px) {
        height: auto;
      }
    }
    .add-comment-btn {
      width: auto;
      font-size: 1rem;
      line-height: 24px;
      border-radius: 10px;
      padding: 11px 30px;
    }
  }
  .comments-section {
    max-width: 800px;
    margin-left: 31px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 50px;
    @media only screen and (max-width: 768px) {
        margin-left: 0;
    }
    .comment-section-header {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 500;
      color:#2C2C2C;
    }
    .comments-list {
      list-style-type: none;
      padding: 0%;
      li {
        &:not(:last-child){
          margin-bottom: 20px;
        }
        .comment{
          display: flex;
          gap: 9px;
          .comment-profile-pic img{
            width: 45px;
            height: 45px;
            border-radius: 30px;
            object-fit: cover;
          }
          .comment-detail{
            display: inline-flex;
            flex-grow: 1;
            width: 100%;
            flex-direction: column;
            .comment-info{
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              .comment-user-name{
                color: #2C2C2C;
                font-size: 1rem;
                line-height: 25px;
                font-weight: 500;
                font-family: 'Roboto', sans-serif;
                margin: 0;
              }
              .comment-date {
                min-width: 150px;
                text-align: right;
                margin-right: 10px;
                .time-icon {
                  margin-right: 0.3rem;
                }
              }
            }
            .comment-body{
              margin-top: 10px;
              background-color: #F5F7FA;
              color: #2C2C2C;
              font-size: .875rem;
              line-height: 20px;
              padding: 20px;
              border-radius: 12px;
              p{
                margin: 0;
                word-break: break-all;
              }
              &.orange{
                background-color: #FEF4E8;
              }
            }
          }
        }
      }
    }
  }
  .read-more {
    margin-left: 5px;
    cursor: pointer;
    font: .875rem Roboto, Sans-serif;
    color: #ea9b45;
  }
}
