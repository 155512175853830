.cintainer-div {
    text-align: center;
    p{
        color: #6e6f70;
    }
    span{
        color: #274d74;
        &:hover {
        cursor: pointer;
        }
    }
}
.title-message {
    font-size: 18px;
    color: #6e6f70;
    margin-bottom: 10px !important;

}

.form-div {
  margin: auto;
  border: 1px solid #ced4da;
  padding: 5px;
  border-radius: 5px;
}

.email-field {
  width: 300px !important;
  margin: auto !important;
}


