@import "../../../../assets/scss/_helpers/variables";

.feedback {
  padding: 100px 0;
  h2 {
    color: map-get($theme-colors, "secondary");
    @media (min-width: 1200px) {
      font-size: 50px;
    }
  }

  .feedback-slider {
    // padding: 150px 0 70px 0;
    // max-width: 80%;
    // margin: auto;
    .item {
      display: grid;
      align-items: center;
      grid-template-columns: 210px auto;
      padding: 150px 100px 70px 100px;
      @media screen and (max-width: 991px) {
        padding: 150px 0px 70px 60px;
      }
      @media screen and (max-width: 767px) {
        grid-template-columns: auto;
        padding: 150px 0px 70px 0px;
      }

      .feedback-img {
        max-width: 230px;
        position: relative;

        @media screen and (max-width: 767px) {
          margin: auto;
          width: 180px;
        }

        .person {
          width: 230px;
          height: 230px;
          border-radius: 100%;
          position: relative;
          z-index: 3;
          @media screen and (max-width:767px){
          height: 180px;
          width: 180px;
          }
        }

        .horns {
          position: absolute;
          top: -122px;
          left: 50%;
          transform: translate(-50%);
          width: 320px;
          z-index: 2;

          @media screen and (max-width: 375px) {
            width: 250px;
          }
        }
      }
      .feedback-detail {
        margin-left: 50px;

        @media screen and (max-width: 767px) {
          margin-left: 0;
          margin-top: 40px;
        }

        .quote {
          font-size: 100px;
          line-height: 0;
          color: map-get($theme-colors, "secondary");
        }
        p {
          color: map-get($theme-colors, "tertiary");
          @media (min-width: 1200px) {
            font-size: 20px;
          }
        }
        h4 {
          color: map-get($theme-colors, "secondary");
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .designation {
          font-size: 12px;
        }
      }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets {
      // bottom: 60px;
      //  width: 200px;
      //left: 35%;
      @media (min-width: 1400px) {
        width: 100%;
        //  left: -14.7%;
      }

      // @media screen and (max-width: 1399px){
      //   left: 41%;
      // }
      // @media screen and (max-width: 1199px){
      //   left: 44%;
      // }
      // @media screen and (max-width: 991px){
      //   left: 49%;
      // }
      // @media screen and (max-width: 767px){
      //   bottom: 15px;
      //   width: 100%;
      //   left: auto;
      // }
      .swiper-pagination-bullet {
        width: 27px;
        height: 4px;
        display: inline-block;
        border-radius: 100px;

        &.swiper-pagination-bullet-active {
          width: 40px;
          background-color: #2c2c2c;
        }
      }
    }
  }
}
