.pending-state-notification-modal {
    .modal-dialog {
        width: 328px;
        margin-right: auto !important;
        margin-left: auto !important;
        .modal-header {
            padding: 0;
            border-bottom: 0;

            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 99;
            }
        }

        .modal-body {
            padding: 30px;
            position: relative;

        }

        .modal-content {
            border-radius: 12px;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            -ms-border-radius: 12px;
            -o-border-radius: 12px;
        }
    }

    .content {
        .location {
            img {
                width: 10px;
                margin-right: 3px;
                margin-top: 2px;
            }

            p {
                font-size: 12px;
                opacity: .5;
            }
        }

        .price {
            text-align: center;
            border-bottom: 1px solid #CBCBCB;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;

            span {
                font-size: 12px;
                color: #2C2C2C !important;
                font-weight: 500;
            }
        }

        .email {
            text-align: center;

            span {
                font-size: 12px;
                color: #2C2C2C;
                font-weight: 500;

                &:first-child {
                    color: #696969;
                    font-weight: 400;
                    padding-right: 4px;
                }
            }
        }

        &.accepted {
            .img {
                margin-top: -70px;

                img {
                    border-color: #1EC42F;
                }

                figcaption {
                    color: #1EC42F;

                    i.dot {
                        &::before {
                            content: "";
                            background-color: #1EC42F;
                        }
                    }
                }
            }

            .message {
                color: #1EC42F;
            }
        }

        &.declined {
            .img {
                margin-top: -70px;

                img {
                    border-color: #CB2027;
                }

                figcaption {
                    color: #CB2027;

                    i.dot {
                        &::before {
                            content: "";
                            background-color: #CB2027;
                        }
                    }
                }
            }

            .message {
                color: #CB2027;
            }
        }

        &.expired,
        &.revoked,
        &.cancelled,
        &.revoked-by-admin {
            .img {
                margin-top: -70px;

                img {
                    border-color: #CB2027;
                }

                figcaption {
                    color: #CB2027;

                    i.dot {
                        &::before {
                            content: "";
                            background-color: #CB2027;
                        }
                    }
                }
            }

            .message {
                color: #CB2027;
            }
        }

        &.pending {
            margin-top: -70px;

            .img {
                img {
                    border-color: #FFBE00;
                }

                figcaption {
                    color: #FFBE00;

                    i.dot {
                        &::before {
                            content: "";
                            background-color: #FFBE00;
                        }
                    }
                }
            }

            .message {
                color: #FFBE00;
            }
        }
    }

    .img {
        display: flex;
        width: fit-content;
        height: auto;
        justify-content: center;
        align-items: center;
        margin: 0 auto 5px auto;
        flex-direction: column;

        img {
            width: 126px;
            height: 126px;
            object-fit: cover;
            border-radius: 100%;
            border: 6px solid;
            margin-bottom: 5px;
        }

        figcaption {
            font-size: 12px;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;

            i.dot {
                font-size: 30px;
                display: inline-flex;
                position: relative;

                &::before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    background-color: #000;
                    position: absolute;
                    top: -2px;
                    left: -10px;
                    line-height: initial;
                    display: inline-flex;
                    font-size: 22px;
                    border-radius: 100%;
                }
            }
        }
    }

    .message {
        font-size: 12px;
        text-align: center;
        margin-bottom: 0;
        padding-top: 10px;
    }

    h6 {
        font-size: 15px;
        font-weight: 500;
        color: #2C2C2C;
        font-family: 'Roboto';
        text-align: center;
        margin-bottom: 0;
    }
}

.font-13 {
    font-size: 13px !important;
}