.banner {
  background-color: #000000;
  margin: 50px 0;
  h2{
    font-size: 60px;
  }
  .mbl-pb-200{
    @media screen and (max-width: 991px) {
      padding-bottom: 200px;
    }
  }
  .inner-container {
    padding-top: 70px;
    padding-bottom: 50px;

    p {
      color: #fff;
    }
    .subtitle {
      color: #cbcbcb;
      font-size: 21px;
    }
    .quote {
      font-size: 100px;
      line-height: 0;
    }
    .verse-txt{
      font-size: 20px;
    }
    .ref-verse{
      font-family: Minionpro;
      font-size: 20px;
    }
    .img-section {
      div {
        position: absolute;
        bottom: 0;
        right: 0;

        // @media screen and (max-width: 1275px) {
        //   top: 13px;
        // }
        // @media screen and (max-width: 1200px) {
        //     top: 28px;
        //   }

        // @media screen and (max-width: 1050px) {
        //     top: 69px;
        // }
        // @media screen and (max-width: 1050px) {
        //     display: none;
        // }

        img {
          max-width: 638px;
          @media only screen and (max-width: 1700px) and (min-width:1276px) {
            max-width: 560px;
          }
          @media screen and (max-width: 1275px) {
            max-width: 560px;
          }

          @media screen and (max-width: 1050px) {
            max-width: 300px;
          }
        }
      }
    }
  }
}
