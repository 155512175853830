@import "../../../../assets/scss/_helpers/variables";
 
.followers-grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(auto , 230px ));
    grid-gap: 20px;
    @media screen and (max-width: 1400px) {
        grid-template-columns: repeat(4, minmax(auto , 230px ));
    }
    @media screen and (max-width:991px) {
        grid-template-columns: repeat(3, minmax(auto , 230px ));
    }
    @media screen and (max-width:767px) {
        grid-template-columns: repeat(2, minmax(auto , 220px ));
    }
    @media screen and (max-width:575px) {
        grid-template-columns: repeat(1, minmax(auto , 220px ));
    }
    .follower-card {
        display: inline-flex;
        align-items: center;
        border: 1px solid #CBCBCB;
        border-radius: 8px;
        padding: 10px 20px;
        justify-content: center;
        word-break: break-word;
        font-size: 18px;
        font-weight: 500;
        // min-width: 230px;
        // max-width: 230px;
        .image {
            margin-right: 12px;
            img {
                height: 48px;
                width: 48px;
                border-radius: 50%;
            }
        }
    }
}




