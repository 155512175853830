@font-face {
  font-family: Minionpro;
  src: url("../../fonts/MinionPro/MinionPro-Regular.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

a,
li,
span,
p,
button {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Minionpro;
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 4.375rem !important;
  }

  h2,
  .h2 {
    font-size: 3.125rem !important;
  }
}

label {
  color: map-get($theme-colors, "tertiary");
  font: normal normal normal 12px/20px Roboto;
  padding-left: 12px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.container {
  padding-right: 30px;
  padding-left: 30px;
}

.inner-container {
  max-width: 100%;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;

  @media only screen and (min-width: 575.9px) {
    max-width: 540px;

    padding-right: 30px;
    padding-left: 30px;
  }

  @media only screen and (min-width: 768px) {
    max-width: 720px;
  }

  @media only screen and (min-width: 992px) {
    max-width: 900px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 950px;
  }

  @media only screen and (min-width: 1400px) {
    max-width: 1374px;
  }
}

.checked {
  color: #fec156;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.border-h-2 {
  height: $border-height;
}

.junior-hunter-header {
  position: relative;

  .junior-hunter-header-inner {
    span {
      width: 102px;
      height: 45px;
      margin: 0 auto;
      display: flex;
      position: absolute;
      left: calc(50% - 52px);
      bottom: -41px;
      z-index: 999;
      cursor: pointer;
    }

    &::after {
      content: "";
      height: 4px;
      background: #EA9B45 0% 0% no-repeat padding-box;
      opacity: 1;
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    &::before {
      content: "";
      width: 102px;
      height: 41px;
      opacity: 1;
      position: absolute;
      bottom: 0;
      transform: translate(-50%, 100%);
      left: 50%;

      background: transparent url(../../icons/junio-icon.png) 0% 0% no-repeat padding-box;
    }
  }
}

.dark-header {
  // background-color: #000000e8;
  position: relative;

  // @media screen and (max-width: 991px) {
  //   background-color: #000;
  // }
}

header {
  .navbar-collapse {
    .navbar-nav {
      @media screen and (max-width: 991px) {
        padding: 10px 10px;
        // position: absolute;
        // top: 54px;
        // background: black;
        // width: 100%;
        // left: 0;
      }
    }

    .navbar-nav {
      .nav-link {
        @media screen and (max-width: 991px) {
          font-size: 18px;
        }

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }

  .user-name {
    margin-bottom: 0;
    color: #fff;
    font-weight: 400;
    min-width: 72px;
    display: -webkit-box;
    max-width: 120px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (max-width: 575.9px) {
      max-width: 72px;
    }
  }

}

.white-header {
  background-color: #ffffff;
  z-index: 1011;
  transition: all .3s ease;
  box-shadow: 0 0 10px 0 #ddddd5;
  border-bottom: 1px solid #ddddd5;

  header {
    .navbar-dark {
      .navbar-nav {
        .nav-link {
          color: #000000;
          padding: 10px 32px;
          font-size: 1rem;
          margin-right: 15px;
          border-radius: 6px;

          &.active,
          &:hover {
            color: #ffffff;
            background-color: #EA9B45;
            border-color: #EA9B45;
          }
        }
      }
    }

    .user-name {
      color: #000;
    }
  }
}
.header-below-strip {
  background-color: #ea9b45;
  padding: 11px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 20px;
    line-height: 28px;
    color: #000;
    margin-bottom: 0;
    @media (max-width: 767.9px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.popover {
  position: fixed !important;
}

.header-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  transition: all .3s ease;
  box-shadow: 0 0 10px 0 #ddddd5;
  border-bottom: 1px solid #ddddd5;
}

.border-padding {
  margin-top: 25px;
  margin-bottom: 25px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 80px 0;

  @media (max-width: 767px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }

  select {
    border: 1px solid map-get($theme-colors, "fifth");
    color: #000;
    padding: 4px 10px;
    border-radius: 4px;
    margin-right: 20px;
  }

  .pagination {
    margin-bottom: 0;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  li {
    display: none;
    border: 1px solid map-get($theme-colors, "fifth");
    height: 30px;
    width: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &.next {
      display: flex;
      margin-left: 15px;
    }

    &.previous {
      display: flex;
    }

    .bi {
      color: #000;
      font-size: 14px;
      padding: 10px;
      font-weight: 600;
    }
  }
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.fs-7 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.full-w {
  width: 100%;
}

.title-fs-50 {
  @media (min-width: 1200px) {
    font-size: 50px;
  }
}

.fw-300 {
  font-weight: 300;
}

.bg-tertiary {
  background-color: map-get($theme-colors, "tertiary");
}

.border-0 {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #fff !important;
}

.cancel-txt {
  color: #D60000;
}

.pending-txt {
  color: #E9BF45;
}

.approved-txt {
  color: #DF9C56;
}

.completed-txt {
  color: #27AB34;
}

.oneline-text {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.twoline-text {
  @extend .oneline-text;
  -webkit-line-clamp: 2;
}

.mw-12 {
  max-width: 12px;
}

.fw-600 {
  font-weight: 600 !important;
}

.arrow-danger {
  color: #cb2027;
}

textarea:focus,
.form-select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: map-get($theme-colors, "tertiary") !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px map-get($theme-colors, "tertiary");
  outline: 0 none;
}

.map {
  width: 100%;
}

.w-99 {
  width: 92px !important;
}

.react-calendar__tile:disabled {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
  background-color: transparent;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent;
}

.react-calendar__month-view__weekdays {
  font-size: 12px;
  color: #383838;
  background-color: #fafcfe;
  padding: 12px 0;
}

.react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none;
}

.react-calendar {
  border-radius: 12px;
}

.react-calendar__tile {
  padding: 5px 5px;
}

.react-calendar__tile abbr {
  font-size: 14px;
}

.react-calendar__tile p {
  font-size: 15px;
}

.react-calendar__tile:disabled abbr {
  color: #e8e8e8;
}

.react-calendar__tile:disabled p {
  color: #e8e8e8 !important;
}

.react-calendar__tile--now:enabled:hover {
  background: #fff;
}

.react-calendar__tile--now p {
  color: #fff !important;
}

// .react-calendar__month-view__days__day--weekend p{
//   color: #EA9B45 !important;
// }
// .react-calendar__month-view__days__day--weekend abbr {
//   color: #EA9B45 !important;
// }
// .react-calendar__month-view__days__day--weekend p {
//   color: #EA9B45 !important;
// }
.react-calendar__navigation {
  margin-bottom: 0;
}

.react-calendar__month-view {
  padding-bottom: 20px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: none;
}

.react-calendar__tile.react-calendar__month-view__days__day p {
  color: #928f8f;

}

.react-calendar__month-view__days__day--weekend {
  color: #000000;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day p {
  color: #928f8f !important;
}

.react-calendar__tile.react-calendar__tile--now:disabled.react-calendar__month-view__days__day p {
  color: #e8e8e8 !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  background-color: #ffffff;
  color: #383838;
  border-radius: 4px;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  background-color: #ffffff;
  color: #383838;
  border-radius: 4px;
}

.react-calendar__tile p {
  margin-bottom: 5px;

}

.react-calendar {
  width: 428px;
  margin-top: 5px;
}

.react-calendar__tile--now {
  background-color: #fff;
}

.react-calendar__tile--active {
  background: #FBECDC;

}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
  background-color: unset;
}
// .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
//   background: #EA9B45;
//   opacity: 10%;
// }
// p {
//   color: $paragraph-color;
// }

// .btn {
//   &:hover {
//     color: #fff;
//   }
// }

// :root {
//   --swiper-navigation-color: map-get($theme-colors, "tertiary");
//   --swiper-navigation-size: 30px;
// }

// .container {
//   padding-right: 30px;
//   padding-left: 30px;
// }

// .inner-container {
//   max-width: 100%;
//   margin: auto;
//   padding-right: 30px;
//   padding-left: 30px;
//   margin-right: auto;

//   @media only screen and (min-width: 575.9px) {
//     max-width: 540px;
//   }

//   @media only screen and (min-width: 768px) {
//     max-width: 720px;
//   }

//   @media only screen and (min-width: 992px) {
//     max-width: 900px;
//   }

//   @media only screen and (min-width: 1200px) {
//     max-width: 950px;
//   }

//   @media only screen and (min-width: 1400px) {
//     max-width: 1100px;
//   }
// }

// .bg-card {
//   position: relative;
//   text-align: center;

//   &::after {
//     width: 400px;
//     height: 400px;
//     background-color: $primary-card;
//     position: absolute;
//     transform: matrix(0.79, -0.62, 0.62, 0.79, 0, 0);
//     border-radius: 80px;
//     content: "";
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%) matrix(0.79, -0.62, 0.62, 0.79, 0, 0);
//     z-index: 1;
//   }

//   &.yellow {
//     &::after {
//       background-color: $secondary-card;
//     }
//   }

//   &.blue {
//     &::after {
//       background-color: $tertiary-card;
//     }
//   }

//   &.red {
//     &::after {
//       background-color: $fourth-card;
//     }
//   }

//   &.blue {
//     &::after {
//       background-color: $fifth-card;
//     }
//   }
// }

// .features {
//   grid-template-columns: auto auto auto;

//   @media only screen and (max-width: 768px) {
//     grid-template-columns: auto;
//     justify-content: center;
//   }

//   .fa {
//     color: map-get($theme-colors, "fourth");
//     font-size: 10px;
//   }

//   .features-list {
//     display: flex;
//     align-items: baseline;
//     text-align: left;
//   }
// }

// .icon-features {
//   display: grid;
//   grid-template-columns: auto auto auto auto auto;
//   justify-content: space-between;
//   text-align: center;
//   align-items: center;

//   @media only screen and (max-width: 550px) {
//     grid-template-columns: auto auto;
//     justify-content: space-between;
//   }

//   img {
//     width: 45px;
//     height: 45px;
//   }

//   p {
//     margin-top: 10px;
//     font-weight: 600;
//   }
// }

// .swiper-horizontal > .swiper-pagination-bullets,
// .swiper-pagination-bullets.swiper-pagination-horizontal {
//   bottom: 85px;
//   left: 64%;
//   transform: translate(-65%);
//   width: unset;

//   @media screen and (max-width: 768px) {
//     left: 0;
//     width: 100%;
//     bottom: 0;
//     transform: unset;
//   }
// }

// .swiper-pagination-bullet {
//   width: 25px;
//   border-radius: 18px;
//   height: 4px;
//   background: #fb8500;
// }

// .swiper-pagination-bullet-active {
//   width: 50px;
// }

.swiper-button-next,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: #000;
}

// a {
//   white-space: nowrap;
//   text-decoration: none;
// }

// .top-space {
//   padding-top: 60px;
// }

// .bottom-space {
//   padding-bottom: 60px;
// }

.cursor-pointer {
  cursor: pointer;
}

.txt-secondary {
  color: map-get($theme-colors, "secondary");
}

.txt-seventh {
  color: map-get($theme-colors, "seventh");
}

.hide {
  display: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}


span.info-icon {
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.intl-tel-input {
  border: 1px solid map-get($theme-colors, "tertiary");
  height: 48px !important;
  width: 290px;
  border-radius: 10px;

  &:hover,
  &:focus {
    border-radius: 10px 0 0 10px;
  }

  @media screen and (max-width: 383px) {
    width: 280px;
  }

  @media screen and (max-width: 320px) {
    width: 226px;
  }
}

.phone-input-container {
  border: none;

  &.react-tel-input .flag-dropdown.open .selected-flag,
  &.react-tel-input .flag-dropdown.invalid-number,
  &.react-tel-input .flag-dropdown.open {
    border-radius: 10px 0 0 10px !important;
  }

  .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #928f8f;
    border-radius: 10px 0 0 10px;

    .selected-flag {
      border-radius: 10px 0 0 10px;

      &:hover,
      &:focus {
        border-radius: 10px 0 0 10px;
      }
    }

  }

  .country-list {
    width: 290px !important;
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.35);
  }
}

input[type=number] {
  -moz-appearance: textfield;
}