@import "../../../../assets/scss/_helpers/variables";

.d-profile-middle-content {
  display: flex;
  justify-content: center;
  // align-items: center;
  height: calc(100% - 54px);
  position: relative;

  >div {
    max-width: 324px;
    width: 100%;
    padding: 1rem;
  }

  .p-user-details {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    position: relative;
    flex-wrap: wrap;
    gap: 15px;
    img.profile-pic {
      border: 1px solid map-get($theme-colors, "primary");
      border-radius: 40px;
      margin-right: 1rem;
      width: 65px;
      height: 65px;
      max-width: 65px;
      object-fit: cover;
    }

    img.upload-icon {
      border: 1px solid map-get($theme-colors, "primary");
      border-radius: 40px;
      // margin-right: 1rem;
      width: 100%;
      height: 65px;
      max-width: 65px;
      object-fit: cover;
    }

    .close-btn {
      position: absolute;
      left: 38px;
      bottom: -8px;

      img {
        width: 40px;
      }
    }

    .p-user-data {
      .p-user-name {
        font-size: 18px;
        line-height: 16px;
        position: relative;
        width: fit-content;

        img {
          position: absolute;
          width: 30px;
          height: 30px;
          top: -13px;
          right: -32px;
        }
      }

      small {
        color: map-get($theme-colors, "tertiary");
        font-size: 14px;
        line-height: 8px;
      }

      .blog-checkbox {
        .toggle-switch {
          width: 36px;
          height: 18px;

          &::before {
            width: 10px;
            height: 10px;
          }

        }

        .toggle-checkbox:checked+.toggle-switch:before {
          left: 22px;
        }
      }
    }
  }

  form {
    button {
      font-size: 15px;
    }

    .form-floating {
      margin-top: 10px;

      input {
        border: 1px solid map-get($theme-colors, "tertiary");
        height: 48px;
        border-radius: 10px;
        padding-top: 20px !important;
      }

      .input-icon {
        position: absolute;
        right: 14px;
        top: 12px;
        cursor: pointer;
      }

      label {
        padding-top: 12px;
      }
    }


    .span-container {
      color: map-get($theme-colors, "tertiary");
      font-size: 14px;
    }
  }

  .react-tel-input {
    .form-control {
      height: 45px;
      width: 100%;
      border: 1px solid #928f8f !important;
      border-radius: 0.5rem;
    }
  }

  .phone-input-container.react-tel-input .flag-dropdown.open .selected-flag {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.35);
  }
}

.d-content-inner-title {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}
.referral-code {
  background-color: #EA9B4533;
    border: none;
    border-radius: 3px;
    padding: 2px 10px;
    margin-top: 4px;
    // margin-left: 1rem;
    position: relative;
    // left: 65px;
  span {
    font-size: 12px;
    line-height: 8px;
    color: #EA9B45;
    span {
      padding-left: 2px;
    }
  }
}
.react-calendar__month-view__weekdays__weekday abbr[title="Sunday"],
  abbr[title="Saturday"] {
    color: #EA9B45;
  }
.profile-detail-calender {
  .birth-date {
    background-color: #eeaf6a !important;
    color: #fff !important;
  }
}