.invitation-code-modal {
  .modal-content {
    border-radius: 9px;
  }
    .modal-header {
        flex-direction: column-reverse;
        align-items: center;
        padding: 60px 60px 0 60px;
        border-bottom: 0;
        @media (max-width: 575.9px) {
          padding: 30px 30px 0 30px;
        }
        .btn-close {
          position: absolute;
          top: 60px;
          right: 60px;
          width: 17.7px;
          height: 17.7px;
          padding: 0;
          opacity: 1;
          @media (max-width: 575.9px) {
            top: 30px;
            right: 30px;
          }
        }
        .modal-title {
          font-size: 32px;
          line-height: 32px;
          margin-top: 0;
          color: #2C2C2C;
          padding: 0 15px;
          text-align: center;
          font-family: 'Minion Pro';
          font-weight: 400;
          @media (max-width: 575.9px) {
            font-size: 22px;
          }
        }
        p {
          font-size: 16px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 0;
          text-align: center;
          margin-top: 6px;
            a {
                font-size: 16px;
                line-height: 20px;
                color: #EA9B45;
            }
            @media (max-width: 575.9px) {
              font-size: 14px;
              list-style: 18px;
              a {
                font-size: 14px;
                list-style: 18px; 
              }
            }
        }
    }
    .modal-title {
      margin-bottom: 0.5rem;
    }
    .modal-body {
      padding: 20px 60px 60px 60px;
      @media (max-width: 575.9px) {
        padding: 10px 30px 30px 30px;
      }
      .form-box {
        padding: 0;
        @media (max-width: 575.9px) {
          margin-bottom: 10px;
        }
        input {
          border-radius: 10px;
          height: 45px;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 400;
          width: 100% !important;
        }
    
        label {
          padding: 12px 10px;
        }
    
        .form-floating>.form-control:not(:placeholder-shown) {
          padding-top: 20px;
        }
    
        .input-icon {
          position: absolute;
          right: 9px;
          top: 7px;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
        .form-box {
          padding: 0;
          @media (max-width: 575.9px) {
            margin-bottom: 10px;
          }
          &.invitation-code-box {
            .image {
              img {
                border-radius: 0;
                object-fit: contain;
                width: auto;
                height: 230px;
              }
            }
            .invitation-pin {
              display: flex;
              gap: 10px;
              flex-direction: column;
              label {
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0px;
                color: #928F8F;
                opacity: 1;
                padding: 0;
                margin-top: 30px;
              }
              .pin {
                display: flex;
                gap: 10px;
                input {
                  background-color: transparent;
                  border-top: 0;
                  border-left: 0;
                  border-right: 0;
                  border-radius: 0;
                  border-bottom: 1px solid #CBCBCB;
                  text-align: center;
                  font-size: 20px;
                  line-height: 20px;
                  letter-spacing: 0px;
                  color: #2C2C2C;
                  opacity: 1;
                  font-weight: 500;
                  margin-bottom: 0;
                }
              }
              p {
                text-align: center;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0px;
                color: #928F8F;
                opacity: 1;
                margin-top: 20px;
                margin-bottom: 0;
              }
            }
            .btns {
              display: flex;
              gap: 10px;
              justify-content: space-between;
              align-items: center;
              button {
                flex-grow: 1;
                margin-top: 20px;
                text-transform: uppercase;
                height: 44px;
                // &:first-child {
                //   border-color: #515151 !important;
                //   background-color: transparent;
                //   color: #2C2C2C !important;
                // }
              }
            }
            .login-text {
              display: flex;
              margin-top: 10px;
              justify-content: center;
              a {
                color: #000000;
                font-weight: 600;
                padding-left: 3px;
              }
            }
          }
        }
    }
}
.h-44 {
  height: 44px !important;
}