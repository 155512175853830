@import "assets/scss/_helpers/variables";

.contact-box{
    border: 1px solid map-get($theme-colors, "fifth");
    border-radius: 16px;
    padding: 30px 48px;
    max-width: 480px;
    @media (max-width:991px) {
        padding: 30px 30px;
    }
    .contact-formfeild{
        border: 1px solid map-get($theme-colors, "fifth");
        border-radius: 10px;
        padding: 11px 20px;
        background-color: #fff;
        width: 100%;
    }
    label{
        font-size: 16px;
        font-weight: 500;
        padding-left: 0;
        margin-bottom: 12px;
        color: map-get($theme-colors, "secondary");
    }
    textarea{
        height: 155px;
    }
    .formGroup{
        margin-bottom: 22px;
    }
}
.contact-right-content{
    @media (max-width:767px) {
        margin-top: 3rem !important;
    }
    .d-flex.text-secondary {
        max-width: 356px;
        margin-bottom: 1rem;
    }
    @media (min-width:768px) {
        h3{
            font-size: 32px !important;
        }
    }
}