@import "../../../../../assets/scss/_helpers/variables";
.stands {
  .pagination {
    justify-content: end;
  }
  .active {
    color: white;
    min-width: 20px;
    justify-content: center;
    .slide-image {
      border: 2px solid #ea9b45;
    }
  }
  .active-img {
    color: white;
    min-width: 20px;
    justify-content: center;
    border: 2px solid #ea9b45;
  }
}
.toggle-listing-map-btn {
  &.action-btn-2 {
    .action-button {
      .dropdown-toggle {
        font-size: 18px !important;
        background: transparent !important;
        border: 1px solid #EA9B45 !important;
        padding: 9px 15px;
        width: 40px !important;
        height: 40px !important;
        &:focus,
        &:hover {
          background-color: transparent !important;
          color: #EA9B45 !important;
          border-color: #EA9B45;
          &::after {
            color: #EA9B45 !important;
          }
        }
        @media (max-width: 575.9px) {
          width: 36px !important;
          height: 36px !important;
          padding: 0 !important;
          &::after {
            font-size: 24px;
          }
        }
      }
    }
    .dropdown-menu {
      box-shadow: 0px 5px 14px #00000033;
      border: 1px solid #D4D4D4 !important;
      border-radius: 5px;
      padding: 0;
      .dropdown-item {
          color: #000000;
          font-size: 14px;
          padding: 6px 14px !important;
          line-height: 1.3;

          &:active,
          &:focus,
          &:hover{
            background-color: #E9ECEF;
            color: #4B4D51;
          }
      }
    }
    
    button {
      font-size: 18px !important;
      background: transparent;
      border: 1px solid #EA9B45;
      padding: 9px 15px;
      width: 150px !important;
      i {
        font-size: 18px !important;
        margin-left: 10px !important;
      }
    }
  }
}
.no-record {
  margin-top: 10px;
  border: 1px solid red;
}

.filter-wrapper {
  text-align: right;
  position: absolute;
  right: 0;
  top: -10px;
  .filter-btn {
    position: relative;
    display: inline-block;
    select {
      color: #000;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
    select {
      border: 1px solid #f2f2f2;
      padding: 9px 15px 9px 40px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      font-size: 18px;
      border-radius: 8px;
    }
  }
}

@import "../../../../../assets/scss/_helpers/variables";
.my-land {
  // padding: 20px;
  .tabs {
    .nav-tabs {
      justify-content: left;
      margin-right: 176px;
      .nav-item {
        border-bottom: none;
        padding: 0;
        margin-right: 25px;
        button {
          @media(max-width: 450px){
            text-align: left;
          }
        }
        .nav-link {
          padding: 0;
          color: map-get($theme-colors, "tertiary");
          font-weight: 300;
          margin-bottom: 8px;
          border: 0;
          &.active {
            font-weight: 400;
            color: map-get($theme-colors, "seventh");
            border-bottom: 2px solid map-get($theme-colors, "primary") !important;
            margin-bottom: 8px;
            border: 0;
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
}
