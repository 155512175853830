@import "../../../../assets/scss/_helpers/variables";

.action-bar {
    &.d-flex {
        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    .create-post {
        color: map-get($theme-colors, "primary");
        text-decoration: unset;
        background: transparent;
        border: 0;
        padding: 0;
        transition: .45s all ease-in-out;

        &:hover {
            opacity: .75;
        }

        svg {
            margin-right: 8px;
        }
    }

    .blog-checkbox {
        @media screen and (max-width: 767px) {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 10px;
        }
    }
}

create-btn {
    border-radius: 10px;
    font-size: 18px;
    background-color: #EA9B45;
    border: 0;
    color: #F5F9FF;
    padding: 18px 40px !important;
    margin-inline: auto;
    line-height: 1.25;
    max-width: fit-content !important;
    margin-top: 34px !important;

    &:hover,
    &:active,
    &:focus {
        background-color: #edaa61;
        color: #F5F9FF;
    }

    @media only screen and (max-width:768px) {
        padding: 12px 30px !important;
        font-size: 1rem;
        margin-top: 17px !important;
    }
}

.no-blog-main {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    &.my-5rem {
        margin-bottom: 5rem;
    }

    .no-blog-img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            height: 115px;
            object-fit: contain;
            margin-bottom: 20px;

            @media screen and (max-width: 767px) {
                height: 65.68px;
            }
        }

        .no-blog-text {
            color: #959595;
            font-size: 19px;
            font-weight: 500;
            margin-bottom: 0;

            @media (max-width: 767.9px) {
                font-size: 14px;
            }
        }

    }

    .no-blog-button {
        background-color: #ea9b45;
        color: #fff;
        cursor: pointer;
        text-decoration: unset;
        font-size: 18px;
        padding: 14.5px 40px;
        border-radius: 10px;
        opacity: 1;
        border: 1px solid;
        margin-top: 15px;

        @media (max-width: 767.9px) {
            font-size: 14px;
            padding: 7.4px 20px;
        }
    }
}

.main-blog {
    margin-bottom: 30px;
    border: 1px solid #FEDFBE;
    border-radius: 10px;
    .thumbslider {
        margin-bottom: 20px;
    }

    .main-blog-topbar {
        padding-inline: 20px;
        .request-for-approval-bar{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
            border-bottom: 1px solid #707070;
            padding-block: 30px;

            @media only screen and (max-width:1399px) {
                justify-content: center;
            }

            p{
                font-size: 20px;
                font-weight: 500;
                margin: 0;
            }
            .request-action-btns{
                display: flex;
                gap: 10px;
                .request-action-btn{
                    padding: 12px 40px;
                    border-radius: 10px;
                    border: 1px solid transparent;
                    font-size: 14px;
                    &:hover{
                        opacity: .8;
                    }
                    @media screen and (max-width: 991px) {
                        padding: 8px 30px;    
                    }

                    &.approve{
                        background-color: #EA9B45;
                        border-color: #EA9B45;
                        color: #F5F9FF;
                    }
                    &.reject{
                        background-color: #FFFFFF;
                        border-color: #CB2027;
                        color: #CB2027;
                    }
                }

                
            }
        }   
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin: 0;
        &.request-for-approval{
            background: transparent linear-gradient(180deg, #FBEBDA 0%, #FBEBDA33 100%) 0% 0% no-repeat padding-box;
        }
        @media screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    .blog-title {
        vertical-align: middle;
        font-size: 35px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        word-break: break-word;

        .blog-status {
            margin-top: 2px;
            font-size: 14px;
            position: relative;
            padding-inline-start: 10px;
            text-transform: capitalize;
            
            &::before {
                position: absolute;
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: currentColor;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
    
            &.pending {
                color: #FFBE00;
            }
    
            &.approved {
                color: #EA9B45;
            }
    
            &.rejected {
                color: #D60000;
            }
        }
        
        @media screen and (max-width: 1200px) {
            font-size: 28px;
        }

        @media screen and (max-width: 991px) {
            font-size: 25px;
        }

        @media screen and (max-width: 575px) {
            font-size: 23px;
        }

        .date {
            border: 1px solid #928F8F;
            width: 76px;
            height: 76px;
            padding: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex: 0 0 76px;
            color: #2C2C2C;
            font-size: 12px;
            opacity: 1;
            vertical-align: middle;
            margin-right: 20px;
            text-align: center;
            background-color: #ffffff;
            @media screen and (max-width: 991px) {
                margin-right: 12px;
            }

            label {
                font-size: 25px;
                font-weight: 600;
                color: #2C2C2C;
                margin-bottom: 5px;
                padding: 0;
            }
        }
    }

    .image {
        margin-bottom: 20px;

        img {
            max-width: 100%;
        }
    }

    .desc {
        margin-bottom: 0;
        padding: 0 20px 10px;
        transition: max-height 0.5s ease, opacity 0.5s ease;

        .read-more {
            margin-left: 5px;
        }

        p {
            margin-bottom: 0;
            word-break: break-word;
            transition: max-height 0.5s ease, opacity 0.5s ease;
        }

        .read-more {
            cursor: pointer;
            font: .875rem Roboto, Sans-serif;
            color: #ea9b45;
        }
    }

    .action-btns {
        display: flex;
        margin-left: 15px;

        @media screen and (max-width: 991px) {
            margin-left: 0px;
            margin-top: 15px;
            width: 100%;
            justify-content: flex-end;
        }

        .btn {
            border: 1px solid #FFB300;
            border-radius: 3px;
            font-size: 14px;
            text-transform: uppercase;
            color: #FFB300;
            padding: 4px 19px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:hover,
            &:focus {
                background-color: #FFB300;
                color: #ffffff;
            }

            svg {
                margin-right: 5px;
            }

            +.btn {
                margin-left: 10px;
            }

            &.btn-delete {
                border-color: #EB3434;
                color: #EB3434;

                &:hover,
                &:focus {
                    background-color: #EB3434;
                    color: #ffffff;
                }
            }
        }
    }

}

.mb-20 {
    margin-bottom: 20px !important;
}

.blog-checkbox {
    .toggle {
        cursor: pointer;
        display: inline-block;
        padding: 0;
    }

    .toggle-switch {
        display: inline-block;
        background: #ccc;
        border-radius: 16px;
        width: 58px;
        height: 32px;
        position: relative;
        vertical-align: middle;
        transition: background 0.25s;
    }

    .toggle-switch:before,
    .toggle-switch:after {
        content: "";
    }

    .toggle-switch:before {
        display: block;
        background: #ffffff;
        border-radius: 50%;
        // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
        width: 24px;
        height: 24px;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: left 0.25s;
    }

    .toggle:hover .toggle-switch:before {
        background: #ffffff;
        // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }

    .toggle-checkbox:checked+.toggle-switch {
        background: map-get($theme-colors, "primary");
    }

    .toggle-checkbox:checked+.toggle-switch:before {
        left: 30px;
    }

    .toggle-checkbox {
        position: absolute;
        visibility: hidden;
    }

    .toggle-label-show {
        margin-right: 8px;
        position: relative;
        top: 2px;
        font-size: 18px;
        color: map-get($theme-colors, "primary");
    }

    .toggle-label-hide {
        margin-right: 8px;
        position: relative;
        top: 2px;
        font-size: 18px;
        color: map-get($theme-colors, "tertiary");
    }
}

.create-post-dialog {
    .modal-dialog {
        max-width: 620px;
    }

    .modal-body {
        padding: 25px 50px;

        @media screen and (max-width: 575px) {
            padding: 25px 20px;
        }
    }

    .title {
        margin-bottom: 25px;
    }

    textarea {
        &.form-control {
            min-height: 220px;
        }
    }

    .modal-header {
        border: 0;
        position: absolute;
        right: 0;
        top: 5px;

        .btn-close {
            z-index: 99;
        }
    }

    form {
        .blog-desc {
            label {
                background: #fff;
                height: 20px;
                margin-top: 8px;
                margin-left: 6px;
                font-size: 14px;
                padding: 0px;
            }
        }

        .form-floating {
            margin-bottom: 20px;

            >label {
                padding-block: 8px 0 !important;
                margin: 0;
            }

            .form-control {
                border-radius: 12px;
                border: 1px solid #000000;
                font-size: 14px;
                padding-block: 26px 8px !important;
                padding-left: 16px !important;
            }

            .form-control-error {
                border-radius: 12px;
                border: 1px solid #EB3434;
                font-size: 14px;
            }

            .error {
                color: #EB3434;
            }

        }

        .error {
            color: #EB3434;
        }

        .title-error {
            position: relative;
            color: #EB3434;
            top: -15px;
        }

        .images-uploader-wrapper {
            border: 1px dashed #000000;
            border-radius: 19px;
            opacity: 1;
            padding: 35px 21px;
            padding-bottom: 27px;
            display: flex;
            flex-wrap: wrap;

            >div {
                margin-bottom: 8px;

                &:not(:first-child) {
                    margin-left: 25px;

                    @media screen and (max-width: 575px) {
                        margin-left: 12px;
                    }
                }
            }

            .size-exceed-error-msg {
                margin: 0;
                flex: 0 0 100%;
                text-align: center;
                margin-left: 0 !important;
                margin-bottom: 0 !important;
                font-size: 14px;
                color: #D60000;
            }

            .uploaded-image {
                position: relative;
                display: inline-block;

                &.size-exceed-error {
                    img {
                        border: 3.5px solid #D60000;
                    }
                }

                .preview-image {
                    width: 58px;
                    height: 58px;
                    overflow: hidden;
                    border-radius: 6px;
                }

                .del-img {
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
                    display: inline-block;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    position: absolute;
                    box-shadow: 0px 3px 6px #00000029;
                    width: 16px;
                    background-color: #ffffff;
                    height: 16px;
                    top: -5px;
                    right: -5px;
                    background-size: 8px;
                    background-position: center;
                    border-radius: 100px;
                }

                .type-icon {
                    position: absolute;
                    bottom: 3px;
                    left: 3px;
                }

            }

            .add-image {
                text-align: center;
                position: relative;
                z-index: 1;
                margin-left: auto;
                margin-right: auto;

                .max-limit {
                    color: #A8A8A8;
                    font-size: 16px;
                    margin-top: 10px;
                    display: block;
                    max-width: 283px;
                    margin-left: auto;
                    margin-right: auto;
                    line-height: 1.2;
                }

                .add-icon {
                    width: 58px;
                    height: 58px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f1f1f1;
                    margin: auto;
                    font-size: 26px;
                    font-weight: 700;
                    color: #929090;
                    border-radius: 6px;
                }

                .add-icon-error {
                    width: 58px;
                    height: 58px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f1f1f1;
                    margin: auto;
                    font-size: 26px;
                    font-weight: 700;
                    color: red;
                    border: 1px solid red;
                }

                label {
                    padding: 0;
                    margin-top: 10px;
                    font-size: 12px;
                    margin-top: 0px;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 99;
                    cursor: pointer;
                }
            }
        }

        .uploaded-media-wrapper {
            border: 1px dashed #000000;
            border-radius: 19px;
            opacity: 1;
            padding: 30px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 14px;
            margin-bottom: 20px;

            .size-exceed-error-msg {
                margin: 0;
                flex: 0 0 100%;
                text-align: center;
                margin-left: 0 !important;
                margin-bottom: 0 !important;
                font-size: 14px;
                color: #D60000;
            }

            .uploaded-image {
                position: relative;
                display: inline-flex;

                &.size-exceed-error {
                    .preview-image {
                        border: 3.5px solid #D60000;
                    }
                }

                .preview-image {
                    width: 58px;
                    height: 58px;
                    overflow: hidden;
                    border-radius: 6px;
                    object-fit: cover;
                }

                .del-img {
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
                    display: inline-block;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    position: absolute;
                    box-shadow: 0px 3px 6px #00000029;
                    width: 16px;
                    background-color: #ffffff;
                    height: 16px;
                    top: -5px;
                    right: -5px;
                    background-size: 8px;
                    background-position: center;
                    border-radius: 100px;
                }

                .type-icon {
                    position: absolute;
                    bottom: 3px;
                    left: 3px;
                }

            }
            .progress-circle {
                width: 60px;
                height: 60px;
            }
        
            .circular-chart {
                display: block;
                max-height: 250px;
            }
        
            .circle-bg {
                fill: none;
                stroke: #eee;
                stroke-width: 3.8;
            }
        
            .circle {
                fill: none;
                stroke-width: 2.8;
                stroke-linecap: round;
                stroke: map-get($theme-colors, "primary");
                animation: progress 1s ease-out forwards;
            }
        
            .percentage {
                fill: map-get($theme-colors, "primary");
                font-family: sans-serif;
                font-size: 0.5em;
                text-anchor: middle;
            }
        
            @keyframes progress {
                0% {
                    stroke-dasharray: 0 100;
                }
            }
        }

        .media-uploader-wrapper {
            border: 1px dashed #000000;
            border-radius: 19px;
            opacity: 1;
            padding: 23px 28px;
            display: flex;
            gap: 8px;

            // flex-wrap: wrap;

            .image-uploader-wrapper,
            .video-uploader-wrapper {
        
                &.exceeded {
                    opacity: 0.6;
                }
            }

            >div {
                text-align: center;
                position: relative;
                z-index: 1;

                .max-limit {
                    color: #B2B0B0;
                    font-size: 13px;
                    margin-top: 7px;
                    display: block;
                    max-width: 200px;
                    line-height: 1.5;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 99;
                    cursor: pointer;
                }

                input[type="file" i]:disabled {
                    pointer-events: none;
                }
            }

            .seperator {
                width: 1px;
                background-color: #70707059;
                min-height: 100%;
                margin-inline: auto;
            }

            .images-uploader-wrapper {}

            .video-uploader-wrapper {}
        }
        

        .modal-action-btns {
            display: flex;
            gap: 10px;
            margin-top: 30px;

            @media (max-width: 575.9px) {
                margin-top: 20px;
            }

            .modal-action-btn {
                border-radius: 10px;
                border: 0;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 1.2;
                padding-block: 15px;
                width: 100%;
                max-width: 146px;


                &:hover,
                &:active,
                &:focus {
                    opacity: .95;
                }

                &.outlined {
                    border: 1px solid #515151;
                    color: #2C2C2C;
                    background-color: transparent;

                    &:hover,
                    &:active,
                    &:focus {
                        opacity: .8;
                    }
                }

                &.filled {
                    background-color: #EA9B45;
                    border: 1px solid #EA9B45;
                }


                @media (max-width: 575.9px) {
                    max-width: none;
                    padding-block: 12px;
                }
            }
        }
    }

}

.custom-description {
    border-radius: 12px;
    border: 1px solid #000000;
    overflow: hidden;

    &.form-floating>.form-control:focus~label,
    &.form-floating>.form-control:not(:placeholder-shown)~label,
    &.form-floating>.form-select~label {
        opacity: 1;
        transform: scale(0.9644) translateY(0) translateX(0.15rem);
        padding: 0.75rem 0.75rem;
        line-height: 10px;
    }

    .form-control {
        overflow-y: auto;
        border: 0 !important;
        padding-top: 44px !important;
        height: auto !important;
    }

    label {
        height: auto !important;
        width: 100% !important;
        background-color: #fff;
        opacity: 1;
        display: block;
        margin-top: 0 !important;
    }
}

.image-error-msg {
    margin-left: 15px;
    color: red;
}


.stand-blog-wrapper {
    background: #cbcbcb;
    border-radius: 8px;
    margin-inline: 20px;
    .swiper-container {
        height: 542px;
        border-radius: 8px;

        @media screen and (max-width: 991px) {
            height: 350px;
        }

        .swiper-wrapper {
            align-items: center;
        }
    }

    .swiper-slide {
        text-align: center;

        img {
            // max-width: 500px;
            // max-height: 500px;
            // width: auto;
            object-fit: contain;
            // height: auto;
            border-radius: unset;
            height: inherit;
            width: auto;
            max-width: 100%;
        }
    }

    .swiper-pagination {
        display: none;
    }
}