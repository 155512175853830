.res-review-wrapper {
  border: 1px solid #cbcbcb;
  padding: 30px;
  border-radius: 12px;
  margin: 30px 0;
  position: relative;

  .feedback-top-area {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .title-area {
      max-width: 100%;
      margin-bottom: 0;

      p {
        max-width: 285px;
      }
    }

    .feedback-star {
      span {
        font-size: 16px;
        margin-right: 10px;
      }
    }
  }

  form {
    textarea {
      border: 1px solid #cbcbcb;
    }
  }
}

@media only screen and (max-width: 767px) {
  .res-review-wrapper {
    padding: 15px;
    margin: 15px 0;
  }

  .res-review-wrapper .feedback-top-area .title-area p {
    max-width: 100%;
  }
}

.max-height-302 {
  height: 302px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 3px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  
  &::-webkit-scrollbar-thumb {
      background-color: #A9A9A9;
      border-radius: 6px;
  }
}

.blur {
  filter: blur(4px);
}

.reveal-comment {
  margin: auto;
  margin-top: -70px;
}

.reveal-comment-none {
  display: none;
}