@import "assets/scss/_helpers/variables";

.img-res-details {
  width: 102px;
  height: 91px;
  object-fit: cover;
  border-radius: 10px;
}

.res-stand-info {
  .pin {
    margin-top: -4px;
  }
}

.fw-medium {
  font-weight: 500;
}

.res-box-title {
  padding: 22px 40px 12px 40px;

  @media (max-width: 767px) {
    padding: 12px 10px 12px 10px;
  }
}

.res-detail-box {
  border: 1px solid map-get($theme-colors, "fifth");
  border-radius: 10px;
  margin-top: 2rem;
  max-height: -webkit-fill-available;
  height: 100%;

  .res-detail-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 8px 40px;

    @media (max-width: 767px) {
      padding: 8px 10px;
      font-size: 12px;
    }

    &.bordered {
      border-top: 1px solid map-get($theme-colors, "fifth");
      border-bottom: 1px solid map-get($theme-colors, "fifth");
    }
  }
}

.res-pay-line {
  padding: 8px 40px;

  @media (max-width: 767.9px) {
    padding: 8px 10px;
    font-size: 12px;
  }
}

.res-image-upload {
  border: 1px dashed map-get($theme-colors, "tertiary");
  border-radius: 10px;
  padding: 20px 0;
  position: relative;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 8px 10px;
    font-size: 12px;
  }

  .uploaded-img-inner {
    height: -webkit-fill-available;

    >img {
      height: -webkit-fill-available;
      width: -webkit-fill-available;
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.text-right {
  text-align: right;
}
.my-land .tabs .nav-tabs .nav-item .nav-link {
  font-size: 16px;
}

.upload-img {
  display: grid;
  margin-top: 20px;
  grid-gap: 10px;
  grid-template-columns: auto auto;

  div {
    width: 100%;
    border: 1px dashed map-get($theme-colors, "tertiary");
    border-radius: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.res-feedback {
  height: 165px;
  border: 1px solid map-get($theme-colors, "tertiary");
  border-radius: 10px;
  margin-top: 2px;
  color: map-get($theme-colors, "darkblack");
  padding: 10px 15px;

  &::placeholder {
    color: map-get($theme-colors, "fifth");
  }
}

.res-submit-btn {
  color: #fff !important;
}

.res-top-details {
  @media (max-width: 767px) {
    flex-direction: column;

    .res-book-dates {
      margin-top: 1rem;
    }
  }
}

.address-span {
  max-width: 546px;
  display: inline-block;
  vertical-align: top;
}

.payment-details-v2 {

  .res-box-title,
  .res-pay-line {
    padding: 8px 30px;
  }

  .footer {
    border-top: 1px solid #cbcbcb;

    div {
      color: #000;
      opacity: 1;
      font-size: 14px;
    }
  }

  .table {
    font-size: 14px;
    border-collapse: collapse !important;

    &> :not(:first-child) {
      border-top: 1px solid currentColor;
    }
  }
}

.funds-request-v2 {
  .stand-details-table {
    .table-responsive {
      border: 1px solid #cbcbcb;
      border-radius: 10px;
        @media (max-width: 991.8px) {
          border: 1px solid transparent;
          border-radius: 0;
          table {
            border: 0;
            caption {
            font-size: 1.3em;
            }
            thead {
              border: none;
              clip: rect(0 0 0 0);
              height: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 1px;
            }
            tr {
              display: block;
              margin-bottom: .625em;
              border: 1px solid #cbcbcb;
              border-bottom: 3px solid #ddd;
              border-radius: 10px;
              td {
                font-size: 0.8em;
                border-bottom: 1px solid #cbcbcb;
                height: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: left;
                padding: 0.5rem 1rem !important;
                &::before {
                  content: attr(data-label);
                  font-weight: 700;
                  text-transform: uppercase;
                  width: 50%;        
                }
                &:last-child {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      .table {
        tbody {
          tr {
            td {
              padding: 0 0.5rem;
            }
          }
        }
      }
    }
  }
}

.underline-view {
  color: #EA9B45;
  border-bottom: 1px solid #EA9B45;
  margin-bottom: 1px;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #EA9B45;
    text-decoration: none;
    border-bottom: 1px solid #EA9B45;
  }
}

.my-land.res-pay-line {
  & > button.btn {
    position: absolute;
    right: 40px;
    z-index: 2;
    @media (max-width: 767.9px) {
      right: 10px;
    }
    @media (max-width: 575.99px) {
      position: relative;
      left: auto;
      margin-left: 0 !important;
      right: auto;
      margin-bottom: 20px;
    }
  }
}

.additional-table {
  width: 280px;

  @media (max-width: 575.99px) {
    width: auto;
    max-width: 280px;
  }
}

.right {
  right: 0 !important;
}

.header {
  margin: 1rem 0rem;
  color: #2C2C2C;

  .add-icon {
    margin-right: 0.25rem;
  }
}
.reservation-details {
  .react-calendar {
    position: absolute !important;
    max-width: 92% !important;
  }
  .res-detail-box {
    .res-stand-info {
      justify-content: space-between;
      & > div {
        margin-right: 1rem;
      }
      @media (max-width: 420.9px) {
        flex-wrap: wrap;
        gap: 10px;
        & > div {
          margin-right: auto;
          margin-left: auto;
        }
        .mr-land-info {
          h5 {
            text-align: center;
          }
        }
      }
    }
  }
  .my-land {
    .tabs {
      margin-right: 40px;
      @media (max-width: 767.9px) {
        margin-right: 20px;
      }
      @media (max-width: 575.99px) {
        margin-right: 0;
        .nav-tabs {
          margin-right: 0;
        }
      }
    }
  }
  .user-card {
    .user-detail {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
.reservation-details-calender {
  .react-calendar button {
    pointer-events: none;
  }

  .react-calendar__navigation button {
    pointer-events: auto;
  }

  .react-calendar1 button {
    pointer-events: auto !important;
  }

  .reserved-date {
    background-color: #eeaf6a !important;
    color: #fff !important;
  }

  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    pointer-events: auto !important;
  }
}
.trophies-dashboard {
  .trophy-box {
    border-radius: 12px;
    .trophies-img {
      position: relative;
      margin-right: 1rem;
      img {
        width: 50px;
        height: 50px;
        border-radius: 30px;
        object-fit: cover;
        border: 2px solid map-get($theme-colors, "primary");
        z-index: 3;
        position: relative;
      }
      .singh {
        max-width: 45px;
        position: absolute;
        left: 3px;
        top: -28px;
        z-index: 1;
      }
    }
    .trophies-bottom {
      padding: 21px 37px;
      text-align: end;
      .btn {
        font-size: 12px;
        padding: 6px 10px;
        background-color: #e9e9e9;
      }
    }
  }
}
.hunt-rejection-modal {
  .modal-content {
    position: relative;
    .modal-body {
      .textarea-main {
        position: relative;
        label {
          position: absolute;
          top: 0;
          left: 0;
          padding: 6px 10px !important;
        }
        textarea {
          height: 80px !important;
          padding-top: 30px;
          line-height: 16px;
        }
      }
    }
  }
  .modal-header {
    .btn-close {
      position: absolute;
      top: 2.3rem;
      right: 43px;
      pointer-events: all;
      z-index: 3;
    }
  }
  .modal-dialog {
    margin: auto;
    @media (max-width: 460.9px) {
      padding: 0 10px;
    }
  }
}