@import "../../../../assets/scss/_helpers/variables";

.primary-link {
  color: map-get($theme-colors, "primary");
  text-decoration: none;
}

.pending-link {
  color: #E9BF45;
  text-decoration: none;
}

.text-red {
  color: #D60000;
}

.amount {
  color: #DF9C56;
}

.dot-completed {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px;
  margin-right: 8px;
  display: block;
  background: #27AB34;
}

.dot-primary {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px;
  margin-right: 8px;
  display: block;
  background: #DF9C56;
}

.dot-danger {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px;
  margin-right: 8px;
  display: block;
  background-color: #D60000;
}

.dot-warning {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px;
  margin-right: 8px;
  display: block;
  background-color: #E9BF45;
}

.text-completed {
  float: right;
  color: #27AB34 !important;
}
.text-primary {
  float: right;
  color: #DF9C56 !important;
}
.text-danger {
  float: right;
  color: #D60000 !important;
}
.text-warning {
  float: right;
  color: #E9BF45 !important;
}

.reservation-boxes {
  .my-res-box {
    background-color: map-get($theme-colors, "sixth");
    border-radius: 10px;
    padding: 20px;

    &:hover {
      box-shadow: 0px 0px 10px #00000026;
    }

    .mr-topDetails {
      display: flex;
      align-items: center;

      >img {
        border-radius: 10px;
        margin-right: 1rem;
        max-width: 77px;
        width: 100%;
        height: 66px;
        object-fit: cover;
      }

      .mr-land-info {
        .location {
          display: flex;
          align-items: flex-start;
          margin: 10px 0;

          img {
            margin-right: 0.5rem;
            margin-top: -2px;
          }

          p {
            color: map-get($theme-colors, "tertiary");
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }
  }
}

.top {
  display: flex;
  align-items: center;
  cursor: pointer;

  .bi {
    font-size: 26px;
  }

  span {
    color: map-get($theme-colors, "seventh");
    font-size: 18px;
    font-weight: 400;
  }
}

.additinal-hunters-tab-main {
  .invitations-main {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CBCBCB;
    border-radius: 16px;
    padding: 25px 25px 10px 25px;
    @media (max-width: 660px) {
      padding: 25px 15px 10px 15px;
    }
    .row   {
      strong {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
    }
    .user-detail {
      @media (max-width: 470px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
      }
      & > .dropdown {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        gap: 7px;
        @media (max-width: 470px) {
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          width: 100%;
          .dropdown {
            flex-grow: 1;
            display: flex;
            width: 100%;
          }
          button {
            font-size: 14px !important;
            padding: 8px 10px !important;
            flex-grow: 1;
          }
        }
        button {
          @media (max-width: 470px) {
            flex-grow: 1;
            width: 100%;
            justify-content: center;
          }
        }
       .btn-primary  {
          width: 100%;
        }
      }
    }
    .items {
      align-items: center;
    }
  }
}

.reservation-boxes {
  .aditional-hunters-post {
    
    &.my-res-box {
      .mr-topDetails {
        width: 100%;
          @media(max-width: 1399.9px) {
            flex-wrap: wrap;
          }

        .img {
          margin-right: 15px;
          position: relative;
          min-width: 146px;
          height: 146px;
          border-radius: 12px;
          img {
            min-width: 146px;
            height: 146px;
            object-fit: cover;
            border-radius: 12px;
            opacity: 1;
          }

          p {
            position: absolute;
            right: 5px;
            top: 5px;
            background: #F9F9F9 0% 0% no-repeat padding-box;
            border-radius: 6px;
            opacity: 1;
            box-shadow: 0px 3px 20px #0000001A;
            padding: 2px 6px;
            font-size: 12px;
          }
        }

        .mr-land-info {
          .location {
            img {
              margin-top: 2px;
              width: 10px;
            }
          }
        }
      }

      .price {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #EA9B45;
        opacity: 1;
        display: flex;
        margin-bottom: 3px;
      }
      .txt-seventh {
        strong {
          font-size: 16px;
          line-height: 18px;
        }
      }
      .user-detail {
        .user-info-content {
          p {
            line-height: 1.2;
          }

          a {
            text-decoration: none;
          }
        }

        .dropdown {
          button {
            min-width: 99px;
            justify-content: center;
          }
        }
      }
    }
  }
}

.invite-member-modal {
  .modal-header {
    padding: 0;
    border: 0;

    .btn-close {
      position: absolute;
      right: 55px;
      top: 55px;
      z-index: 2;
      background-size: 15px;
      padding: 0 !important;
      width: 15px;
      height: 15px;
    }
  }

  .main-heading {
    h5 {
      letter-spacing: 0px;
      color: #2C2C2C;
      opacity: 1;
      margin-bottom: 4px;
      font-size: 22px;
      font-weight: 400;
      line-height: 16px;
    }

    span {
      font: normal normal normal 16px/21px Roboto;
      letter-spacing: 0px;
      color: #928F8F;
      opacity: 1;
    }
  }

  .input-group {
    margin-top: 30px;

    .form-control {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #CBC6C6;
      border-radius: 3px 0 0 3px;
      opacity: 1;
      border-right: 0;
      font-size: 14px;
      line-height: 8px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;

      &::placeholder {
        font-size: 14px;
        line-height: 8px;
        letter-spacing: 0px;
        color: rgb(153 153 153 / 84%);
        opacity: 1;
      }
    }

    .input-group-append {
      border-radius: 4px !important;
      -webkit-border-radius: 4px !important;
      -moz-border-radius: 4px !important;
      -ms-border-radius: 4px !important;
      -o-border-radius: 4px !important;
    }

    .btn {
      border-radius: 4px !important;
      margin-left: -3px;
      -webkit-border-radius: 4px !important;
      -moz-border-radius: 4px !important;
      -ms-border-radius: 4px !important;
      -o-border-radius: 4px !important;
      text-align: left;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      height: 43px;
      font-size: 14px;
      line-height: 8px;
      font-weight: 400;
      text-transform: capitalize;
      letter-spacing: 0;
      padding: 5px 30px;
    }
  }
}

.br-0 {
  border-radius: 0px !important;
}