@import "../../../../assets/scss/_helpers/variables";

.courses-causal {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-column-gap: 5%;
  justify-content: center;
  width: 100%;
  row-gap: 20px;

  @media screen and (max-width: 991px) {
    // grid-template-columns: repeat(2, auto);
    // grid-template-columns: repeat(2,1fr);
    grid-template-columns: repeat(2, 48%);
    grid-column-gap: 2%;
  }

  @media screen and (max-width: 767px) {
    // grid-template-columns: repeat(1, auto);
    // grid-template-columns: repeat(1,1fr);
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
  }
}

.land-item {
  max-width: 100%;
  width: 100%;
  align-items: center;
  margin: 2rem auto;
  background-color: #f9f9f9;
  // height: -webkit-fill-available;
  height: auto;
  display: grid;
  align-items: end;

  &:hover {
    cursor: pointer;
  }
}

.carusal-item {
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  height: 280px;

  &:hover {
    .overlay {
      visibility: visible;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;

    span {
      font-size: 12px;
      color: map-get($theme-colors, "secondary");

      &:hover {
        color: #fff;
        background-color: map-get($theme-colors, "secondary");
        .bi-heart{
          color: #fff !important;
        }
      }

      .bi-star-fill {
        color: map-get($theme-colors, "primary");
        margin-right: 3px;
      }
    }

    &.add-to-favorite {
      right: 65px;
    }
  }

  .overlay {
    background: #000000ba;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: all 0.3s ease 0s;
    z-index: 99;

    a {
      text-decoration: none;
    }

    img {
      width: 40px;
      border-radius: 0;
    }
  }
}

.course-details {
  padding: 20px;
  background: #F9F9F9;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .detail-top-skeleton {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
  }

  .details-top {
    display: flex;
    justify-content: space-between;
    .price-with-icon {
      min-width: 85px;
    }


    h5 {
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    p {
      font: normal normal normal 14px/20px Roboto;
      color: map-get($theme-colors, "tertiary");
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      height: 40px;

      img {
        width: 11px;
        margin-top: -4px;
      }
    }

    .address-distance {
      list-style: unset;
      padding: 0;

      li {
        display: flex;
        align-items: flex-start;
        position: relative;

        &:first-child {
          min-height: 34px;
        }

        &::marker {
          content: none;
        }

        &:not(:last-child) {
          margin-bottom: 8px;

          &:before {
            content: "";
            position: absolute;
            bottom: -9px;
            left: 7px;
            width: 0;
            height: calc(100% - 12px);
            border-left: 2px dashed #ea9b45;
          }
        }

        img {}

        span {
          font: normal normal normal 14px/20px Roboto;
          color: map-get($theme-colors, "tertiary");
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 1.2;
        }
      }
    }

    .hrs {
      color: #2c2c2c;
      font-weight: bold;

      img {
        margin-right: 5px;
      }
    }
  }

  .detail-btn {
    border-top: 2px solid #c5c5c5;
    padding-top: 15px;
  }

  .detail-btm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #c5c5c5;
    padding-top: 15px;
    flex-wrap: wrap;

    a {
      font: normal normal normal 12px/15px Roboto;
      padding: 11px 30px;
    }

    .btn-secondary {
      font-size: 0.813rem !important;
      margin-top: 8px !important;
      margin-left: auto;

      &:hover {
        color: white !important;
      }
    }

    p {
      margin-bottom: 0;
      font: normal normal medium 14px/16px Roboto;
      color: map-get($theme-colors, "secondary");
      font-size: 14px;
    }
  }
}

.stand-listig-with-map {
  .courses-causal {
    grid-template-columns: repeat(1, 100%);
    grid-column-gap: 0;

    .land-item {
      display: flex;
      align-items: center;
      padding: 15px;
      margin: 0 0 30px;

      .carusal-item {
        height: 170px;
        border-radius: 12px;
        max-width: 250px;
        width: 100%;

        .img-btn {
          right: auto;
          left: 15px;
        }
      }

      .course-details {
        width: calc(100% - 240px);
        padding: 0 0 0 15px;

        .detail-btm {
          p {
            margin-bottom: 0;
            font: normal normal medium 14px/16px Roboto;
            color: #515151;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.stand-listig-with-map {
  margin-bottom: 50px;
}

.stand-listig-with-map .courses-causal .land-item:last-child {
  margin-bottom: 0;
}

.map-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 71px;
  height: calc(100vh - 71px);
}

.map-holder {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .stand-listig-with-map .courses-causal .land-item {
    display: block;
    padding: 0;
  }

  .stand-listig-with-map .courses-causal .land-item .carusal-item {
    height: 250px;
    max-width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .stand-listig-with-map .courses-causal .land-item .course-details {
    width: 100%;
    padding: 20px;
  }
}