@import "../../../../assets/scss/_helpers/variables";

.action-bar {
    &.d-flex {
        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    .create-post {
        color: map-get($theme-colors, "primary");
        text-decoration: unset;

        svg {
            margin-right: 8px;
        }
    }

}

.blog-page {
    flex: 1;
}

.main-blog {
    margin-bottom: 30px;

    .main-blog-topbar {
        @media screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    .blog-title {
        vertical-align: middle;
        font-size: 35px;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        @media screen and (max-width: 1200px) {
            font-size: 28px;
        }

        @media screen and (max-width: 991px) {
            font-size: 25px;
        }

        @media screen and (max-width: 575px) {
            font-size: 23px;
        }

        .date {
            border: 1px solid #928F8F;
            width: 76px;
            height: 76px;
            padding: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex: 0 0 76px;
            color: #2C2C2C;
            font-size: 12px;
            opacity: 1;
            vertical-align: middle;
            margin-right: 20px;
            text-align: center;

            @media screen and (max-width: 991px) {
                margin-right: 12px;
            }

            label {
                font-size: 25px;
                font-weight: 600;
                color: #2C2C2C;
                margin-bottom: 5px;
                padding: 0;
            }
        }
    }

    .image {
        margin-bottom: 20px;

        img {
            max-width: 100%;
        }
    }

    .desc {
        margin-bottom: 0;

        p {
            margin-bottom: 0;
        }
    }

    .main-blog-topbar {
        margin-bottom: 20px;
    }

    .blogSub-details {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        >* {
            margin-bottom: 10px;
        }

        .user-info {
            display: flex;
            align-items: center;
            margin-right: 25px;

            @media screen and (max-width: 991px) {
                margin-right: 12px;
            }

            &__img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 12px;
                //flex: 0 0 38px;

                img {
                    height: 100%;
                    width: 100%;
                    height: 40px;
                    width: 40px;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }

            &__desc {

                h5 {
                    color: #2C2C2C;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: "Roboto", sans-serif !important;
                    margin-bottom: 3.5px;
                }

                h6 {
                    color: #2C2C2C;
                    opacity: 0.7;
                    font-size: 14px;
                    font-family: "Roboto", sans-serif !important;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.mb-20 {
    margin-bottom: 20px !important;
}



.font-50 {
    font-size: 50px !important;

    @media screen and (max-width: 1200px) {
        font-size: 40px !important;
    }

    @media screen and (max-width: 991px) {
        font-size: 36px !important;
    }

    @media screen and (max-width: 575px) {
        font-size: 32px !important;
    }
}

.mx-w-960 {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}