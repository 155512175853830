.calendar-modal {
  .modal-content {
    border-radius: 9px;
  }

  .modal-header {
    align-items: center;
    padding: 66px 60px 0 60px;
    border-bottom: 0;
    margin-bottom: 4px;

    @media (max-width: 575.9px) {
      padding: 30px 30px 0 30px;
    }

    .btn-close {
      width: 17.7px;
      height: 17.7px;
      opacity: 1;

      @media (max-width: 575.9px) {
        top: 30px;
        right: 30px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 18px;
      margin: 0;
      color: #2C2C2C;
      font-family: 'Minion Pro';
      font-weight: 500;

      @media (max-width: 575.9px) {
        font-size: 22px;
      }
    }

  }

  .modal-body {
    padding: 20px 60px 60px 60px;

    @media (max-width: 575.9px) {
      padding: 20px 30px 30px 30px;
    }


    .react-calendar {
      position: static !important;
      width: 100%;
      margin-top: 5px;
      border: 1px solid #ced4da;
      border-radius: 8px;
    }

    .react-calendar__tile.react-calendar__month-view__days__day p {
      color: #928f8f;
      font-size: 11px;
    }

    .react-calendar__month-view__weekdays__weekday abbr[title="Sunday"],
    abbr[title="Saturday"] {
      color: #EA9B45;
    }

    .react-calendar__navigation__label {
      background-color: transparent;
      pointer-events: none;
    }

    .react-calendar__navigation__label__labelText {
      color: #928f8f;
    }

    .done-btn {
      background-color: #EA9B45;
      border-radius: 10px;
      border: 0;
      color: #F5F9FF;
      font-size: 16px;
      line-height: 1.1;
      padding-block: 20px;
      width: 100%;
      margin-top: 20px;

      &:hover,
      &:active,
      &:focus {
        opacity: .95;
      }

      @media (max-width: 575.9px) {
        margin-top: 16px;
        max-width: none;
        padding-block: 12px;
      }
    }

  }
}