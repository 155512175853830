.floating-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 128px;
    @media (max-width: 991.9px) {
        bottom: 30px;
        right: 25px;
    }

    @media (max-width: 767.9px) {
        bottom: 20px;
        right: 25px;  
    }

    @media (max-width: 575.9px) {
        bottom: 20px;
        right: 25px;  
    }

    &-list {
        list-style: none;
        padding-inline: 15px;
        background-color: #EA9B45;
        border-radius: 6px;
        color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease-in-out;
        transform: translateY(60px) scale(0.5);
        display: none;
        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0) scale(1);
            display: block;
        }

        &-item {
            border-bottom: 1px solid #fff;
            padding-block: 10px;
            // cursor: pointer;
            a {
                text-decoration: none;
                color: #fff;

                &:hover {
                    opacity: .75;
                    color: #fff;
                }
            }

            // &:hover {
            //     opacity: .75;
            // }

            &:last-child {
                border-bottom: 0;
            }
            & div {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &-text {
            margin-left: 10px;
            line-height: 15px;
            text-transform: uppercase;
        }
        &-innertext {
            font-size: 14px;
            margin-left: 32px;
            width: 100%;
            display: flex;
        }
    }

    .floating-profile-img {
        cursor: pointer;
        transition: all 0.3s ease-in;
        width: 80px;
        height: 80px;
        object-fit: cover;
        @media (max-width: 991.9px) {
            width: 60px;
            height: 60px;
        }
        &:hover {
            transform: scale(1.125);
        }
    }
    .floating-button-img {
        width: 22px;
        object-fit: contain;
    }
}