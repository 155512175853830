.title-modal.h4 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 20px;
}
.header-modal,
.footer-modal {
  border: none !important;
}
.footer-modal button {
  max-width: 100px;
  width: 100%;
  margin-left: 10px;
  padding: 7px 15px;
}
.footer-modal .btn-elevate {
  background: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.content-modal {
  width: 100% !important;
}