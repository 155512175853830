@import "../../../../assets/scss/_helpers/variables";

.blog-section {
  .blog-heading {
    text-align: center;
    h1 {
      max-width: 100%;
      margin: auto;
      color: map-get($theme-colors, "seventh");
    }
    p {
      color: map-get($theme-colors, "seventh");
    }
    .blog-cover {
      width: 100%;
      max-width: 800px;
      margin: auto;
      border-radius: 10px;
    }
  }
  .blog-detail {
    max-width: 650px;
    margin: auto;
    color: #2c2c2c;
    .blog-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .blog-user {
        display: flex;
        img {
          border-radius: 50%;
          height: 48px;
          width: 48px;
        }
        h4 {
          color: map-get($theme-colors, "seventh");
        }
        p {
          color: map-get($theme-colors, "seventh");
        }
      }
      i {
        color: #010002;
        border: 1px solid #f2f2f2;
        padding: 5px 15px;
      }
    }
  }
}
.Read-next {
  border-top: 1px solid #bebebe;

  h2 {
    text-align: center;
  }
}
.blog-btm-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
  justify-content: flex-start;
  @media screen and (max-width: 991px) {
    grid-template-columns: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  .blog-item {
    position: relative;
    margin-bottom: 10px;
    max-width: 336px;
    height: 220px;

    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
    &.blog-right {
      img {
        max-height: 170px;
        @media screen and (max-width: 991px) {
          max-height: unset;
        }
      }
    }
    .layer {
      position: absolute;
      height: 100%;
      width: 100%;
      background: transparent
        linear-gradient(
          181deg,
          #ffffff00 0%,
          #00000000 51%,
          #1f1f1fbd 77%,
          #000000 100%
        )
        0% 0% no-repeat padding-box;
      z-index: 2;
      top: 0;
      border-radius: 4px;
    }
    .blog-bottom {
      position: absolute;
      width: 100%;
      bottom: 5px;
      padding: 0 15px;
      z-index: 3;

      @media screen and (max-width: 380px) {
        padding: 0 5px;
      }
      .heading {
        color: #fff;
        font-size: 18px;
      }
      .blog-detail {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #fff;
        border-top: 1px solid map-get($theme-colors, "fifth");
        padding-top: 8px;

        div {
          display: flex;

          img {
            width: 17px;
            margin-right: 10px;
            object-fit: unset;
            border-radius: unset;
          }
        }
      }
    }
  }
}

.blog-detail img {
  width: 100%;
  height: 100%;
}
