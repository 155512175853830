@import "../../../../assets/scss/_helpers/variables";

.acheivement {
  padding: 70px 0 180px 0;
  @media (max-width: 991.9px) {
    padding: 50px 0 50px 0;
  }
  .acheivement-items {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .d-grid {
    grid-template-columns: auto auto;
    grid-gap: 30px;
    @media screen and (max-width: 550px) {
      grid-template-columns: auto;
    }

    div {
      background-color: map-get($theme-colors, "sixth");
      display: grid;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;

      h2 {
        margin-bottom: 0;
        font-weight: 600;
        color: map-get($theme-colors, "primary");
        font-size: 60px;
        @media (max-width: 991.9px) {
          font-size: 45px;
        }
      }
      p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 20px;
        color: map-get($theme-colors, "secondary");
        @media (max-width: 991.9px) {
          font-size: 16px;
        }
      }
    }
  }

  .achievement-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;

    @media screen and (max-width: 767px) {
      padding-left: 10px;
      padding-top: 30px;
    }
    h2 {
      color: map-get($theme-colors, "secondary");
      @media screen and (min-width: 1200px) {
        font-size: 52px;
      }
      margin-bottom: 2rem;
      @media (max-width: 991.9px) {
        margin-bottom: 1rem;
      }
    }
    p {
      color: map-get($theme-colors, "tertiary");
      font-size: 18px;
      @media (max-width: 991.9px) {
        font-size: 16px;
      }
    }
  }
}
