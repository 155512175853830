@import "../../../../../src/assets/scss/_helpers/variables";

.payments {
  .payments-inner {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    & > div {
      h3 {
        margin-bottom: 0 !important;
      }
    }
    @media (max-width: 475.9px) {
      & > div {
        flex-direction: column;
        .transfer-to-strip-btn {
          display: flex;
          order: 1;
          justify-content: flex-end;
          width: 100%;
        }
        h3 {
          display: flex;
          order: 2;
          text-align: left;
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }
  .payment-card {
    .card-price {
      //border: 1px solid map-get($theme-colors, "fifth");
      padding: 15px 20px;
      border-radius: 8px;

      @media (max-width: 1400px) {
        padding: 10px 10px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        flex-direction: column;
      }
      .icon {
        background-color: #daf5dd;
        padding: 4px 6px;
        border-radius: 6px;
        margin-top: 5px;
        margin-right: 10px;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1400px) {
          padding: 0px 3px;
          margin-right: 0;
        }
        i {
          font-size: 20px;
        }
        &.spending {
          background-color: #f7dedf;
          i {
            color: #cb2027;
          }
        }
        &.info {
          background-color: transparent;
          width: 30px;
          padding: 0;
          text-align: center;
          i {
            font-size: 22px;
          }
        }
      }
      .detail {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @media (max-width: 1400px) {
          text-align: center;
        }
        .price {
          font: normal normal bold 32px Roboto;
          @media (max-width: 1400px) {
            font: normal normal bold 18px Roboto;
            text-align: center;
          }
          @media (max-width: 1199px) {
            font: normal normal bold 16px Roboto;
          }
        }
        .title {
          font: normal normal normal 16px Roboto;
          color: #928f8f;
          min-width: 140px;
          margin-bottom: 0;
          text-align: left;
          @media (max-width: 1400px) {
            font: normal normal normal 14px Roboto;
          }
          @media (max-width: 1400px){
            min-width: 120px;
          }
        }
      }
      &.card-price-v2 {
        background-color: #EFEFEF;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        width: fit-content;
        padding-left: 40px;
        padding-right: 40px;
        align-items: center;
        gap: 10px;
        width: 100%;
        @media (max-width: 475px) {
          padding-left: 15px;
          padding-right: 15px;
        }
        .detail {
          flex-direction: row;
          width: 100%;
          align-items: center;
        }
        .title {
          &.green {
            color: #0BA14B;
          }
          &.red {
            color: #D86065;
          }
          &.orange {
            color: #EA9B45;
          }
        }
        .price {
          text-align: right;
          letter-spacing: -0.54px;
          color: #2C2C2C;
          opacity: 1;
          font-size: 18px;
          line-height: 9px;
          @media (max-width: 475px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    
    .chart {
      height: 100%;
      border: 1px solid #cbcbcb;
      border-radius: 8px;
      .btn-group-main {
        justify-content: flex-end;
      }
      // .btn-group {
        // @media (max-width: 450px) {
        //   flex-direction: column;
        //   gap: 5px;
        // }
      // }
      .filterButton {
        color: #000;
        @media (max-width: 475px) {
          // border-radius: 8px !important;
          padding: 5px 13px !important;
          font-size: 12px;
        }
      }
    }
  }
  .payment-table {
    table {
      border-radius: 10px;
      border-width: 1px;
      border-style: solid;
      border-color: #cbcbcb;
      border-spacing: 0;
      border-collapse: separate;
      width: 100%;
      thead {
        tr {
          th {
            font: normal normal normal 16px Roboto;
            color: #cbcbcb;
            text-align: left;
            padding: 20px 20px;
            border-bottom: 1px solid #cbcbcb;
          }
        }
      }
      tbody {
        tr {
          &:last-of-type {
            td {
              border-bottom: none;
            }
          }
          td {
            font: normal normal normal 18px Roboto;
            color: #515151;
            text-align: left;
            padding: 20px 20px;
            border-bottom: 1px solid #cbcbcb;
          }
        }
      }
    }
  }
}
.payment-tabs-v2 {
  @media (max-width: 767.9px) {
    .tab-btn {
      .nav-tabs {
        margin-right: 90px;
      }
    }
  }
  @media (max-width: 475px) {
    margin-top: 0;
    .tab-btn {
      .nav-tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-right: 0 !important;
      }
    }
  }
  
  .tab-btns {
    margin-bottom: 12px;
    .tab-items {
      font-size: 16px;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
    @media (max-width: 475px) {
      display: flex;
      .tab-items {
        font-size: 14px;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
    
  }
  .filter-new-btns {
    margin-top: -4px;
    .clear-filter {
      margin-top: 6px;
    }
    @media (max-width: 475px) {
      display: flex;
      position: relative;
      margin-top: 0;
      margin-bottom: 10px;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-end;
      gap: 15px;
      .clear-filter {
        margin-right: 0;
        font-size: 14px;
      }
      .btn-primary {
        padding: 5px 13px !important;
        font-size: 14px;
      }
    }
  }
}
.transfer-model {
  padding-left: 0 !important;
  .modal-dialog {
    max-width: 435px;
    .modal-content {
      border: none;
      .modal-header {
        border: none;
        padding-bottom: 0;
        .btn-close {
          font-size: 12px;
          color: #000000;
          opacity: unset;
          argin: 0.2rem 0.23rem -0.5rem auto;
        }
      }
      .modal-body {
        padding: 1rem 43px;
        padding-bottom: 40px;
        h3 {
          font: normal normal normal 28px/16px Minion Pro;
          color: #2c2c2c;
        }
        // P {
        //   font: normal normal normal 16px/20px Roboto;
        //   color: #928f8f;
        //   margin-top: 12px;
        // }
        textarea {
          border-radius: 10px;
          font-size: 14px;
          font-weight: 400;
          resize: none;
          height: 105px;
        }
        input {
          border-radius: 10px;
          height: 45px;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 400;
        }
        label {
          padding: 12px 10px;
        }
        .blue-btn {
          width: 100%;
          background-color: #626cd9;
        }
        a {
          width: 100%;
          color: #fff;
        }
      }
    }
  }
}
.transfer-model.escrow-model {
  .modal-dialog {
    max-width: 616px;
  }
  .modal-body {
    h3 {
      span {
        font-size: 16px;
      }
    }
    h2 {
      font: normal normal medium 18px/16px Roboto !important;
    }
    h4 {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 600;
    }
    .subtitle {
      color: #928f8f;
      margin-top: 15px;
    }
    .escor-listing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #cbcbcb;
      .price {
        color: #EA9B45 !important;
        font-size: 20px;
        margin-bottom: 0;
        font-weight: 500;
      }
      .date {
        color: #928f8f;
        font-size: 14px;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
    .list-detail {
      border-bottom: 1px solid #cbcbcb;
      align-items: center;
      margin-top: 15px;

      &:last-of-type {
        border-bottom: none;
      }
      .title {
        color: #2c2c2c;
        font-size: 16px;
        margin-bottom: 0;
      }
      .price {
        color: #EA9B45 !important;
        font-size: 20px;
        margin-bottom: 0;
        font-weight: 500;
      }
      .date {
        color: #928f8f;
        font-size: 14px;
      }
    }
  }
}
.transfer-amount-model {
  p {
    margin-bottom: 0;
  }
  .stripe-box {
    border: 1px solid #000000;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 20px;
    .title {
      color: #635bff;
      font-weight: bold;
    }
    P {
      color: #928f8f;
    }
    .emailBox {
      display: flex;
      justify-content: space-between;
      p {
        color: #928f8f;
      }
      .change {
        color: #0566c8;
      }
    }
  }
  form p {
    color: #928f8f;
  }
}
.date-range-holder {
  max-width: 260px;
  flex: 0 0 260px;
  position: relative;
}
.field-custom-width.date-range-holder {
  max-width: 280px;
  flex: 0 0 280px;
  position: relative;
}
.danger {
  color: red;
}
.primary {
color: orange;
}
.my-land {
  .tabs{
    &.mr-110 {
      .nav-tabs {
        margin-right: 110px;
        flex-grow: 1;
        @media (max-width:767.9px) {
          margin-right: 90px;
        }
        @media (max-width:470px) {
          margin-right: 0;
        }
      }
    }
  }
  .tab-btn {
    display: flex;
    justify-content: space-between;
    font: normal normal normal 18px/22px Roboto;
    width: 18%;
    .underline-view {
      color: #EA9B45;
      border-bottom: 1px solid #EA9B45;
      margin-bottom: 1px;
      text-transform: capitalize;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-bottom 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
    
      &:hover {
        color: #EA9B45;
        text-decoration: none;
        border-bottom: 1px solid #EA9B45;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-bottom 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
      }
    }
  
    .simple-view {
      color: #928F8F;
      margin-bottom: 1px;
      text-transform: capitalize;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-bottom 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
    
      &:hover {
        color: #EA9B45;
        text-decoration: none;
      }
    }
    .filter-new-btns {
      // margin-top: -10px;
      // @media(max-width: 767.9px) {
      //     margin-top: -16px;
      //     align-items: center;
      // }
    }

    // @media screen and (max-width: 1399px) {
    //   width: 33%;
    // }
  
    // @media screen and (max-width: 767px) {
    //   width: 43%;
    // }

    // @media screen and (max-width: 425px) {
    //   width: 50%;
    // }

    // @media screen and (max-width: 375px) {
    //   width: 57%;
    // }
  }
}
.transfer-to-strip-btn {
  display: flex;
  align-items: center;
  img {
    width: 26px;
    height: 26px;
    margin-right: -50px;
    z-index: 1;
    @media (max-width: 475px) {
      width: 16px;
      height: 16px;
      margin-right: -30px;

    }
  }
  .btn-primary {
    color: #0BA14B !important;
    border-color: #EFEFEF;
    background-color: #EFEFEF;
    border-radius: 50px;
    font-size: 16px;
    padding-left: 60px !important;
    margin-right: 0;
    min-width: 245px;
    @media (max-width: 475px) {
      min-width: 155px;
      padding-left: 35px !important;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}
.filter-new-btns {
  position: absolute;
  right: 0;
  display: flex;
  align-items: flex-start;
  @media (max-width: 450px) {
    position: absolute;
    right: 0;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: -20px;
  }
   @media (max-width: 363.9px) {
   margin-top: 0;
   }
  .clear-filter {
    outline: 0;
    box-shadow: none;
    border: 0;
    background-color: transparent;
    color: #0566C8;
    text-decoration: underline;
    font-size: 16px;
    text-transform: capitalize;
    padding: 0;
    margin-right: 20px;
  }
}
.payment-history-v2 {
  .payment-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    
    &.red {
      .left {
        .title {
          color: #DA6267;
        }
      }
      .right {
        .price {
          color: #DA6267;
        }
      }
    }
    &.green {
      .left {
        .title {
          color: #0BA14B;
        }
      }
      .right {
        .price {
          color: #0BA14B;
        }
      }
    }
    .left,.right {
      display: flex;
      flex-direction: column;
      span {
        text-align: left;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0px;
        color: #2C2C2C;
        @media (max-width: 767.9px) {
          font-size: 16px;
          line-height: 18px;
        }
        @media (max-width: 475px) {
          font-size: 14px;
          line-height: 16px;
          &.date {
            text-align: right;
            min-width: 85px;
          }
        }
      }
      
      // .title {
      //   text-align: left;
      //   font-size: 18px;
      //   line-height: 26px;
      //   letter-spacing: 0px;
      // }
      // .sub-title {
      //   text-align: left;
      //   font-size: 18px;
      //   line-height: 26px;
      //   letter-spacing: 0px;
      //   color: #2C2C2C;
      // }
    }
    // .right {
    //   display: flex;
    //   flex-direction: column;
      // .price {
      //   text-align: left;
      //   font-size: 18px;
      //   line-height: 26px;
      //   letter-spacing: 0px;
      // }
      // .date {
      //   text-align: left;
      //   font-size: 18px;
      //   line-height: 26px;
      //   letter-spacing: 0px;
      //   color: #2C2C2C;
      // }
    // }
  }
}
.payments-radios-main {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767.9px) {
    flex-direction: column;
    gap: 5px;
  }
  .left, .right {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: 45%;
    .main-label {
      font-size: 16px;
      font-weight: 500;
      color: #2C2C2C;
      text-transform: capitalize;
      margin-top: 16px;
    }
    @media (max-width: 1399.9px) {
      width: calc(50% - 2.5px);
    }
    @media (max-width: 767.9px) {
      width: 100%;
    }
  }
  .form-check-main {
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    padding: 15px;
    // padding-left: 45px;
    .form-check-input {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:checked {
        background-color: #ffffff;
        border-color: #EA9B45;
        background-image: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: #EA9B45;
          // position: absolute;
          // left: 4px;
          // top: 4px;
        }
      }
    }
    .form-check-label {
      color: #2C2C2C;
      position: relative;
      top: 0.5px;
      font-weight: 500;
      font-size: 14px;
      padding-left: 5px;
    }
    #react-select-2-placeholder {
      font-size: 14px;
      line-height: 16px;
      color: #A8A8A8;
    }
    #react-select-2-listbox {
      box-shadow: 2px 3px 10px #00000029;
      border: 1px solid #EFEFEF;
      border-radius: 12px;
      margin-top: 3px;
      & > div {
        border-radius: 12px;
        & > div {
          padding: 0 !important;
          background-color: transparent;
        }
      }
    }
  }
}
.selected-custom-options-main {
  img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.options-custom {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #fff;
  border-bottom: 1px solid;
  border-color: #EA9B45;
  &.selected {
    background-color: #EA9B451A;
  }
  img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover;
  }
  .id-stand-address {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .id-stand {
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #2C2C2C;
  }
  .address {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #959595;
  }
}
.options-custom-values-box {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
}
.options-custom-values {
  display: inline-flex;
  // align-items: center;
  padding: 6px 6px;
  background-color: #fff;
  border: 1px solid #CBCBCB;
  border-radius: 7px;
  position: relative;
  width: calc(50% - 2.5px);
  @media (max-width: 1399.9px) {
    width: 100%;
  }
  img {
    min-width: 37px;
    max-width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover;
  }
  i {
    .fa-times {
      cursor: pointer;
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 14px;
    }
  }
  .id-stand-address {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-right: 6px;
  }
  .id-stand {
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #2C2C2C;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    .px-2 {
      display: none;
    }
  }
  .address {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    color: #959595;
  }
}
.custon-dateRange {
  display: flex;
  align-items: center;
  position: relative;
  i {
    position: absolute;
    right: 12px;
  }
  .form-control {
    font-size: 14px;
    color: #2C2C2C;
    opacity: 1;
    height: 48px;
    border-radius: 12px;
    border-color: #212529;
    &::placeholder {
      font-size: 14px;
      line-height: 16px;
      color: #A8A8A8;
    }
  }
}
.react-calendar__tile--hasActive {
  background: #EA9B45 !important;
}