@import "../../../../assets/scss/_helpers/variables";

.d-get-help-middle-content{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 54px);
  position: relative;
  > div {
    max-width: 324px;
    width: 100%;
    padding: 1rem;
    text-align: center;
    .ticket-avatar{
        width: 18rem;
        height: 15rem;
        margin-bottom: 1rem;

    }
  }
  
}
