@import "../../../../assets/scss/_helpers/variables";

.create-blog-confirmation-modal {
  .modal-content {
    border-radius: 9px;
  }

  .modal-header {
    align-items: center;
    padding: 60px 60px 0 60px;
    border-bottom: 0;
    margin-bottom: 4px;

    @media (max-width: 575.9px) {
      padding: 30px 30px 0 30px;
    }

    .btn-close {
      width: 17.7px;
      height: 17.7px;
      opacity: 1;

      @media (max-width: 575.9px) {
        top: 30px;
        right: 30px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 18px;
      margin: 0;
      color: #2C2C2C;
      font-family: 'Minion Pro';
      font-weight: 500;

      @media (max-width: 575.9px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0;
      text-align: center;
      margin-top: 6px;

      a {
        font-size: 16px;
        line-height: 20px;
        color: #EA9B45;
      }

      @media (max-width: 575.9px) {
        font-size: 14px;
        list-style: 18px;

        a {
          font-size: 14px;
          list-style: 18px;
        }
      }
    }
  }

  .modal-body {
    padding: 10px 60px 60px 60px;

    @media (max-width: 575.9px) {
      padding: 10px 30px 30px 30px;
    }

    .modal-body-text {
      color: #2C2C2C;
      font-size: 14px;
      margin-bottom: 30px;
    }


    .create-blog-confirmation-form {

      .created-blog-section {
        padding: 20px;
        border: 1px solid #928F8F;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        .content {
          display: flex;

          .blog-title {
            font-family: 'Roboto', sans-serif;
            font-size: 22px;
            font-weight: 500;
            color: #231F20;
            margin: 1px 0 0 0;

            @media (max-width:768px) {
              font-size: 18px;
            }
          }

          .blog-image {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }
        }


        .toggle-menu {
          i {
            color: #EA9B45;
            font-size: 28px;
            cursor: pointer;
          }

          .dropdown-menu {
            box-shadow: 0px 5px 14px #00000033;
            border: 1px solid #D4D4D4;
            border-radius: 5px;
            padding: 0;
            min-width: 100px;

            .dropdown-item {
              color: #000000;
              font-size: 14px;
              padding: 6px 14px !important;
              line-height: 1.3;

              &:active,
              &:focus,
              &:hover {
                background-color: #E9ECEF;
                color: #4B4D51;
              }

              &.red-dropdown-item {
                color: #D60000;
              }
            }
          }
        }
      }


      .modal-action-btns {
        display: flex;
        gap: 10px;
        margin-top: 30px;

        @media (max-width: 575.9px) {
          margin-top: 20px;
        }

        .modal-action-btn {
          border-radius: 10px;
          border: 0;
          color: #FFFFFF;
          font-size: 14px;
          line-height: 1.2;
          padding-block: 15px;
          width: 100%;
          max-width: 146px;


          &:hover,
          &:active,
          &:focus {
            opacity: .95;
          }

          &.outlined {
            border: 1px solid #515151;
            color: #2C2C2C;
            background-color: transparent;

            &:hover,
            &:active,
            &:focus {
              opacity: .8;
            }
          }

          &.filled {
            background-color: #EA9B45;
            border: 1px solid #EA9B45;
          }


          @media (max-width: 575.9px) {
            max-width: none;
            padding-block: 12px;
          }
        }
      }

    }

  }
}

// .edit-blog-post-dialog {
//   .modal-body {
//       padding: 25px 50px;

//       @media screen and (max-width: 575px) {
//           padding: 25px 20px;
//       }
//   }

//   .title {
//       margin-bottom: 25px;
//   }

//   textarea {
//       &.form-control {
//           min-height: 220px;
//       }
//   }

//   .modal-header {
//       border: 0;
//       position: absolute;
//       right: 0;
//       top: 5px;

//       .btn-close {
//           z-index: 99;
//       }
//   }

//   form {
//       .blog-desc {
//           label {
//               background: #fff;
//               height: 20px;
//               margin-top: 8px;
//               margin-left: 6px;
//               font-size: 14px;
//               padding: 0px;
//               color: #000;
//           }
//       }

//       .form-floating {
//           margin-bottom: 20px;

//           .form-control {
//               border-radius: 12px;
//               border: 1px solid #000000;
//               font-size: 14px;
//           }

//           .form-control-error {
//               border-radius: 12px;
//               border: 1px solid #EB3434;
//               font-size: 14px;
//           }

//           .error {
//               color: #EB3434;
//           }

//       }

//       .error {
//           color: #EB3434;
//       }

//       .title-error {
//           position: relative;
//           color: #EB3434;
//           top: -15px;
//       }

//       .images-uploader-wrapper {
//           border: 1px dashed #000000;
//           border-radius: 19px;
//           opacity: 1;
//           padding: 35px 21px;
//           padding-bottom: 27px;
//           display: flex;
//           flex-wrap: wrap;

//           >div {
//               margin-bottom: 8px;

//               &:not(:first-child) {
//                   margin-left: 25px;

//                   @media screen and (max-width: 575px) {
//                       margin-left: 12px;
//                   }
//               }
//           }

//           .size-exceed-error-msg {
//               margin: 0;
//               flex: 0 0 100%;
//               text-align: center;
//               margin-left: 0 !important;
//               margin-bottom: 0 !important;
//               font-size: 14px;
//               color: #D60000;
//           }

//           .uploaded-image {
//               position: relative;
//               display: inline-block;

//               &.size-exceed-error {
//                   img {
//                       border: 3.5px solid #D60000;
//                   }
//               }

//               img {
//                   width: 58px;
//                   height: 58px;
//                   overflow: hidden;
//                   border-radius: 6px;
//               }

//               .del-img {
//                   background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
//                   display: inline-block;
//                   background-repeat: no-repeat;
//                   cursor: pointer;
//                   position: absolute;
//                   box-shadow: 0px 3px 6px #00000029;
//                   width: 16px;
//                   background-color: #ffffff;
//                   height: 16px;
//                   top: -5px;
//                   right: -5px;
//                   background-size: 8px;
//                   background-position: center;
//                   border-radius: 100px;
//               }

//           }

//           .add-image {
//               text-align: center;
//               position: relative;
//               z-index: 1;
//               margin-left: auto;
//               margin-right: auto;

//               .max-limit {
//                   color: #A8A8A8;
//                   font-size: 16px;
//                   margin-top: 10px;
//                   display: block;
//                   max-width: 283px;
//                   margin-left: auto;
//                   margin-right: auto;
//                   line-height: 1.2;
//               }

//               .add-icon {
//                   width: 58px;
//                   height: 58px;
//                   display: flex;
//                   align-items: center;
//                   justify-content: center;
//                   background-color: #f1f1f1;
//                   margin: auto;
//                   font-size: 26px;
//                   font-weight: 700;
//                   color: #929090;
//                   border-radius: 6px;
//               }

//               .add-icon-error {
//                   width: 58px;
//                   height: 58px;
//                   display: flex;
//                   align-items: center;
//                   justify-content: center;
//                   background-color: #f1f1f1;
//                   margin: auto;
//                   font-size: 26px;
//                   font-weight: 700;
//                   color: red;
//                   border: 1px solid red;
//               }

//               label {
//                   padding: 0;
//                   margin-top: 10px;
//                   font-size: 12px;
//                   margin-top: 0px;
//               }

//               input {
//                   position: absolute;
//                   opacity: 0;
//                   width: 100%;
//                   height: 100%;
//                   top: 50%;
//                   left: 50%;
//                   transform: translate(-50%, -50%);
//                   z-index: 99;
//                   cursor: pointer;
//               }
//           }
//       }

//       .uploaded-media-wrapper{
//         border: 1px dashed #000000;
//         border-radius: 19px;
//         opacity: 1;
//         padding: 30px ;
//         display: flex;
//         justify-content: center;
//         flex-wrap: wrap;
//         gap: 14px;
//         margin-bottom: 20px;

//         .size-exceed-error-msg {
//             margin: 0;
//             flex: 0 0 100%;
//             text-align: center;
//             margin-left: 0 !important;
//             margin-bottom: 0 !important;
//             font-size: 14px;
//             color: #D60000;
//         }

//         .uploaded-image {
//             position: relative;
//             display: inline-flex;

//             &.size-exceed-error {
//                 .preview-image {
//                     border: 3.5px solid #D60000;
//                 }
//             }

//             .preview-image {
//                 width: 58px;
//                 height: 58px;
//                 overflow: hidden;
//                 border-radius: 6px;
//                 object-fit: cover;
//             }

//             .del-img {
//                 background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
//                 display: inline-block;
//                 background-repeat: no-repeat;
//                 cursor: pointer;
//                 position: absolute;
//                 box-shadow: 0px 3px 6px #00000029;
//                 width: 16px;
//                 background-color: #ffffff;
//                 height: 16px;
//                 top: -5px;
//                 right: -5px;
//                 background-size: 8px;
//                 background-position: center;
//                 border-radius: 100px;
//             }
//             .type-icon{
//                 position: absolute;
//                 bottom: 3px;
//                 left: 3px;
//             }

//         }
//         .progress-circle {
//           width: 60px;
//           height: 60px;
//         }

//         .circular-chart {
//           display: block;
//           max-height: 250px;
//         }

//         .circle-bg {
//           fill: none;
//           stroke: #eee;
//           stroke-width: 3.8;
//         }

//         .circle {
//           fill: none;
//           stroke-width: 2.8;
//           stroke-linecap: round;
//           stroke: map-get($theme-colors, "primary");
//           animation: progress 1s ease-out forwards;
//         }

//         .percentage {
//           fill: map-get($theme-colors, "primary");
//           font-family: sans-serif;
//           font-size: 0.5em;
//           text-anchor: middle;
//         }

//         @keyframes progress {
//           0% {
//             stroke-dasharray: 0 100;
//           }
//         }
//       }

//       .media-uploader-wrapper {
//         border: 1px dashed #000000;
//         border-radius: 19px;
//         opacity: 1;
//         padding: 23px 28px;
//         display: flex;
//         gap: 8px;

//         // flex-wrap: wrap;

//         .image-uploader-wrapper,
//         .video-uploader-wrapper {
      
//           &.exceeded {
//             opacity: 0.6;
//           }
//         }

//         >div {
//             text-align: center;
//             position: relative;
//             z-index: 1;

//             .max-limit {
//                 color: #B2B0B0;
//                 font-size: 13px;
//                 margin-top: 7px;
//                 display: block;
//                 max-width: 200px;
//                 line-height: 1.5;
//             }

//             input {
//                 position: absolute;
//                 opacity: 0;
//                 width: 100%;
//                 height: 100%;
//                 top: 50%;
//                 left: 50%;
//                 transform: translate(-50%, -50%);
//                 z-index: 99;
//                 cursor: pointer;
//             }

//             input[type="file" i]:disabled {
//                 pointer-events: none;
//             }
//         }
//         .seperator{
//             width: 1px;
//             background-color: #70707059;
//             min-height: 100%;
//             margin-inline: auto;
//         }
//         .images-uploader-wrapper {}

//         .video-uploader-wrapper {}
//       }
//   }
// }

.edit-blog-post-dialog {
  .modal-dialog{
    max-width:620px ;
  }
  .modal-body {
      padding: 25px 50px;

      @media screen and (max-width: 575px) {
          padding: 25px 20px;
      }
  }

  .title {
      margin-bottom: 25px;
  }

  textarea {
      &.form-control {
          min-height: 220px;
      }
  }

  .modal-header {
      border: 0;
      position: absolute;
      right: 0;
      top: 5px;

      .btn-close {
          z-index: 99;
      }
  }

  form {
      .blog-desc {
          label {
              background: #fff;
              height: 20px;
              margin-top: 8px;
              margin-left: 6px;
              font-size: 14px;
              padding: 0px;
          }
      }

      .form-floating {
        margin-bottom: 20px;

        >label {
          padding-block: 8px 0 !important;
          margin: 0;
        }

        .form-control {
          border-radius: 12px;
          border: 1px solid #000000;
          font-size: 14px;
          padding-block: 26px 8px !important;
          padding-left: 16px !important;

        }

        .form-control-error {
          border-radius: 12px;
          border: 1px solid #EB3434;
          font-size: 14px;
        }

        .error {
          color: #EB3434;
        }

      }

      .error {
          color: #EB3434;
      }

      .title-error {
          position: relative;
          color: #EB3434;
          top: -15px;
      }

      .images-uploader-wrapper {
          border: 1px dashed #000000;
          border-radius: 19px;
          opacity: 1;
          padding: 35px 21px;
          padding-bottom: 27px;
          display: flex;
          flex-wrap: wrap;

          >div {
              margin-bottom: 8px;

              &:not(:first-child) {
                  margin-left: 25px;

                  @media screen and (max-width: 575px) {
                      margin-left: 12px;
                  }
              }
          }

          .size-exceed-error-msg {
              margin: 0;
              flex: 0 0 100%;
              text-align: center;
              margin-left: 0 !important;
              margin-bottom: 0 !important;
              font-size: 14px;
              color: #D60000;
          }

          .uploaded-image {
              position: relative;
              display: inline-block;

              &.size-exceed-error {
                  img {
                      border: 3.5px solid #D60000;
                  }
              }

              img {
                  width: 58px;
                  height: 58px;
                  overflow: hidden;
                  border-radius: 6px;
              }

              .del-img {
                  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
                  display: inline-block;
                  background-repeat: no-repeat;
                  cursor: pointer;
                  position: absolute;
                  box-shadow: 0px 3px 6px #00000029;
                  width: 16px;
                  background-color: #ffffff;
                  height: 16px;
                  top: -5px;
                  right: -5px;
                  background-size: 8px;
                  background-position: center;
                  border-radius: 100px;
              }

          }

          .add-image {
              text-align: center;
              position: relative;
              z-index: 1;
              margin-left: auto;
              margin-right: auto;

              .max-limit {
                  color: #A8A8A8;
                  font-size: 16px;
                  margin-top: 10px;
                  display: block;
                  max-width: 283px;
                  margin-left: auto;
                  margin-right: auto;
                  line-height: 1.2;
              }

              .add-icon {
                  width: 58px;
                  height: 58px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f1f1f1;
                  margin: auto;
                  font-size: 26px;
                  font-weight: 700;
                  color: #929090;
                  border-radius: 6px;
              }

              .add-icon-error {
                  width: 58px;
                  height: 58px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f1f1f1;
                  margin: auto;
                  font-size: 26px;
                  font-weight: 700;
                  color: red;
                  border: 1px solid red;
              }

              label {
                  padding: 0;
                  margin-top: 10px;
                  font-size: 12px;
                  margin-top: 0px;
              }

              input {
                  position: absolute;
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 99;
                  cursor: pointer;
              }
          }
      }

      .uploaded-media-wrapper{
        border: 1px dashed #000000;
        border-radius: 19px;
        opacity: 1;
        padding: 30px ;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 14px;
        margin-bottom: 20px;

        .size-exceed-error-msg {
            margin: 0;
            flex: 0 0 100%;
            text-align: center;
            margin-left: 0 !important;
            margin-bottom: 0 !important;
            font-size: 14px;
            color: #D60000;
        }

        .uploaded-image {
            position: relative;
            display: inline-flex;

            &.size-exceed-error {
                .preview-image {
                    border: 3.5px solid #D60000;
                }
            }

            .preview-image {
                width: 58px;
                height: 58px;
                overflow: hidden;
                border-radius: 6px;
                object-fit: cover;
            }

            .del-img {
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
                display: inline-block;
                background-repeat: no-repeat;
                cursor: pointer;
                position: absolute;
                box-shadow: 0px 3px 6px #00000029;
                width: 16px;
                background-color: #ffffff;
                height: 16px;
                top: -5px;
                right: -5px;
                background-size: 8px;
                background-position: center;
                border-radius: 100px;
            }
            .type-icon{
                position: absolute;
                bottom: 3px;
                left: 3px;
            }

        }
        .progress-circle {
          width: 60px;
          height: 60px;
        }

        .circular-chart {
          display: block;
          max-height: 250px;
        }

        .circle-bg {
          fill: none;
          stroke: #eee;
          stroke-width: 3.8;
        }

        .circle {
          fill: none;
          stroke-width: 2.8;
          stroke-linecap: round;
          stroke: map-get($theme-colors, "primary");
          animation: progress 1s ease-out forwards;
        }

        .percentage {
          fill: map-get($theme-colors, "primary");
          font-family: sans-serif;
          font-size: 0.5em;
          text-anchor: middle;
        }

        @keyframes progress {
          0% {
            stroke-dasharray: 0 100;
          }
        }
      }

      .media-uploader-wrapper {
        border: 1px dashed #000000;
        border-radius: 19px;
        opacity: 1;
        padding: 23px 28px;
        display: flex;
        gap: 8px;

        // flex-wrap: wrap;
      
        .image-uploader-wrapper,
        .video-uploader-wrapper {
      
          &.exceeded {
            opacity: 0.6;
          }
        }

        >div {
            text-align: center;
            position: relative;
            z-index: 1;

            .max-limit {
                color: #B2B0B0;
                font-size: 13px;
                margin-top: 7px;
                display: block;
                max-width: 200px;
                line-height: 1.5;
            }

            input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 99;
                cursor: pointer;
            }

            input[type="file" i]:disabled {
                pointer-events: none;
            }
        }
        .seperator{
            width: 1px;
            background-color: #70707059;
            min-height: 100%;
            margin-inline: auto;
        }
        .images-uploader-wrapper {}

        .video-uploader-wrapper {}
      }

      .modal-action-btns {
        display: flex;
        gap: 10px;
        margin-top: 30px;
    
        @media (max-width: 575.9px) {
          margin-top: 20px;
        }
    
        .modal-action-btn {
          border-radius: 10px;
          border: 0;
          color: #FFFFFF;
          font-size: 14px;
          line-height: 1.2;
          padding-block: 15px;
          width: 100%;
          max-width: 146px;
    
    
          &:hover,
          &:active,
          &:focus {
            opacity: .95;
          }
    
          &.outlined {
            border: 1px solid #515151;
            color: #2C2C2C;
            background-color: transparent;
    
            &:hover,
            &:active,
            &:focus {
              opacity: .8;
            }
          }
    
          &.filled {
            background-color: #EA9B45;
            border: 1px solid #EA9B45;
          }
    
    
          @media (max-width: 575.9px) {
            max-width: none;
            padding-block: 12px;
          }
        }
      }
  }
}