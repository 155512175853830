.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .filter-btn {
    color: #2c2c2c;
    font-size: 18px;
    max-width: 112px;
    width: 100%;
    text-align: center;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding: 9px;
    text-decoration: none;
    background: transparent;
    img {
      width: 18px;
      margin-right: 10px;
    }
  }
}

.filter-form-wrapper {
  margin: 15px 0;
  .field-group {
    margin-bottom: 25px;
    position: relative;
    label {
      margin-bottom: 5px;
    }
    .date-calander {
      position: relative;
      .calendar-btn {
        position: absolute !important;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 !important;
      }
      .form-control {
        padding-right: 35px;
      }
    }
    .form-control {
      border: 1px solid #928f8f;
      border-radius: 10px !important;
      color: #2c2c2c;
      padding: 9px 15px;
      &::-webkit-scrollbar {
        width: 8px;
        height: 3px;
        border-radius: 6px;
        background-color: #f5f5f5;
      }
      
      &::-webkit-scrollbar-thumb {
          background-color: #A9A9A9;
          border-radius: 6px;
      }
    }
    select {
      appearance: auto;
    }
    .trophy-btn-wrap {
      .trophy-btn {
        display: inline-block;
        width: 55px;
        height: 50px;
        font-size: 16px;
        color: #2c2c2c;
      }
      .trophy-btn:hover {
        color: white;
        background-color: #EA9B45;
        border-color: #EA9B45;
      }
    }
  }
}
.filter-btns label {
  color: #2c2c2c;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  margin-bottom: 10px;
}
.gallery-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin-bottom: 20px;
}
.gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 5s ease 0s;
}
.gallery-image:hover img {
  transform: scale(1.2);
  filter: grayscale(1);
}

.react-calendar {
  position: absolute !important;
  z-index: 1001 !important;
}

.item {
  width: 100%;
  padding: 15px 0px;
  // background-color: gray;
  font-size: 15pt;
  text-align: center;
  margin-bottom: 4px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
