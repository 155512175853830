// .modal-title.h4 {
//   font-family: "Roboto";
//   font-weight: 700;
//   font-size: 20px;
// }
// .logout-popup .modal-content {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 2rem 0rem;
//   width: 85%;
//   margin: 0 auto;
//   @media screen and(max-width:375px) {
//     width: 100%;
//   }
// }
// .modal-header,
// .modal-footer,
// .modal-body {
//   border: none !important;
// }
// .logout-popup .modal-header,
// .logout-popup .modal-footer,
// .logout-popup .modal-body {
//   padding: 0rem !important;
// }
// .logout-popup .modal-footer {
//   flex-direction: row;
//   width: 100%;
//   justify-content: center !important;
//   margin-top: 1rem;
//   display: flex;
// }
// .modal-footer button {
//   max-width: 100px;
//   width: 100%;
//   margin-left: 10px;
//   padding: 7px 15px;
//   border-radius: 4px;
// }

.btn-outline-secondary{
    &:hover{
        color: #515151 !important;
        background: #f7f7f7 !important;
    }
}